import React from "react";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";

// checked
const TermsOfUse = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageComponents.MetaComponent
        title={t("terms_of_use_page.title")}
        canonicalUrl={window.location.host}
      />
      <section className="py-16 containerSpacing">
        <div className="w-full text-gray-600 body-font ">
          <h2 className="head font-extrabold pb-6">
            {t("terms_of_use_page.heading")}
          </h2>
          <p className="p1">{t("terms_of_use_page.p1")}</p>
          <p className="p1">{t("terms_of_use_page.p2")}</p>
          <p className="p1">{t("terms_of_use_page.p3")}</p>
          <p className="p1">{t("terms_of_use_page.p4")}</p>
          <p className="p1">
            {t("terms_of_use_page.p5")}
            <a href="https://www.termsofusegenerator.net">
              {t("terms_of_use_page.p6")}
            </a>
            .
          </p>
          <h3 className="head py-4">{t("terms_of_use_page.heading1")}</h3>
          <p className="p1">
            <strong>{t("terms_of_use_page.p7")}</strong>{" "}
            {t("terms_of_use_page.p8")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p9")}</strong>{" "}
            {t("terms_of_use_page.p10")}
          </p>
          <p className="p1">{t("terms_of_use_page.p11")}</p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p12")}</strong>{" "}
            {t("terms_of_use_page.p13")}
          </p>
          <p className="p1">{t("terms_of_use_page.p14")}</p>
          <h3 className="head py-4">{t("terms_of_use_page.heading2")}</h3>
          <p className="p1">
            <strong>{t("terms_of_use_page.p15")}</strong>{" "}
            {t("terms_of_use_page.p16")}
          </p>
          <p className="p1">{t("terms_of_use_page.p17")}</p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p18")}</strong>{" "}
            {t("terms_of_use_page.p19")}
          </p>
          <p className="p1">{t("terms_of_use_page.p20")}</p>
          <p className="p1">{t("terms_of_use_page.p21")}</p>
          <p className="p1">{t("terms_of_use_page.p22")}</p>
          <h3 className="head py-4">{t("terms_of_use_page.heading3")}</h3>
          <p className="p1">
            <strong>{t("terms_of_use_page.p23")}</strong>{" "}
            {t("terms_of_use_page.p24")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p25")}</strong>{" "}
            {t("terms_of_use_page.p26")}
          </p>
          <p className="p1">{t("terms_of_use_page.p27")}</p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p28")}</strong>{" "}
            {t("terms_of_use_page.p29")}
          </p>
          <h3 className="head py-4">{t("terms_of_use_page.heading4")}</h3>
          <p className="p1">{t("terms_of_use_page.p30")}</p>
          <p className="p1">{t("terms_of_use_page.p31")}</p>
          <h3 className="head py-4">{t("terms_of_use_page.heading5")}</h3>
          <p className="p1">{t("terms_of_use_page.p32")}</p>
          <p className="p1">{t("terms_of_use_page.p33")}</p>
          <p className="p1">{t("terms_of_use_page.p34")}</p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p35")}</strong>{" "}
            {t("terms_of_use_page.p36")}
          </p>
          <h3 className="head py-4">{t("terms_of_use_page.p37")}</h3>
          <p className="p1">{t("terms_of_use_page.p38")}</p>
          <ul className="p1">
            <li>{t("terms_of_use_page.list.p1")}</li>
            <li>{t("terms_of_use_page.list.p2")}</li>
            <li>{t("terms_of_use_page.list.p3")}</li>
            <li>{t("terms_of_use_page.list.p4")} </li>
            <li>{t("terms_of_use_page.list.p5")}</li>
            <li>{t("terms_of_use_page.list.p6")}</li>
            <li>{t("terms_of_use_page.list.p7")}</li>
          </ul>
          <p className="p1">{t("terms_of_use_page.p39")}</p>
          <h3 className="head py-4">{t("terms_of_use_page.heading6")}</h3>
          <p className="p1">{t("terms_of_use_page.p40")}</p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p41")}</strong>{" "}
            {t("terms_of_use_page.p42")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p43")}</strong>{" "}
            {t("terms_of_use_page.p44")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p45")}</strong>{" "}
            {t("terms_of_use_page.p46")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p47")} </strong>{" "}
            {t("terms_of_use_page.p48")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p49")}</strong>{" "}
            {t("terms_of_use_page.p50")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p51")}</strong>{" "}
            {t("terms_of_use_page.p52")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p53")}</strong>{" "}
            {t("terms_of_use_page.p54")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p55")}</strong>{" "}
            {t("terms_of_use_page.p56")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p57")}</strong>{" "}
            {t("terms_of_use_page.p58")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p59")}</strong>{" "}
            {t("terms_of_use_page.p60")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p61")}</strong>{" "}
            {t("terms_of_use_page.p62")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p63")}</strong>{" "}
            {t("terms_of_use_page.p64")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p65")}</strong>{" "}
            {t("terms_of_use_page.p66")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p67")}</strong>{" "}
            {t("terms_of_use_page.p68")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p69")}</strong>{" "}
            {t("terms_of_use_page.p70")}
          </p>
          <p className="p1">{t("terms_of_use_page.p71")}</p>
          <p className="p1">{t("terms_of_use_page.p72")}</p>
          <p className="p1">{t("terms_of_use_page.p73")}</p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p74")}</strong>{" "}
            {t("terms_of_use_page.p75")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p76")}</strong>{" "}
            {t("terms_of_use_page.p77")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p78")}</strong>{" "}
            {t("terms_of_use_page.p79")}
          </p>
          <p className="p1">
            <strong>{t("terms_of_use_page.p80")}</strong>{" "}
            {t("terms_of_use_page.p81")}
          </p>
          <h3 className="head py-4">{t("terms_of_use_page.heading7")}</h3>
          <p className="p1">
            {t("terms_of_use_page.p82")}: https://auto-bids.ch
          </p>
          <p className="p1">{t("terms_of_use_page.p83")}: info@auto-bids.ch</p>
        </div>
      </section>
    </>
  );
};

export default TermsOfUse;
