import { MainPages, DashboardPages, AuthPages } from "../pages";
import Layout from "../layouts";

export const AppRoutes = [
  {
    Component: Layout.Main2,
    children: [
      // { path: "/html-to-json", Component: MainPages.HTmlToJSON },
      { path: "/", Component: MainPages.Home },
      { path: "/home", Component: MainPages.Home },
      { path: "/auctions", Component: MainPages.Home },
      { path: "/past-auctions", Component: MainPages.Home },
    ],
  },
  {
    Component: Layout.Main,
    children: [
      { path: "/html-to-json", Component: MainPages.HTmlToJSON },
      // { path: "/resize-images", Component: MainPages.ResizeImages },
      { path: "/featured-auctions", Component: MainPages.Featured },
      { path: "/auctions/:title", Component: MainPages.Auction },
      { path: "/auctions/:title/:uuid", Component: MainPages.Auction },
      { path: "/sell-car", Component: MainPages.SellACar },
      { path: "/verify-email", Component: AuthPages.Verification },
      { path: "/admin/verify-email", Component: AuthPages.Verification },
      {
        path: "/daily-email/unsubscribe",
        Component: AuthPages.UnsubscribeDailyMail,
      },
      { path: "/reset-password", Component: AuthPages.ResetPassword },
      { path: "/sell-car/submit", Component: MainPages.Submit },
      { path: "/what-is", Component: MainPages.WhatIs },
      { path: "/about", Component: MainPages.WhatIs },
      { path: "/search/:slug", Component: MainPages.Search },
      { path: "/auth/google", Component: AuthPages.GoogleCallBack },
      { path: "/term-of-use", Component: MainPages.TermsOfUse },
      { path: "/privacy-policy", Component: MainPages.PrivacyPolicy },
      { path: "/support", Component: MainPages.Support },
      { path: "/shipping", Component: MainPages.Shipping },
      { path: "/inspection", Component: MainPages.Inspection },
      { path: "/user/:slug", Component: MainPages.PublicProfile },
      { path: "/careers", Component: MainPages.Careers },
    ],
  },
  {
    Component: Layout.User,
    children: [
      { path: "/profile", Component: MainPages.Profile },
      { path: "/listings", Component: MainPages.Listings },
      { path: "/settings", Component: MainPages.Settings },
    ],
  },
  {
    Component: Layout.General,
    children: [
      { path: "/watch-list", Component: MainPages.WatchList },
      { path: "/review-listing/:list_id", Component: MainPages.AddReview },
    ],
  },
  {
    Component: Layout.Application,
    children: [
      { path: "/application/:list_id", Component: MainPages.Application },
      { path: "/details/:list_id", Component: MainPages.Details },
      { path: "/condition/:list_id", Component: MainPages.Condition },
      { path: "/media/:list_id", Component: MainPages.Media },
      { path: "/specs/:list_id", Component: MainPages.Listing },
      { path: "/scheduling/:list_id", Component: MainPages.Scheduling },
      { path: "/review/:list_id", Component: MainPages.Review },
      { path: "/auction/:list_id", Component: MainPages.ApplicationAuction },
    ],
  },
  {
    Component: Layout.Dashboard,
    children: [
      { path: "/dashboard", Component: DashboardPages.Main },
      { path: "/lists", Component: DashboardPages.Listing },
      { path: "/list-detail", Component: DashboardPages.ListingDetail },
      {
        path: "/auction-review/:list_id",
        Component: DashboardPages.ReviewForm,
      },
      { path: "/schedule", Component: DashboardPages.TimeSchedule },
      { path: "/schedule/add", Component: DashboardPages.ListTimeScheduler },
      {
        path: "/schedule/update",
        Component: DashboardPages.UpdateTimeScheduler,
      },
      { path: "/auction", Component: DashboardPages.Auction },
      {
        path: "/auction-details/:list_id",
        Component: DashboardPages.AuctionDetails,
      },
      {
        path: "/create-auction",
        Component: DashboardPages.ListAuctionForm,
      },
      {
        path: "/update-auction",
        Component: DashboardPages.ListAuctionForm,
      },
      { path: "/reviewers", Component: DashboardPages.Reviewers },
      {
        path: "/create-reviewer-account",
        Component: DashboardPages.ReviewerForm,
      },
      { path: "/edit-reviewer", Component: DashboardPages.ReviewerForm },
      { path: "/faqs", Component: DashboardPages.Faqs },
      { path: "/faq-detail", Component: DashboardPages.FaqDetail },
      { path: "/create-faq", Component: DashboardPages.FaqForm },
      { path: "/edit-faq", Component: DashboardPages.FaqForm },
      { path: "/users", Component: DashboardPages.Users },
      { path: "/user-details", Component: DashboardPages.UserDetails },
      { path: "/admin-users", Component: DashboardPages.AdminUsers },
      { path: "/admin-details", Component: DashboardPages.AdminDetails },
      { path: "/create-admin", Component: DashboardPages.AdminUserForm },
      { path: "/edit-admin", Component: DashboardPages.AdminUserForm },
    ],
  },
  {
    Component: Layout.DashboardAuth,
    children: [{ path: "/admin", Component: DashboardPages.Signin }],
  },
  {
    Component: Layout.NotFoundLayout,
    children: [
      { path: "/404", Component: MainPages.PageNotFound },
      { path: "/*", Component: MainPages.PageNotFound },
    ],
  },
];
