import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  NavComponent,
  FooterComponent,
  ApplicationSidebar,
  FloatingScroller,
  HeaderComponent,
} from "../components";
import CheckUserType from "../utils/checkUserType.utils";

const Application = () => {
  const navigate = useNavigate();
  const isUserAndLogedIn = CheckUserType.isUser_and_loged_in();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    !isUserAndLogedIn && document.getElementById("signin")?.click();
    if (!isUserAndLogedIn) {
      // navigate(-1);
      return navigate("/", { state: { open_login: true } });
    }
  }, [isUserAndLogedIn]);

  return (
    <>
      <div className="sticky top-0 z-50">
        <NavComponent />
      </div>
      <HeaderComponent />
      <div className="bg-white dark:bg-[--dark]">
        <div className="mainContainer">
          <div className="containerSpacing py-16">
            <div className="flex flex-col sm:flex-row justify-start items-start gap-0 min-h-[60vh] ">
              <ApplicationSidebar />
              <div className="w-full sm:w-8/12 md:w-9/12 2xl:w-10/12 py-8">
                <Outlet />
              </div>
            </div>
          </div>
          <FloatingScroller />
        </div>
      </div>
      <div className="bg-[--dark]">
        <FooterComponent />
      </div>
    </>
  );
};

export default Application;
