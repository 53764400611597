import { Outlet, useNavigate } from "react-router-dom";
import {
  NavComponent,
  FooterComponent,
  FloatingScroller,
  HeaderComponent,
} from "../components";
import Services from "../services";
import { useEffect } from "react";

const General = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const loginStatus = Services.Auth.IsUserLogedIn();
    if (loginStatus === false) {
      return navigate("/", { state: { open_login: true } });
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="sticky top-0 z-50">
        <NavComponent />
      </div>
      <HeaderComponent />
      <div className="bg-white dark:bg-[--dark]">
        <div className="mainContainer">
          <Outlet />
          <FloatingScroller />
        </div>
      </div>
      <div className="bg-[--dark]">
        <FooterComponent />
      </div>
    </>
  );
};

export default General;
