import React, { useState, useEffect } from "react";
import { ThemeProvider, Button } from "@mui/material";
import Utils from "../utils";
import Services from "../services";
import Swal from "sweetalert2";
import _ from "lodash";
import moment from "moment";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Input } from "@mui/joy";

function AutosReviews() {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const themes = Utils.Themes();
  const [reviews, setReviews] = useState<any[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(6);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = {
      email: email,
    };
    Services.Auth.SubscribeToDailyEmail(data)
      .then((res) => {
        if (res.status) {
          Swal.fire({
            icon: "success",
            title: res.message,
          });
          setEmail("");
        } else {
          Swal.fire({
            icon: "error",
            title: res.message,
          });
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          Swal.fire({
            icon: "error",
            title: error?.response?.data?.message,
          });
        }
      });
  };

  const getReviews = () => {
    Services.System.GetReviews(offset, limit).then((res) => {
      res.status && setReviews(res.data);
    });
  };

  useEffect(() => getReviews(), [offset, limit]);

  const handleTimes = (rating: number) => {
    const ratingHtml = _.times(rating, (index) => {
      return (
        <span key={index} className="text-[var(--main-color)]">
          <svg
            aria-hidden="true"
            className="w-5 h-5 "
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
          </svg>
        </span>
      );
    });
    return ratingHtml;
  };

  return (
    <section className="body-font bg-white z-50">
      <div className="w-full containerSpacing py-5">
        <hr className="h-[1px] mt-14 bg-black-200 border dark:bg-gray-700 mb-5"></hr>
        <div className="flex justify-start items-start flex-wrap lg:flex-nowrap md:text-left text-center gap-2 py-4">
          <div className="lg:w-1/3 w-full ">
            <h6 className="title-font font-bold text-gray-900 tracking-widest text-lg ">
              {t("about")}
            </h6>
            <nav className="list-none mb-10 grid grid-cols-2 gap-4 ">
              <li>
                <h6 className="title-font font-bold text-gray-900 tracking-widest text-base">
                  12000+
                </h6>
                <h5 className="title-font text-xs text-gray-900 tracking-widest mb-3">
                  {t("reviews_section.options.1")}
                </h5>
              </li>
              <li>
                <h6 className="title-font font-bold text-gray-900 tracking-widest text-base">
                  85%+
                </h6>
                <h5 className="title-font text-xs text-gray-900 tracking-widest mb-3">
                  {t("reviews_section.options.2")}
                </h5>
              </li>
              <li>
                <h6 className="title-font font-bold text-gray-900 tracking-widest text-base">
                  256M+ CHF
                </h6>
                <h5 className="title-font text-xs text-gray-900 tracking-widest mb-3">
                  {t("reviews_section.options.3")}
                </h5>
              </li>
              <li>
                <h6 className="title-font font-bold text-gray-900 tracking-widest text-base">
                  360k+
                </h6>
                <h5 className="title-font text-xs text-gray-900 tracking-widest mb-3">
                  {t("reviews_section.options.4")}
                </h5>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/3 md:w-2/5 w-full">
            <h6 className="title-font font-bold tracking-widest text-lg mb-3">
              {t("reviews_section.title")}
            </h6>
            {reviews?.length > 0 &&
              reviews?.map((review, index) => (
                <div key={index}>
                  <div className="flex items-center flex-wrap justify-center md:justify-start textColor">
                    {handleTimes(review.rating)}
                    <p className="ml-2 text-sm font-bold">
                      {review.user?.username}.
                    </p>
                    <span className="w-1 h-1 "></span>
                    <Link to="/" className="text-sm font-medium textGray">
                      {moment(review.created_at).format("MMM YYYY")}
                    </Link>
                  </div>
                  <p className="title-font text-base textGray tracking-widest mt-2">
                    {review.review}
                  </p>
                </div>
              ))}
          </div>
          <div className="lg:w-1/3 md:w-2/5 w-full">
            <h6 className="title-font font-bold tracking-widest text-lg mb-3 mt-10 md:mt-0">
              {t("reviews_section.newsletter.title")}
            </h6>

            <div className="flex flex-col md:flex-nowrap flex-wrap lg:justify-center items-center lg:items-start md:justify-start ">
              <form onSubmit={(e) => handleSubmit(e)} className="w-full">
                <div className=" mb-2 mr-0 w-full">
                  <Input
                    type="text"
                    id="footer-field"
                    name="footer-field"
                    placeholder={t(
                      "reviews_section.newsletter.input_placeholder"
                    )}
                    required={true}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={themes.JoyGrayShadeInputStyle2_2}
                  />
                </div>
                <div className="w-full md:w-fit">
                  <ThemeProvider theme={themes.ButtonTheme_2}>
                    <Button fullWidth variant="contained" type="submit">
                      {t("reviews_section.newsletter.button_text")}
                    </Button>
                  </ThemeProvider>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AutosReviews;
