import { useState } from "react";
import { Button } from "@mui/material";
import { Images } from "../../assets";
import QuoteModal from "./QuoteModal.component";
import { useTranslation } from "react-i18next";

const Quote = () => {
  const { t } = useTranslation();
  const [openQuote, setOpenQuote] = useState(false);

  return (
    <>
      <div className="py-4 ">
        <Button
          className="get-Quote-tBg rounded-[--butn-border-radius]  w-full"
          color="inherit"
          sx={{
            boxShadow: "none",
            border: "none", 
            borderRadius: "var(--butn-border-radius)"
          }}
          onClick={() => setOpenQuote(true)}
        >
          <div className="flex justify-between items-center w-full px-3 md:px-4 py-3">
            <div className="flex justify-start items-center gap-2">
              <img src={Images.TruckIcon} alt="truck" className="w-8 h-auto" />
              <div className="flex flex-col	 justify-center items-start">
                <h3 className="font-semibold p1 normal-case	">
                  {t("auction_details_page.quote_section.title")}
                </h3>
                <p
                  className=" normal-case p2 ps-1  sm:hidden"
                  style={{ fontWeight: "100" }}
                >
                  {t("auction_details_page.quote_section.p1")}
                </p>
              </div>
              <p
                className=" normal-case p2 ps-1 hidden sm:block"
                style={{ fontWeight: "100" }}
              >
                {t("auction_details_page.quote_section.p2")}
              </p>
            </div>
            <button
              className="normal-case px-2 sm:px-3.5 py-0.5 sm:py-1 rounded border border-black 	hidden sm:block shadow-sm p2 dark:border-[--dark] darkShadow"
              style={{ fontWeight: "100" }}
            >
              {t("auction_details_page.quote_section.comming_soon_butn_text")}
            </button>
          </div>
        </Button>
      </div>
      {openQuote && (
        <QuoteModal modalOpen={openQuote} setIsModalOpen={setOpenQuote} />
      )}
    </>
  );
};

export default Quote;
