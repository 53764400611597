import { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import Services from "../../services";
import FeaturedCardComponent from "./FeaturedCard.component";
import FeaturedSkeletonComponent from "./FeaturedSkeleton.component";

const FeaturedContentComponent = () => {
  const [loading, setLoading] = useState(false);
  const [auctions, setAuctions] = useState<any[]>([]);
  const [limit, setLimit] = useState(10);

  const getListings = () => {
    setLoading(true);
    Services.Listing.GetListings(
      0,
      limit,
      "auctions",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      true
    )
      .then((res: any) => {
        setLoading(false);
        res.status && setAuctions(res.data);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getListings();
  }, []);

  const addToWatchList = (id: string | number) => {
    // add to watch list
    setLoading(true);
    const auction_data = {
      listing_id: id,
    };
    Services.WatchList.AddToWatchList(auction_data)
      .then((res) => {
        setLoading(false);
        res.status &&
          auctions?.length > 0 &&
          setAuctions(
            auctions?.map((item: any) => {
              item.id == id && (item.watching = true);
              return item;
            })
          );
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const removeFromWatchList = (id: string | number) => {
    // remove from watch
    setLoading(true);
    Services.WatchList.DeleteFromWatchList(id)
      .then((res) => {
        setLoading(false);
        res.status &&
          auctions?.length > 0 &&
          setAuctions(
            auctions?.map((item: any) => {
              item.id == id && (item.watching = false);
              return item;
            })
          );
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    (loading || auctions?.length > 0) && (
      <section
        className="containerSpacing flex justify-center items-start flex-wrap gap-1 sm:gap-2 mt-16 sx-center"
        id="featured-slider"
      >
        <h2
          className="head text-center pb-4 lg:pb-0"
          style={{ fontWeight: 700 }}
        >
          Featured Cars
        </h2>
        <div className="w-full relative pb-4">
          {loading ? (
            <div className="pt-4">
              <FeaturedSkeletonComponent />
            </div>
          ) : (
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              emulateTouch={true}
              swipeable={true}
              showThumbs={false}
              showArrows={false}
              className="cursor-grab"
              showStatus={false}
              showIndicators={true}
              // axis="vertical"
            >
              {auctions.map((item, index) => (
                <div className="w-full px-1 " key={index}>
                  <FeaturedCardComponent
                    data={item}
                    addToWatchList={addToWatchList}
                    removeFromWatchList={removeFromWatchList}
                  />
                </div>
              ))}
            </Carousel>
          )}
        </div>
      </section>
    )
  );
};

export default FeaturedContentComponent;
