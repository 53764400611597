//

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import Context from "./context";

const rootElement = document.getElementById("root");

if (rootElement?.hasChildNodes()) {
  // Use hydrateRoot for server-side rendered apps
  ReactDOM.hydrateRoot(
    rootElement,
    <Context.LanguageContextProvider>
      <Context.ThemeContextProvider>
        <App />
      </Context.ThemeContextProvider>
    </Context.LanguageContextProvider>
  );
} else {
  if (rootElement) {
    // Use createRoot for client-side rendered apps
    const root = ReactDOM.createRoot(rootElement);
    root?.render(
      <Context.LanguageContextProvider>
        <Context.ThemeContextProvider>
          <App />
        </Context.ThemeContextProvider>
      </Context.LanguageContextProvider>
    );
  } else {
    console.error("The root element with ID 'root' was not found.");
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
