import { memo } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { Modal, ModalClose, Sheet } from "@mui/joy";
import Utils from "../../utils";
import Services from "../../services";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToggleButton } from "../../components";
import { useTranslation } from "react-i18next";

interface SaveSearchProps {
  open: boolean; // Defines if the modal or UI element is open
  auctionNotifyMe: boolean; // Flag to indicate if auction notifications are enabled
  setAuctionNotifyMe: (notify: boolean) => void; // Function to toggle auction notifications
  handleSubmit: (e: any, redirectWatchList?:boolean) => void; // Function to handle form submission
  isLoading: boolean; // Flag to indicate if the process is loading
  searchLabel?:string;
}

const SavedSearch = memo(
  ({
    open,
    // setOpen,
    auctionNotifyMe,
    setAuctionNotifyMe,
    handleSubmit,
    isLoading,
    searchLabel
  }: SaveSearchProps) => {
    const navigate= useNavigate()
    const params = useParams();
    const { t } = useTranslation();
    const themes = Utils.Themes();

    return (
      <>
        <div className="overflow-y-auto">
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={open}
            hideBackdrop={true}
            onClose={(e) => handleSubmit(e)}
            sx={themes.JoyModalStyle_2}
          >
            <Sheet
              className="w-11/12 sm:8/12 md:w-6/12 lg:w-5/12 xl:w-4/12"
              sx={themes.JoyModalSheetStyle_2}
            >
              <ModalClose
                variant="outlined"
                size="sm"
                sx={themes.JoyModalCloseStyle_2}
              />
              <div className="flex justify-center align-items-center w-100">
                <h2 className="head capitalize">
                  <b>{t("save_search_modal.heading")}</b>
                </h2>
              </div>
              <hr className="hrDarkMode" />
              <form
                onSubmit={(e) => handleSubmit(e)}
                noValidate
                autoComplete="off"
                className="modalForm"
              >
                <div className="pt-8 w-100">
                  <div className="flex justify-center items-center">
                    <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                      <Button
                        variant="contained"
                        type="button"
                        fullWidth={false}
                        disabled={isLoading}
                        onClick={(e) => handleSubmit(e, true)}
                      >
                        {searchLabel?.trim() || params?.slug?.trim()}
                      </Button>
                    </ThemeProvider>
                  </div>
                  <p className="py-3 p1">{t("save_search_modal.p1")}</p>
                  <h3 className="p1 font-bold pt-3">
                    {t("save_search_modal.heading2")}...
                  </h3>
                  <div className="flex justify-between">
                    <label className="p1 font-normal my-3">
                      {t("save_search_modal.heading1")}
                    </label>
                    <ToggleButton
                      id="when_new_auctions_go_live"
                      value={auctionNotifyMe}
                      handleChange={() => setAuctionNotifyMe(!auctionNotifyMe)}
                    />
                  </div>
                </div>
                <div className="text-center mt-2">
                  <ThemeProvider theme={themes.ButtonTheme_2}>
                    <Button
                      variant="contained"
                      type="submit"
                      fullWidth
                      // className="two-xl-larger-text"
                      disabled={isLoading}
                    >
                      {t("save_search_modal.done_butn_text")}
                    </Button>
                  </ThemeProvider>
                  <div className="flex justify-center items-center pt-2">
                    <p className="p1">
                      {t("save_search_modal.p2")}{" "}
                      <Link to="/watch-list" className="underline">
                        {t("save_search_modal.a1")}
                      </Link>
                    </p>
                  </div>
                </div>
              </form>
            </Sheet>
          </Modal>
        </div>
      </>
    );
  }
);

export default SavedSearch;
