import { useState, memo } from "react";
import { SearchTypeProps } from "../../type/Auction.type";
import Countdown from "react-countdown";
import { Link, useNavigate } from "react-router-dom";
import Utils from "../../utils";
import { Button, Grid, ThemeProvider, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import CircleIcon from "./CircleIcon.component";
import { ConvertToLocal } from "../../utils/CalculateDays";

const SearchCardComponent = memo(
  ({
    getSavedSearch,
    item,
    searchedText,
    deleteSearch,
    savedSearches,
    index,
  }: SearchTypeProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const themes = Utils.Themes();
    const [loading, setLoading] = useState<boolean>(false);

    const isAutionEnd = (data: any) => {
      if (
        data?.auction?.status === "live" &&
        Utils.CalculateDays(data?.auction?.auction_end) > 0
      ) {
        return false;
      }
      return true;
    };

    return (
      <>
        {item?.listing ? (
          <div key={item?.listing?.uuid} className="listingCard">
            <div className="flex flex-row items-center justify-between ">
              <Link to={`/search/${searchedText}`}>
                <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                  <Button variant="text" size="small">
                    {searchedText}
                  </Button>
                </ThemeProvider>
              </Link>
              {!isAutionEnd(item?.listing) && (
                <div
                  className={`${item?.listing?.has_reserve_price ? "hide" : ""} no_reserve_label`}
                >
                  <p className="no_reserve_text" style={{ margin: 0 }}>
                    {t("card_data.no_reserve")}
                  </p>
                </div>
              )}
            </div>

            <div className="py-2">
              <div className="w-full"></div>
              <div className="flex flex-wrap justify-between">
                <h2 className="truncate w-5/6">
                  <Link
                    to={`/auctions/${
                      item?.listing?.year?.replace(/\s+/g, "-") +
                      "-" +
                      item?.listing?.make?.replace(/\s+/g, "-") +
                      "-" +
                      item?.listing?.model?.replace(/\s+/g, "-") +
                      (item?.listing?.title
                        ? "-" + item?.listing?.title?.replace(/\s+/g, "-")
                        : "")
                    }`}
                    className="cardMainTitle"
                    state={{ uuid: item?.listing?.uuid }}
                  >
                    {item?.listing?.title ? item?.listing?.title : ""}
                  </Link>
                </h2>
              </div>
              <h3 className="cardSubTitle">
                {item?.listing?.make}
                <CircleIcon className="dot" />
                {item?.listing?.model + " " + item?.listing?.year}
                <CircleIcon className="dot" />
                {item?.listing?.mileage}
                {" KM"}
              </h3>
            </div>
            <div>
              <div className="relative overflow-hidden text-white shadow-lg bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40">
                <div className="flex justify-center">
                  <Link
                    to={`/auctions/${
                      item?.listing?.year?.replace(/\s+/g, "-") +
                      "-" +
                      item?.listing?.make?.replace(/\s+/g, "-") +
                      "-" +
                      item?.listing?.model?.replace(/\s+/g, "-") +
                      (item?.listing?.title
                        ? "-" + item?.listing?.title?.replace(/\s+/g, "-")
                        : "")
                    }`}
                    state={{ uuid: item?.listing?.uuid }}
                    className="w-full"
                  >
                    <img
                      src={`${process.env.REACT_APP_HOST_API_KEY}/${item?.listing?.photos[0]?.uri}`}
                      alt={
                        item?.listing?.year +
                        " " +
                        item?.listing?.make +
                        " " +
                        item?.listing?.model +
                        (item?.listing?.title ? " " + item?.listing?.title : "")
                      }
                      className="cardh"
                      loading="lazy"
                    />
                  </Link>
                </div>
                <Grid
                  container
                  spacing={0}
                  className="rounded-b-xl p-0 h-fu ll"
                >
                  {!isAutionEnd(item?.listing) && (
                    <Grid
                      item
                      xs={5.95}
                      className="text-center bg-yellow-300 p-0"
                    >
                      <p
                        className={`font-bold text-black dark:text-black text-lg`}
                      >
                        {item?.listing?.auction?.auction_end &&
                        Utils.CalculateDays(
                          item?.listing?.auction?.auction_end
                        ) > 1 ? (
                          Utils.CalculateDays(
                            item?.listing?.auction?.auction_end
                          ).toFixed(0) +
                          " " +
                          (Utils.CalculateDays(
                            item?.listing?.auction?.auction_end
                          ) > 1
                            ? t("search_card_component.9il748cza")
                            : t("search_card_component.9il748czb"))
                        ) : (
                          <Countdown
                            date={ConvertToLocal(
                              item?.listing?.auction?.auction_end
                            )}
                            daysInHours={true}
                          />
                        )}
                      </p>

                      <p className="text-gray-500 text-sm dark:text-black">
                        {t("search_card_component.mpxu3n6vm")}
                      </p>
                    </Grid>
                  )}
                  {!isAutionEnd(item?.listing) && <Grid item xs={0.1} />}
                  <Grid
                    item
                    xs={!isAutionEnd(item?.listing) ? 5.95 : 12}
                    className="text-center bg-yellow-300 p-0"
                  >
                    <p className="font-bold text-black p1 dark:text-black">
                      {item?.listing?.current_bid?.price
                        ? item?.listing?.current_bid?.price
                        : 0}{" "}
                      CHF
                    </p>
                    <p className="text-gray-500 p1 dark:text-black">
                      {item?.listing?.bids_count || 0}{" "}
                      {t("search_card_component.qne6lw958")}
                    </p>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex justify-between items-center truncate">
                <p className="p1 font-medium">
                  {t("search_card_component.4byermfwk")}
                </p>
                <p className="p1 font-semibold">{item?.listing?.body_style}</p>
              </div>
              <div className="flex justify-between items-center truncate">
                <p className="p1 font-medium">
                  {t("search_card_component.pktesvg6e")}
                </p>
                <p className="p1 font-semibold">{item?.listing?.engine}</p>
              </div>
              <div className="flex justify-between items-center truncate">
                <p className="p1 font-medium">
                  {t("search_card_component.4xge3isgg")}
                </p>
                <p className="p1 font-semibold">
                  {item?.listing?.transmission}
                </p>
              </div>
              <div className="flex justify-between items-center truncate">
                <p className="p1 font-medium">
                  {t("search_card_component.ycgjuftog")}
                </p>
                <div className="p1 font-semibold">
                  <div className="flex -space-x-2 items-center justify-center">
                    <Tooltip
                      title={
                        t("details_submission_page.2xwpepu29") +
                        " : " +
                        item?.listing?.interior_color
                      }
                    >
                      <span
                        className={`w-6 h-6 border-2 border-black rounded-full drop-shadow-sm hovershadow z-[1] hover:z-[999] hover:drop-shadow-md interiorHover`}
                        style={{
                          background: item?.listing?.interior_color
                            ? item?.listing?.interior_color
                            : "white",
                        }}
                        onMouseEnter={(e: any) =>
                          (e.target.style.borderColor = item?.listing
                            ?.interior_color
                            ? item?.listing?.interior_color
                            : "white")
                        }
                        onMouseLeave={(e: any) =>
                          (e.target.style.borderColor = "#000")
                        }
                      ></span>
                    </Tooltip>
                    <Tooltip
                      title={
                        t("details_submission_page.e5gzqci3g") +
                        " : " +
                        item?.listing?.exterior_color
                      }
                    >
                      <span
                        className={`w-6 h-6 border-2 border-black rounded-full drop-shadow-sm hovershadow hover:z-[999] z-[2] hover:drop-shadow-md exteriorHover`}
                        style={{
                          background: item?.listing?.exterior_color
                            ? item?.listing?.exterior_color
                            : "white",
                        }}
                        onMouseEnter={(e: any) =>
                          (e.target.style.borderColor = item?.listing
                            ?.exterior_color
                            ? item?.listing?.exterior_color
                            : "white")
                        }
                        onMouseLeave={(e: any) =>
                          (e.target.style.borderColor = "#000")
                        }
                      ></span>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center truncate">
                <p className="p1 font-medium">
                  {t("search_card_component.6mfla5mjt")}
                </p>
                <p className="p1 font-semibold">
                  {item?.listing?.zip_code || ""}{" "}
                  {item?.listing?.city_and_province || ""}{" "}
                  {item?.listing?.country || ""}
                </p>
              </div>
            </div>
            <div className="flex justify-between items-baseline">
              <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                <Button
                  variant="text"
                  type="button"
                  onClick={() => navigate(`/search/${searchedText}`)}
                >
                  +{savedSearches[searchedText]?.total}{" "}
                  {t("search_card_component.6vxiseoks")}
                </Button>
              </ThemeProvider>
              <ThemeProvider theme={themes.ButtonTheme_2}>
                <Button
                  variant="text"
                  size="small"
                  onClick={() => deleteSearch(searchedText)}
                >
                  {t("search_card_component.wr5hltytx")}
                </Button>
              </ThemeProvider>
            </div>
          </div>
        ) : (
          <div key={index} className="listingCard hovershadow justify-between">
            <div className="flex flex-col gap-6">
              <div className="flex flex-row items-center justify-between gap-1 pb-1">
                <Link to={`/search/${searchedText}`}>
                  <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                    <Button variant="text" size="small">
                      <p className="break-all	"> {searchedText}</p>
                    </Button>
                  </ThemeProvider>
                </Link>
              </div>
              <div className="auction-top-img cursor-pointer  h-[300px] bg-[var(--mid-gray-color)] flex justify-center items-center capitalize tracking-wide rounded-xl shadow  w-full dark:bg-[--gray] darkShadow dark:border-[--gray]">
                <p className="head font-medium ">
                  {t("search_card_component.u4kg5po5j")}
                </p>
              </div>
            </div>
            <div className="flex justify-between items-baseline">
              <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                <Button
                  variant="text"
                  type="button"
                  onClick={() => navigate(`/search/${searchedText}`)}
                >
                  +{savedSearches[searchedText]?.total}{" "}
                  {t("search_card_component.6vxiseoks")}
                </Button>
              </ThemeProvider>
              <ThemeProvider theme={themes.ButtonTheme_2}>
                <Button
                  variant="text"
                  size="small"
                  onClick={() => deleteSearch(searchedText)}
                >
                  {t("search_card_component.wr5hltytx")}
                </Button>
              </ThemeProvider>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default SearchCardComponent;
