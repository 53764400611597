import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ThemeProvider,
  Button,
  Breadcrumbs,
  Box,
  IconButton,
  Popover,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonGroup,
  Pagination,
  Avatar,
} from "@mui/material";
import Utils from "../../utils";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Services from "../../services";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { PreLoaderComponent } from "../../components";
import moment from "moment";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";
import { isAdmin_and_loged_in } from "../../utils/checkUserType.utils";

const Users = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const themes = Utils.Themes();
  const isLogedIn = isAdmin_and_loged_in();
  const [anchorEl, setAnchorEl] = useState(null);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [loadingdata, setLoadingdata] = useState<boolean>(false);
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [users, setUsers] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [search, setSearch] = useState<string>("");
  const [search_text, setSearchText] = useState<string>("");

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    document.addEventListener("click", (e: any) => {
      let clickonActionButton = false;
      rows?.map((row, index) => {
        const actionButton = document.getElementById(`actionMenu-${index}`);
        if (e.target.contains(actionButton)) {
          clickonActionButton = true;
        }
      });
    });
  }, []);

  const getUsers = (e?: any) => {
    e?.preventDefault();
    setLoadingdata(true);
    Services.User.getUsersForAdmin(
      offset,
      limit,
      search_text?.toLocaleLowerCase()
    )
      .then((res) => {
        setLoadingdata(false);
        if (res.status) {
          res.data && setUsers(res.data);
          setTotal(Math.ceil(res.total / limit));
        }
      })
      .catch((error) => {
        setLoadingdata(false);
      });
  };

  useEffect(() => {
    isLogedIn && getUsers();
  }, [offset, limit, search_text]);

  const resetPagenation = () => {
    setOffset(0);
    setTotal(0);
    setPage(1);
  };

  const handleSearch = (e?: any) => {
    e?.preventDefault();
    resetPagenation();
    setSearchText(search);
  };

  return (
    <>
      <PageComponents.MetaComponent title={t("users_page.nd3o5dm71")} />
      <div className=" ">
        <div className=" ">
          {/* list items */}
          <div className="flex flex-wrap justify-between items-center py-6">
            <div className="flex flex-col justify-center items-start gap-2">
              <h2 className="breadCrumbsMianTitle">
                {t("users_page.ve294cof0")}
              </h2>
              <div role="presentation">
                <ThemeProvider theme={themes.MuiBreadCrumbsTheme}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/dashboard" className="hover:underline">
                      {t("users_page.pfyuuvkzh")}
                    </Link>
                    <Link to={location.pathname} className="hover:underline">
                      {t("users_page.ve294cof0")}
                    </Link>
                  </Breadcrumbs>
                </ThemeProvider>
              </div>
            </div>
            <form onSubmit={(e) => handleSearch(e)}>
              <PageComponents.SearchComponent
                search={search}
                setSearch={setSearch}
              />
            </form>
          </div>
          {/* loading data */}
          {loadingdata && <PreLoaderComponent />}
          {/* desktop responsive table */}
          <div className="">
            {!loadingdata && (
              <div className="hidden md:block w-full overflow-x-auto">
                <Box
                  component={Paper}
                  sx={themes.MuiTableBox}
                  className="overflow-x-auto "
                >
                  <ThemeProvider theme={themes.TableTheme_2}>
                    <TableContainer className="muiTableContainer">
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("users_page.9irzh8r6y")}</TableCell>
                            <TableCell>{t("users_page.hrj5j1un8")}</TableCell>
                            <TableCell>{t("users_page.9h99xanbm")}</TableCell>
                            <TableCell>{t("users_page.7hdgcvcvn")}</TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {users?.length > 0 &&
                            users?.map((item, index) => (
                              <TableRow
                                key={item.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  <Avatar
                                    src={
                                      item.avatar
                                        ? `${process.env.REACT_APP_HOST_API_KEY}/${item.avatar}`
                                        : item.username
                                    }
                                    alt={item.username}
                                    className="darkShadow"
                                  />
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ textTransform: "uppercase" }}
                                >
                                  {item.username}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ textTransform: "uppercase" }}
                                >
                                  {item.email}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ textTransform: "uppercase" }}
                                >
                                  {moment(item.created_at).format(
                                    "MMMM DD YYYY"
                                  )}
                                </TableCell>
                                <TableCell align="right" className="relative">
                                  <IconButton
                                    onClick={(e) => {
                                      handleOpen(e);
                                      setSelectedItem(item);
                                    }}
                                    aria-describedby={`actionMenu-${index}`}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 128 512"
                                      className="popover"
                                    >
                                      <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
                                    </svg>
                                  </IconButton>
                                  <Popover
                                    id={`actionMenu-${index}`}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <div
                                      className={`MuiPopoverMenu`}
                                      id={`actionMenu-${index}`}
                                    >
                                      <ThemeProvider
                                        theme={themes.AppleButtonTheme_2}
                                      >
                                        <Button
                                          // color="inherit"
                                          fullWidth
                                          onClick={() =>
                                            navigate("/user-details", {
                                              state: { data: selectedItem },
                                            })
                                          }
                                        >
                                          <div className="flex justify-start items-center mr-auto pl-1">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="w-3 h-3 "
                                              fill="currentColor"
                                              viewBox="0 0 576 512"
                                            >
                                              <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                            </svg>
                                            <span className="pl-4 capitalize ">
                                              {t("users_page.kd5urbhtg")}
                                            </span>
                                          </div>
                                        </Button>
                                      </ThemeProvider>
                                    </div>
                                  </Popover>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </ThemeProvider>
                </Box>
              </div>
            )}
          </div>
          {/* mobile responsive data table */}
          <div className="py-3">
            <div className="flex flex-wrap h-full md:hidden">
              {!loadingdata &&
                users?.length > 0 &&
                users?.map((item, index) => (
                  <div className=" p-1 sm:p-2 w-full" key={index} id={item.id}>
                    <ThemeProvider theme={themes.MuiAccordionTheme}>
                      <Accordion className="accordionShadow">
                        <AccordionSummary
                          expandIcon={<ExpandMoreRoundedIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography
                            sx={{ width: "100%", flexShrink: 0 }}
                            className="uppercase flex justify-start items-center gap-2"
                          >
                            <Avatar
                              src={
                                item.avatar
                                  ? `${process.env.REACT_APP_HOST_API_KEY}/${item.avatar}`
                                  : item.username
                              }
                              alt={item.username}
                              sx={{
                                width: 32,
                                height: 32,
                              }}
                              className="darkShadow"
                            />
                            <p className="p0 font-medium capitalize overflow-wrap-anywhere">
                              {item.username}
                            </p>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                              <Table>
                                <TableRow
                                  sx={{ borderBottom: "1px solid #e5e7eb" }}
                                >
                                  <TableHead>
                                    <TableCell
                                      sx={{
                                        border: "none",
                                      }}
                                    >
                                      {t("users_page.9irzh8r6y")}
                                    </TableCell>
                                  </TableHead>
                                  <TableCell>
                                    <Avatar
                                      src={
                                        item.avatar
                                          ? `${process.env.REACT_APP_HOST_API_KEY}/${item.avatar}`
                                          : item.username
                                      }
                                      alt={item.username}
                                      className="darkShadow"
                                    />
                                  </TableCell>
                                </TableRow>

                                <TableRow
                                  sx={{ borderBottom: "1px solid #e5e7eb" }}
                                >
                                  <TableHead>
                                    <TableCell
                                      sx={{
                                        border: "none",
                                      }}
                                    >
                                      {t("users_page.hrj5j1un8")}
                                    </TableCell>
                                  </TableHead>
                                  <TableCell className="uppercase overflow-wrap-anywhere">
                                    {item.username}
                                  </TableCell>
                                </TableRow>
                                <TableRow
                                  sx={{ borderBottom: "1px solid #e5e7eb" }}
                                >
                                  <TableHead>
                                    <TableCell
                                      sx={{
                                        border: "none",
                                      }}
                                    >
                                      {t("users_page.9h99xanbm")}
                                    </TableCell>
                                  </TableHead>
                                  <TableCell className="uppercase overflow-wrap-anywhere">
                                    {item.email}
                                  </TableCell>
                                </TableRow>
                                <TableRow
                                  sx={{ borderBottom: "1px solid #e5e7eb" }}
                                >
                                  <TableHead>
                                    <TableCell
                                      sx={{
                                        border: "none",
                                      }}
                                    >
                                      {t("users_page.7hdgcvcvn")}
                                    </TableCell>
                                  </TableHead>
                                  <TableCell className="uppercase overflow-wrap-anywhere">
                                    {moment(item.created_at).format(
                                      "MMMM DD YYYY"
                                    )}
                                  </TableCell>
                                </TableRow>
                              </Table>
                            <div className="py-4 w-full flex">
                              <ThemeProvider
                                theme={themes.MenuActiveButtonTheme_2}
                              >
                                <ButtonGroup
                                  variant="contained"
                                  aria-label="action button"
                                  sx={{ margin: "auto" }}
                                >
                                  <Button
                                    fullWidth
                                    onClick={() =>
                                      navigate("/user-details", {
                                        state: { data: item },
                                      })
                                    }
                                  >
                                    <div className="flex justify-start items-center mr-auto px-6 ">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-3 h-3 "
                                        fill="currentColor"
                                        viewBox="0 0 576 512"
                                      >
                                        <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                      </svg>
                                      <span className="pl-2 p1 capitalize ">
                                        {t("users_page.kd5urbhtg")}
                                      </span>
                                    </div>
                                  </Button>
                                </ButtonGroup>
                              </ThemeProvider>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </ThemeProvider>
                  </div>
                ))}
            </div>
          </div>
          {/* pagenation */}
          <div className="flex justify-end items-center">
            {!loadingdata && total > 1 && (
              <Pagination
                count={total}
                hidePrevButton
                hideNextButton
                page={page}
                onChange={(e: any, pageNum) => {
                  setPage(pageNum);
                  setOffset((pageNum - 1) * limit);
                }}
                shape="rounded"
                sx={themes.MuiPagenationStyle_2}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
