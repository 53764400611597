import React, { useState, useEffect, lazy, Suspense } from "react";
import PageComponents from "../components";
import { Tabs, Tab, Box, ThemeProvider } from "@mui/material";
import { PreLoaderComponent } from "../../components";
import { useTranslation } from "react-i18next";
import Utils from "../../utils";
import Services from "../../services";
import { useParams } from "react-router-dom";

const ApplicationAuctionInfo = lazy(
  () => import("../components/ApplicationAuctionInfo.component")
);
const ApplicationBids = lazy(
  () => import("../components/ApplicationBids.component")
);
const ApplicationQA = lazy(
  () => import("../components/ApplicationQA.component")
);
const ApplicationComments = lazy(
  () => import("../components/AuctionComments.component")
);

const AuctionDetails = () => {
  const { t } = useTranslation();
  const params = useParams();
  const role = Services.Storage.getCurrentRole();
  const [value, setValue] = useState(0);
  const [auction_info, setAuctionInfo] = useState<any>({});
  const [comments, setComments] = useState<any[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);
  const [loadComments, setLoadComments] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getComments = () => {
    if (params.list_id) {
      let apiUrl;

      if (role !== "user") {
        apiUrl = Services.Listing.GetCommentsOfListingForAdmin(
          params.list_id,
          limit,
          offset
        );
      } else {
        apiUrl = Services.Listing.GetCommentsOfUserListings(
          params.list_id,
          limit,
          offset
        );
      }
      setLoading(true);
      apiUrl
        .then((res) => {
          if (res.status) {
            setTotal(res.total);
            setLoading(false);
            if (loadComments) {
              setComments([...comments, ...res.data]);
              setLoadComments(false);
            }
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getComments();
  }, [params.list_id, loadComments]);

  return (
    <>
      <PageComponents.MetaComponent
        title={t("auction_details_dashboard_page.lzaagxv8z")}
      />
      <div className=" ">
        <Box
          sx={{ maxWidth: "100%", overflowX: "auto" }}
        >
          <ThemeProvider theme={Utils.Themes().TabsStyle_2}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs"
            >
              <Tab label={t("auction_details_dashboard_page.qhljsjbwv")} />
              <Tab label={t("auction_details_dashboard_page.td8ks754v")} />
              <Tab label={t("auction_details_dashboard_page.pip1njg63")} />
              <Tab label={t("auction_details_dashboard_page.t9c5u37vi")} />
            </Tabs>
          </ThemeProvider>
        </Box>
        <div className=" py-5">
          <Suspense fallback={<PreLoaderComponent />}>
            {value === 0 ? (
              <ApplicationAuctionInfo
                setAuctionInfo={setAuctionInfo}
                data={{
                  offset,
                  setLoading,
                  totalComments: comments?.length
                }}
              />
            ) : value === 1 ? (
              <ApplicationBids auction_info={auction_info} />
            ) : value === 2 ? (
              <ApplicationQA auction_info={auction_info} />
            ) : value === 3 ? (
              <ApplicationComments
                data={{
                  comments,
                  auction_info,
                  loading,
                  length: comments?.length,
                }}
                limit={limit}
                setOffset={setOffset}
                total={total}
                setLoadComments={setLoadComments}
                getComments={getComments}
              />
            ) : (
              <></>
            )}
          </Suspense>
        </div>
      </div>
    </>
  );
};

export default AuctionDetails;
