import API from "../config/api.config";
import Auth from "./Auth.service";

const CreateAuction = (data: any) => {
  return API.post(`/api/admin/auctions/create`, data)
    .then((res: any) => {
      return res?.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status === 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error?.response?.data;
    });
};


const ChangeAuctionStatus = (data: any) => {
  return API.put(`/api/admin/auctions/status/change`, data)
    .then((res: any) => {
      return res?.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status === 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error?.response?.data;
    });
};

const GetAuctions = (
  offset: number,
  limit: number,
  search?: string | null,
  sort?: string | null,
  filter?: string | null,
  status?: string | null
) => {
  return API.get(`/api/admin/auctions`, {
    params: { offset, limit, search, sort, filter, status },
  })
    .then((res: any) => {
      return res?.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status === 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error?.response?.data;
    });
};

const GetApprovedListingForDropdown = () => {
  return API.get(`/api/admin/listings/approved/dropdown`)
    .then((res: any) => {
      return res?.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status === 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error?.response?.data;
    });
};

const CountAuctionView = (listing_id: number) => {
  return API.post(`/api/user/auctions/${listing_id}/views`)
    .then((res: any) => {
      return res?.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status === 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error?.response?.data;
    });
};

const Auction = {
  CountAuctionView,
  CreateAuction,
  GetAuctions,
  GetApprovedListingForDropdown,
  ChangeAuctionStatus,
};

export default Auction;
