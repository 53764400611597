import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Services from "../../services";
import { Grid, Pagination } from "@mui/material";
import AuctionCardComponent from "./AuctionCard.component";
import { useTranslation } from "react-i18next";
import { CardSkeletonElements } from "./CardsSkeleton.component";
import Utils from "../../utils";

const UserAuctions = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const themes = Utils.Themes();
  const [auctions, setAuctions] = useState<any[]>([]);
  const [loadingdata, setLoadingdata] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);

  const getAuctions = () => {
    setLoadingdata(true);
    location?.state?.data.id &&
      Services.User.getUserAuctionsForAdmin(
        location?.state?.data?.id,
        offset,
        limit
      )
        .then((res) => {
          setLoadingdata(false);
          if (res?.status) {
            res?.data && setAuctions(res?.data);
            res?.total && setTotal(Math.ceil(res?.total / limit));
          }
        })
        .catch((error) => {
          setLoadingdata(false);
        });
  };

  useEffect(() => {
    getAuctions();
  }, [offset, limit, page]);

  return (
    <>
      {loadingdata && (
        <Grid container spacing={2}>
          <CardSkeletonElements />
        </Grid>
      )}
      <div className="flex flex-col py-5">
        <div className="flex flex-wrap">
          <Grid container spacing={2}>
            {!loadingdata &&
              (auctions?.length > 0 ? (
                auctions?.map((item, index) => (
                  <Grid
                    item
                    className="px-3-"
                    lg={4}
                    md={6}
                    xs={12}
                    key={index}
                  >
                    <AuctionCardComponent
                      getListings={getAuctions}
                      item={{ ...item, ...item?.listing }}
                      showDetailsInProp={true}
                    />
                  </Grid>
                ))
              ) : (
                <p className="py-10 flex justify-center p1 items-center w-full">
                  {t("user_details_page.qzhjoe6t1")}
                </p>
              ))}
          </Grid>
        </div>
        <div className="py-3 flex justify-end items-center">
          {!loadingdata && total > 1 && (
            <div className="">
              <Pagination
                count={total}
                hidePrevButton
                hideNextButton
                page={page}
                onChange={(e: any, pageNum: number) => {
                  setPage(pageNum);
                  setOffset((pageNum - 1) * limit);
                }}
                shape="rounded"
                sx={themes.MuiPagenationStyle_2}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserAuctions;
