import { ApiConfig } from "../config";
import Auth from "./Auth.service";

const PostComment = (id: number | string, data: any) => {
  return ApiConfig.post(`/api/user/listings/${id}/comments`, data)
    .then((res: any) => {
      return res?.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status === 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error?.response?.data;
    });
};

const GetComments = (
  id: number,
  offset: number,
  limit: number,
  filter: string | null
) => {
  let url = `/api/user/listings/${id}/comments?offset=${offset}&limit=${limit}`;
  if (filter != null) {
    url = url + `&filter=${filter}`;
  }
  return ApiConfig.get(url)
    .then((res: any) => {
      return res?.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status === 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error?.response?.data;
    });
};

const UpvoteComment = (id: number, comment_id: number, data: any) => {
  return ApiConfig.post(
    `/api/user/listings/${id}/comments/${comment_id}/upvote`,
    data
  )
    .then((res: any) => {
      return res?.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status === 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error?.response?.data;
    });
};

const FlgComment = (id: number, comment_id: number, data: any) => {
  return ApiConfig.post(
    `/api/user/listings/${id}/comments/${comment_id}/flag`,
    data
  )
    .then((res: any) => {
      return res?.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status === 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error?.response?.data;
    });
};

const Comment = {
  PostComment,
  GetComments,
  UpvoteComment,
  FlgComment,
};

export default Comment;
