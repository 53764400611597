import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { NavComponent, FooterComponent, HeaderComponent } from "../components";

function NotFoundLayout() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="sticky top-0 z-50">
        <NavComponent />
      </div>
      <HeaderComponent />
      <div className="bg-white dark:bg-[--dark]">
        <div className="mainContainer min-h-screen mainContainer">
          <div className="">
            <Outlet />
          </div>
        </div>
      </div>
      <div className="bg-[--dark]">
        <FooterComponent />
      </div>
    </>
  );
}

export default NotFoundLayout;
