import React, { useState, memo } from "react";
import { ThemeProvider, Tabs, Tab, Typography, Box } from "@mui/material";
import { Modal, ModalClose, Sheet } from "@mui/joy";
import Utils from "../../utils";
import Shipping from "./Shipping.component";
import Financing from "./Financing.component";

interface modalProps {
  modalOpen: any;
  setIsModalOpen: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = memo(function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
});

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const QuoteModal = memo(({ modalOpen, setIsModalOpen }: modalProps) => {
  const [value, setValue] = useState(0);
  const themes= Utils.Themes();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={modalOpen}
        hideBackdrop={true}
        onClose={() => {
          setIsModalOpen(false);
        }}
        sx={themes.JoyModalStyle_2}
      >
        <Sheet variant="outlined" sx={themes.JoyModalSheetStyle_2}>
          <ModalClose sx={themes.JoyModalCloseStyle_2} />
          <div className="mt-4 w-100 px-0 overflow-y-auto max-h-[80vh] scroll-none">
            <div className="flex flex-row ">
              <div className="mt-3 w-full">
                <Box sx={{ width: "100%" }}>
                  <Box className="flex justify-center items-center">
                    <ThemeProvider theme={themes.TabsStyle_2}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                      >
                        <Tab label="Shipping" {...a11yProps(0)} />
                        <Tab label="Financing" {...a11yProps(1)} />
                      </Tabs>
                    </ThemeProvider>
                  </Box>
                  <Box>
                    <CustomTabPanel value={value} index={0}>
                      <Shipping />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      <Financing />
                    </CustomTabPanel>
                  </Box>
                </Box>
              </div>
            </div>
          </div>
        </Sheet>
      </Modal>
    </>
  );
});

export default QuoteModal;
