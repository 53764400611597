// import & export utilities

import Validator from "./Validator.utils";
import Theme, { Themes } from "./Theme.utils";
import RegExp from "./RegExp.utils";
import HtmlToText from "./htmlToText";
import CalculateDays, { ConvertToLocal } from "./CalculateDays";
import InvertColorByName from "./invertColorByName.utils";

const Utils = {
  Themes,
  InvertColorByName,
  RegExp,
  Validator,
  Theme,
  HtmlToText,
  CalculateDays,
  ConvertToLocal,
};

export default Utils;
