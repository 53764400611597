import { Skeleton as MuiSkeleton } from "@mui/material";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CardSkeletonComponent from "../pages/components/CardsSkeleton.component";
import { useContext } from "react";
import { ThemeContext } from "../context/theme.context";
import Hooks from "../hooks";

interface NskeletonProps {
  classes?: string;
}

const AuctionDetailsSkeletonComponent = () => {
  const { isDark } = useContext(ThemeContext);
  const devices = Hooks.useDevice();

  const style = {
    borderRadius: "0.5rem",
    height: "100%",
    width: "100%",
    background: isDark ? "var(--gray)" : "var(--light)",
  };

  const Nskeleton = ({ classes }: NskeletonProps) => {
    return (
      <div className={`${classes || "w-full h-8"}`}>
        <MuiSkeleton sx={style} variant="rectangular" />
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col mt-6 text-gray-700 bg-white dark:bg-[--dark]  px-4 py-3 gap-6 lg:gap-4 h-full ">
      {/* title skeleton */}
      <div className="flex flex-col md:flex-row gap-1 justify-start md:items-start">
        <div className="flex flex-col gap-1.5 w-full md:w-2/3">
          <Nskeleton />
          <Nskeleton />
        </div>
        <div className=" w-2/3 md:w-1/3 flex justify-start md:justify-end items-start md:items-center">
          <Nskeleton classes="w-[140px] md:w-[220px] h-8" />
        </div>
      </div>
      {/* images skeleton */}
      <div className="flex flex-col lg:flex-row gap-1 md:gap-2 lg:gap-3 xl:gap-4 justify-center items-center">
        <div className="w-full lg:w-3/4 xl:w-2/3">
          <Nskeleton classes="w-full h-[300px]" />
        </div>
        <div className=" w-full lg:w-1/4 xl:w-1/3">
          <div className="flex flex-wrap justify-between items-center  w-full">
            {_.times(devices.is_xs ? 2 : devices.is_lg ? 3 : 6, (index) => (
              <div
                className="w-1/2 sm:w-1/3 lg:w-full xl:w-1/2 p-0.5"
                key={index}
              >
                <Nskeleton
                  classes={`w-full ${devices.is_md ? "h-[160px]" : " h-[95px]"}`}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="flex gap-1 md:gap-2 lg:gap-3 xl:gap-4 justify-between items-start w-full ">
        {/* left side */}
        <div className="w-full xl:w-2/3 flex flex-col gap-1 md:gap-1.5 lg:gap-2 ">
          <Nskeleton />
          <Nskeleton classes="w-full h-[60px]" />
          <Nskeleton classes="w-full h-[300px]" />
          <Nskeleton classes="w-full h-[100px]" />
          {_.times(2, (index) => (
            <div
              key={index}
              className="flex flex-col gap-1 md:gap-1.5 lg:gap-2 py-4"
            >
              <Nskeleton />
              <Nskeleton classes="w-full h-[200px]" />
              <hr className="mt-4 hrDarkMode" />
            </div>
          ))}
          {_.times(2, (index) => (
            <div key={index}>
              <Nskeleton classes="w-full h-[60px]" />
            </div>
          ))}
        </div>
        {/* right side */}
        <div className="sm:w-1/3 hidden xl:block ">
          <Nskeleton />
          {_.times(2, (index) => (
            <div key={index} className="py-2">
              <CardSkeletonComponent />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AuctionDetailsSkeletonComponent;
