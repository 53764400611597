import Crypto from "./Crypto.service";

// setSession in local storage
const setEVS = (evs: any) => {
  // encrypt evs (email verfication setting) data before store it on the localstorage
  const envString = JSON.stringify(evs);
  const enc_data = Crypto.encrypUsertData(envString);
  localStorage.setItem("evs", enc_data);
};

// setDailyEmail in local storage
const setDailyEmail = (email: string) => {
  const enc_data = Crypto.encrypUsertData(email);
  localStorage.setItem("de", enc_data);
};

// setSession in local storage
const setSession = (token: string, user: any) => {
  localStorage.setItem("token", token);
  // encrypt user data before store it on the localstorage
  const userString = JSON.stringify(user);
  const enc_data = Crypto.encrypUsertData(userString);
  localStorage.setItem("user", enc_data);
  return { user: getCurrentUser(), token: getToken() };
};

// setDeviceToken in local storage
const setDeviceToken = (token: any) => {
  localStorage.setItem("device_token", token);
};

// getDeviceToken in local storage
const getDeviceToken = () => {
  const device_token = localStorage.getItem("device_token");
  return device_token;
};

// setToggleNavWidth in local storage
const setToggleNavWidth = (value: string) => {
  localStorage.setItem("toggle_nav_width", value);
};

// getToggleNavWidth from local storage
const getToggleNavWidth = () => {
  const toggle_nav_width = localStorage.getItem("toggle_nav_width");
  return toggle_nav_width;
};

// set access token
const setToken = (token: string) => {
  localStorage.setItem("token", token);
};

// update info
const updateUser = (user: JSON) => {
  // encrypt user data before store it on the localstorage
  const userString = JSON.stringify(user);
  const enc_data = Crypto.encrypUsertData(userString);
  localStorage.setItem("user", enc_data);
};

// clear session of local storage
const clearSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user_role");
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  const dec_user = localStorage.getItem("user");
  let user;
  if (dec_user && dec_user !== null) {
    user = Crypto.dcryptUserData(dec_user);
    user = JSON.parse(Crypto.dcryptUserData(dec_user));
  }
  return user;
};

const getEVS = () => {
  const dec_evs = localStorage.getItem("evs");
  let evs;
  if (dec_evs && dec_evs !== null) {
    evs = Crypto.dcryptUserData(dec_evs);
    evs = JSON.parse(Crypto.dcryptUserData(dec_evs));
  }
  return evs;
};

const getDailyEmail = () => {
  const dailyEmail = localStorage.getItem("de");
  let de;
  if (dailyEmail && dailyEmail !== null) {
    de = Crypto.dcryptUserData(dailyEmail);
  }
  return de;
};

const unsubscribeDailyEmail = () => {
  return localStorage.removeItem("de");
};

const isDailyMailSubscribed = () => {
  const dailyEmail = getDailyEmail();
  let subscribed: boolean = false;
  if (dailyEmail) {
    subscribed = true;
  }
  return subscribed;
};

const getToken = () => {
  const token = localStorage.getItem("token");
  return token;
};

const setUserRole = (user_role: string) => {
  const enc_data = Crypto.encrypUserRole(user_role);
  localStorage.setItem("user_role", enc_data);
  return { role: getCurrentRole };
};

const getCurrentRole: any = () => {
  const dec_user = localStorage.getItem("user_role");
  let user;
  if (dec_user && dec_user !== null) {
    user = Crypto.dcryptUserRole(dec_user);
  }
  return user;
};

// setCookieConsent in local storage
const setCookieConsent = (data: string) => {
  localStorage.setItem("cookieConsent", data);
};

// getCookieConsent in local storage
const getCookieConsent = () => {
  return localStorage.getItem("cookieConsent") || "";
};

const Storage = {
  setToggleNavWidth,
  getToggleNavWidth,
  setCookieConsent,
  getCookieConsent,
  isDailyMailSubscribed,
  setEVS,
  getEVS,
  setDeviceToken,
  setUserRole,
  updateUser,
  setSession,
  clearSession,
  getToken,
  getCurrentUser,
  setToken,
  getCurrentRole,
  getDeviceToken,
  setDailyEmail,
  getDailyEmail,
  unsubscribeDailyEmail,
};

export default Storage;
