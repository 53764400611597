import { ApiConfig } from "../config";
import Auth from "./Auth.service";

const GetStats = () => {
  return ApiConfig.get("api/user/system/stats")
    .then((res: any) => {
      return res?.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status === 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error?.response?.data;
    });
};

const GetReviews = (offset: number, limit: number) => {
  return ApiConfig.get(
    `/api/user/system/rating-reviews?offset=${offset}&limit=${limit}`
  )
    .then((res: any) => {
      return res?.data;
    })
    .catch(async (error: any) => {
      if (error?.response?.status === 401) {
        await Auth.Logout();
        window.location.reload();
      }
      return error?.response?.data;
    });
};

const System = {
  GetStats,
  GetReviews,
};

export default System;
