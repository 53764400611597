
const HtmlToText = (html: string) => {
  let text = "";
  var temp = document.createElement("div");
  temp.innerHTML = html;
  temp.textContent && (text = temp.textContent);
  return { text };
};

export default HtmlToText;
