import { memo } from "react";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CircleIcon from "./CircleIcon.component";

const CarAuctionsCarousel = memo(({ auctions }: any) => {
  const navigate = useNavigate();

  return auctions?.length > 0 ? (
    <Carousel
      autoPlay={true}
      infiniteLoop={true}
      emulateTouch={true}
      swipeable={true}
      showThumbs={false}
      showArrows={false}
      className="cursor-grab adminDashboardSwiper"
      showStatus={false}
    >
      {auctions?.map((item: any, index: number) => (
        <div key={index} className="h-full w-full">
          <div className="sliderOverlay"></div>
          <img
            src={`${process.env.REACT_APP_HOST_API_KEY}/${item.uri}`}
            loading="lazy"
            className="w-full h-full bg-cover drop-shadow-sm shadow-sm rounded object-cover"
          />
          <div
            className="carsouelContent w-full px-2"
            onClick={() => navigate(`/auction-details/${item.uuid}`)}
          >
            <h2 className="cardMainTitle">{item?.title ? item?.title : ""}</h2>
            <h3
              className="cardSubTitle"
              style={{
                color: "var(--light)",
              }}
            >
              {item?.make}
              <CircleIcon className="dot" />
              {item?.model + " " + item?.year}
              <CircleIcon className="dot" />
              {item?.mileage}
              {" KM"}
            </h3>
          </div>
          <p className="legend bgTransparent"></p>
        </div>
      ))}
    </Carousel>
  ) : (
    <></>
  );
});

export default CarAuctionsCarousel;
