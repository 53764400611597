import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { DashboardSidebar, DashboardNav, FloatingScroller } from "../components";
import CheckUserType, { isUser_loged_in } from "../utils/checkUserType.utils";
import { Backdrop } from "@mui/material";

const Dashboard = () => {
  const navigate = useNavigate();
  const isLogedIn = isUser_loged_in();
  const [showSidebar, setShowSidebar] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (!isLogedIn || !CheckUserType.isAdmin_and_loged_in()) && navigate("/");
  }, [isLogedIn]);

  return (
    <div className="bg-white dark:bg-[--dark]">
      <div className="flex max-w-screen h-screen box-border ">
        <DashboardSidebar
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
        />
        <div className="w-full overflow-x-hidden">
          <div>
            <DashboardNav
              showSidebar={showSidebar}
              setShowSidebar={setShowSidebar}
            />
            <div className="containerSpacing py-8  shadow3 ">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => 10 }}
        open={showSidebar}
        onClick={() => setShowSidebar(!showSidebar)}
      />
    </div>
  );
};

export default Dashboard;
