import { useEffect, useState } from "react";
import Utils from "../utils";
import Hooks from "../hooks";
import { Images } from "../assets";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, ThemeProvider } from "@mui/material";

const HeaderComponent = () => {
  const navigate = useNavigate();
  const themes = Utils.Themes();
  const location = useLocation();
  const { width } = Hooks.useScreen();
  const [search, setSearch] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    return navigate(`/search/${search}`);
  };

  useEffect(() => {
    if (location?.pathname?.split("/")[1] !== "search") {
      setSearch("");
    }
  }, [location]);

  return (
    <div
      // old classes whcih is fully responsive
      className="relative h-[40vh] sm:h-[45vh] lg:h-[55vh] bg-cover bg-center bg-no-repeat "

      // new temprary class which is responsive for current background
      // className="relative h-[30vh] sm:h-[36vh] md:h-[45vh] lg:h-[55vh] bg-cover bg-center bg-no-repeat "
      style={{
        // backgroundImage: `url('${width > 1024 ? Images.DesktopBanner : Images.MobileBanner}')`,
        backgroundImage: `url('${width > 1024 ? Images.NewHeaderBg : Images.NewHeaderMobileBg}')`,
      }}
    >
      {/* heading */}
      <div className="absolute flex justify-center w-full bottom-10 sm:bottom-16">
        <h2
          className="headerTitle"
          style={{ fontWeight: 400 }}
        >
          Wouldn't <span className="font-bold">sell</span> a used{" "}
          <span className="font-bold">vehicle</span> any other way!
        </h2>
      </div>
      {/* search form */}
      <div className="absolute flex justify-center w-full -bottom-8">
        <div className="lg:w-1/2 md:w-9/12 sm:w-10/12 w-11/12 z-20 mx-auto">
          <form
            onSubmit={handleSubmit}
            className="mx-auto InputFieldSetting w-full"
          >
            <div className="searchBox focus-within:border-[--main-color] mx-auto">
              <div className="flex justify-between items-center gap-1 sm:gap-2 w-full">
                <input
                  type="search"
                  id="default-search"
                  className="h-full w-full border-none bg-transparent"
                  placeholder="Search for cars ..."
                  required
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  autoComplete="off"
                />
                <div className="">
                  <ThemeProvider theme={themes.ButtonActiveTheme_2}>
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{ minWidth: "36px", minHeight: "42px" }}
                    >
                      <svg
                        className="w-4 h-4 text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                        />
                      </svg>
                    </Button>
                  </ThemeProvider>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
