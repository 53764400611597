import { AppRoutes } from "./routes/app.routes";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  const appRoutes = AppRoutes.map(({ Component, children }, key) => (
    <Route key={key} element={<Component />}>
      {children.map(({ path, Component }, key) => (
        <Route key={key} path={path} element={<Component />} />
      ))}
    </Route>
  ));

  return (
    <div className="bg-[--dark]">
      <BrowserRouter>
        <Routes>{appRoutes}</Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
