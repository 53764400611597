import React from "react";
import { Images } from "../../assets";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";

const ProfileCommentHistory = () => {
  return (
    <>
      <div className="p-1 flex flex-col sm:flex-row justify-start items-start gap-3 w-full lg:w-5/6 xl:w-7/12">
        <div className="relative">
          <img
            src={Images.BestCar}
            className="rounded border shadow-sm  w-full sm:w-[225px] sm:max-w-[325px] "
          />
          <div className="absolute bottom-[10px] left-[10px]">
            <div className="text-white bg-[rgba(0,0,0,0.6)] flex justify-between items-center gap-3  shadow-sm p-1 rounded">
              <div className="flex gap-1 justify-start items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="sm::w-2 sm:h-2 md:w-3 md:h-3 lg:w-3.5 lg:h-3.5"
                >
                  <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                </svg>
                <Countdown date={Date.now() + 200000000} daysInHours={true} />
              </div>
              <div className="flex  justify-end items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="h-4"
                >
                  <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                </svg>
                <span>7425 CHF</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-start">
          <Link to="#"   target="_self" className="hover:underline">
            <h4 className="text-base md:text-lg font-bold py-0 leading-4">
              2014 Mercedes-Benz E63 S AMG Wagon
            </h4>
          </Link>
          <span className="textGray text-sm py-0">August 17, 2023 7:05 AM</span>
          <p className="text-sm md:text-base py-1">
            Clearly to nice of a car to be parking on the street in New York
            City! Low mileage wins out! Tempting for sure! Clearly to nice of a
            car to be parking on the street in New York
          </p>
          <div className="flex  justify-end items-center textGray">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              stroke="currentColor"
              strokeWidth="2"
              fill="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="h-3"
            >
              <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
            </svg>
            <span>2</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCommentHistory;
