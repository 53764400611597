import { useState, useEffect, Suspense } from "react";
import { ThemeProvider, Button, Box, Grid } from "@mui/material";
import Utils from "../../utils";
import PageComponents from "../components/index";
import { Link, useNavigate } from "react-router-dom";
import Services from "../../services";
import { Carousel } from "react-responsive-carousel";
import _ from "lodash";
import moment from "moment";
import { isUser_loged_in } from "../../utils/checkUserType.utils";
import AuctionCardComponent from "../components/AuctionCard.component";
import { useTranslation } from "react-i18next";

type listingType = any[];

export default function SellACar() {
  const isUserLogedIn = isUser_loged_in();
  let navigate = useNavigate();
  const { t } = useTranslation();
  const themes = Utils.Themes();
  const [stats, setStats] = useState<any>({});
  const [reviews, setReviews] = useState<any[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(8);
  const [loading, setLoading] = useState<boolean>(false);
  const [listings, setListings] = useState<listingType>([]);

  const getStats = () => {
    Services.System.GetStats().then((res) => {
      res.status && setStats(res.data);
    });
  };

  useEffect(() => getStats(), []);

  const getReviews = () => {
    Services.System.GetReviews(offset, limit).then((res) => {
      res.status && setReviews(res.data);
    });
  };

  useEffect(() => getReviews(), [offset, limit]);

  const handleTimes = (rating: number) => {
    const ratingHtml = _.times(rating, (index) => {
      return (
        <span key={index} className="text-[var(--main-color)]">
          <svg
            aria-hidden="true"
            className="w-5 h-5 "
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
          </svg>
        </span>
      );
    });
    return ratingHtml;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getListings = () => {
    setLoading(true);
    Services.Listing.GetListings(
      offset,
      limit,
      "result",
      null,
      null,
      null,
      null,
      null,
      null,
      "sold"
    )
      .then((res) => {
        setLoading(false);
        setListings(res.data);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => getListings(), [offset, limit]);

  return (
    <>
      <PageComponents.MetaComponent title={t("sell_a_car_page.page_title")} />
      <div className="containerSpacing py-16">
        <div>
          <h2 className="head text-center sm:text-left">
            <p>{t("sell_a_car_page.title")}</p>
            <p>{t("sell_a_car_page.title2")}.</p>
          </h2>
          <p className=" text-center sm:text-left">
            <Link
              className="sm:ml-auto text-[var(--main-color)] hover:underline hover:opacity-60 p1"
              to="/listings"
            >
              {t("sell_a_car_page.user_listing")}
            </Link>
          </p>
          {/* reviews and sell on auto bids */}
          <div className="flex flex-col py-8 lg:flex-row gap-8 w-full">
            {/* sell on auto bids */}
            <div className=" flex flex-col lg:w-1/2">
              <div className="flex flex-col gap-y-6">
                <div className="flex flex-row w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="var(--main-color)"
                    className="w-10 h-8 p-1 bg-[var(--light-color)] rounded"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
                    />
                  </svg>
                  <span className=" flex flex-col w-full md:ml-8 ml-4">
                    <p className="title-font font-medium text-gray-900 w-full p1">
                      <span className="font-bold w-full">
                        {t("sell_a_car_page.p1")}
                      </span>{" "}
                      {t("sell_a_car_page.p2")}
                    </p>
                  </span>
                </div>
                <div className="flex items-center w-full ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="var(--main-color)"
                    className="w-10 h-8 p-1 bg-[var(--light-color)] rounded"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                    />
                  </svg>
                  <span className="w-64 flex-grow flex flex-col md:ml-8 ml-4">
                    <p className="title-font font-medium text-gray-900 p1">
                      <span className="font-medium">
                        {t("sell_a_car_page.p3")}{" "}
                      </span>{" "}
                      <span className="font-bold">
                        {t("sell_a_car_page.p4")}
                      </span>{" "}
                      {t("sell_a_car_page.p5")}
                    </p>
                  </span>
                </div>
                <div className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="var(--main-color)"
                    className="w-10 h-8 p-1 bg-[var(--light-color)] rounded"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span className="w-96 flex-grow flex flex-col md:ml-8 ml-4">
                    <p className="title-font font-medium text-gray-900 p1">
                      <span className="font-medium">
                        {t("sell_a_car_page.p6")}
                      </span>{" "}
                      <span className="font-bold">
                        {t("sell_a_car_page.p7")}
                      </span>{" "}
                      {t("sell_a_car_page.p8")}
                    </p>
                  </span>
                </div>
                <div className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="var(--main-color)"
                    className="w-10 h-8 p-1 bg-[var(--light-color)] rounded"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span className="w-96 flex-grow flex flex-col md:ml-8 ml-4 ">
                    <p className="p1 title-font font-medium text-gray-900">
                      <span className=" font-bold">
                        {t("sell_a_car_page.p9")}
                      </span>{" "}
                      {t("sell_a_car_page.p10")}
                    </p>
                  </span>
                </div>
              </div>
            </div>
            {/* customer reviews */}
            <div className=" flex flex-col lg:w-5/12">
              <h3 className="lg:justify-start justify-center head title-bold font-bold  mb-4  text-center sm:text-left">
                {t("sell_a_car_page.title3")}
              </h3>
              <div className="w-full">
                {reviews?.length > 0 && (
                  <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    emulateTouch={true}
                    swipeable={true}
                    showThumbs={false}
                    showArrows={false}
                    className="cursor-grab reviewCarousel w-full"
                    showStatus={false}
                  >
                    {reviews?.map((review: any, index: number) => (
                      <div className="px-1">
                        <div
                          className="bg-[var(--light-color)] rounded-[--butn-border-radius] p-6 w-full h-full select-none"
                          key={index}
                        >
                          <div className="flex flex-col justify-start text-start items-start gap-2 flex-wrap">
                            <div className="flex justify-start items-start gap-1 p1">
                              {handleTimes(review.rating)}
                            </div>
                            <div>
                              <span className="textGray p1 dark:text-black">
                                {moment(review.created_at).format("MMM YYYY")}
                              </span>
                              <p className="font-extrabold capitalize p1 break-all dark:text-black">
                                {review?.user?.username}.
                                {t("sell_a_car_page.p11")}
                              </p>
                            </div>

                            <p className="text-left break-all p1 dark:text-black">
                              {review.review}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            </div>
          </div>
          {/* sell now */}
          <div className="flex flex-col py-8 items-center justify-center w-full">
            <h3 className="head font-bold justify-center items-center text-center sm:text-left">
              {t("sell_a_car_page.title4")}
            </h3>
            <Box className="md:w-96 sm:w-76 w-60 pt-6">
              <ThemeProvider theme={themes.ButtonTheme_2}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() =>
                    isUserLogedIn
                      ? navigate("/sell-car/submit")
                      : document.getElementById("signin")?.click()
                  }
                  sx={{
                    paddingTop: "0.75rem",
                    paddingBottom: "0.75rem",
                    textTransform: "none",
                  }}
                >
                  {t("sell_a_car_page.sell_no_button_text")}
                </Button>
              </ThemeProvider>
            </Box>
          </div>
          {/* stats */}
          <div className="flex flex-col py-8 items-center w-full">
            <h3 className="head text-center sm:text-left">
              {t("sell_a_car_page.why_auto_bids_section.heading")}
            </h3>
            <div className="list-none grid grid-cols-1 sm:grid-cols-2 gap-8 pt-12 ">
              <div className="whyAutoBidsItem">
                <span className="stats">{stats?.auctions_completed}</span>
                <p className="label">
                  {t("sell_a_car_page.why_auto_bids_section.heading2")}
                </p>
              </div>
              <div className="whyAutoBidsItem">
                <span className="stats">{stats?.sell_through_rate}</span>
                <p className="label">
                  {t("sell_a_car_page.why_auto_bids_section.heading3")}
                </p>
              </div>
              <div className="whyAutoBidsItem">
                <span className="stats">{stats?.value_of_cars_sold}</span>
                <p className="label">
                  {t("sell_a_car_page.why_auto_bids_section.heading4")}
                </p>
              </div>
              <div className="whyAutoBidsItem">
                <span className="stats">{stats?.registered_members}</span>
                <p className="label">
                  {t("sell_a_car_page.why_auto_bids_section.heading5")}
                </p>
              </div>
            </div>
          </div>
          {/* recent sales */}
          <div className="flex flex-col py-8 items-center">
            <h3 className="head font-bold text-center sm:text-left pb-6">
              {t("sell_a_car_page.recent_sale_section.heading")}
            </h3>
            <Grid container spacing={1} alignItems={"center"}>
              <Suspense fallback={<PageComponents.CardSkeletonElements />}>
                {loading ? (
                  <PageComponents.CardSkeletonElements />
                ) : (
                  listings?.length > 0 &&
                  listings?.map((item, index) => (
                    <Grid
                      item
                      className="px-2"
                      lg={4}
                      md={6}
                      xs={12}
                      key={index}
                    >
                      <AuctionCardComponent
                        showDetailsInProp={false}
                        item={item}
                      />
                    </Grid>
                  ))
                )}
              </Suspense>
            </Grid>
          </div>
          {/* our auctions */}
          <div className="flex flex-col py-8 items-center">
            <h3 className="pb-6 head font-bold ">
              {t("sell_a_car_page.our_auctions_section.heading")}
            </h3>
            <div className="relative flex flex-col w-full lg:flex-row mt-1 bg-[--icon-color] dark:bg-transparent dark:border dark:border-[--gray] darkShadow p-6 gap-8 ">
              <div className="flex flex-col gap-1">
                <h4 className="font-bold p1 dark:text-white">
                  {t("sell_a_car_page.our_auctions_section.p1")}
                </h4>
                <p className="p1">
                  {t("sell_a_car_page.our_auctions_section.p2")}
                </p>
              </div>
              {/* start from here */}
              <div className="absolute rotate-90 lg:rotate-0 hidden lg:block lg:mt-0 right-1/2 w-0.5 h-1/2 xl:h-[42%] bgColor bottom-0 my-7"></div>
              <div className="flex flex-col gap-1">
                <h4 className="font-bold p1 dark:text-white">
                  {t("sell_a_car_page.our_auctions_section.p3")}
                </h4>
                <p className="p1">
                  {t("sell_a_car_page.our_auctions_section.p4")}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col py-8 mx-auto- items-center w-full">
            <h3 className="head font-bold">
              {t("sell_a_car_page.how_work_section.heading")}
            </h3>
            {/* flow diagram start here */}
            <div className="flex flex-col items-center gap-1">
              <div className="py-7 lg:flex lg:flex-row flex-col w-full h-full lg:space-x-4 items-center justify-center gap-1" id="flow_diagram">
                <div className="flow_item">
                  <div className="lg:flex-grow ">
                    <div className="text-center">
                      <span className="relative inline-block">
                        <div className="bg-[var(--light-color)] rounded-lg p-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-10 h-auto"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                            />
                          </svg>
                        </div>
                        <span className="absolute -top-4 -left-5 px-3.5 py-1 font-bold text-red-100 transform bgColor rounded-full p2">
                          1
                        </span>
                      </span>
                      <p className="w-40 pt-2 text-gray-500 p2">
                        {t("sell_a_car_page.how_work_section.p1")}
                      </p>
                    </div>
                  </div>
                </div>
                <PageComponents.RightArrowSvg />
                <div className="flow_item">
                  <div className="lg:flex-grow">
                    <div className="text-center">
                      <span className="relative inline-block">
                        <div className="bg-[var(--light-color)] rounded-lg p-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-10 h-auto"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12"
                            />
                          </svg>
                        </div>
                        <span className="absolute -top-4 -left-5 px-3.5 py-1 p2 font-bold text-red-100 transform bgColor rounded-full">
                          2
                        </span>
                      </span>
                      <p className="w-40 pt-2 p2 text-gray-500">
                        {t("sell_a_car_page.how_work_section.p2")}
                      </p>
                    </div>
                  </div>
                </div>
                <PageComponents.RightArrowSvg />
                <div className="flow_item">
                  <div className="lg:flex-grow">
                    <div className="text-center">
                      <span className="relative inline-block">
                        <div className="bg-[var(--light-color)] rounded-lg p-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-10 h-auto"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z"
                            />
                          </svg>
                        </div>
                        <span className="absolute -top-4 -left-5 px-3.5 py-1 p1 font-bold text-red-100 transform bgColor rounded-full">
                          3
                        </span>
                      </span>
                      <p className="w-40 pt-2 p2 text-gray-500">
                        {t("sell_a_car_page.how_work_section.p3")}
                      </p>
                    </div>
                  </div>
                </div>
                <PageComponents.RightArrowSvg />
              </div>
              <div className=" lg:flex lg:flex-row lg:space-x-4 items-center justify-center">
                <div className="lg:w-1/3 lg:flex lg:flex-col lg:items-center">
                  <div className="lg:flex-grow">
                    <div className="text-center">
                      <span className="relative inline-block">
                        <div className="bg-[var(--light-color)] rounded-lg p-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-10 h-auto"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z"
                            />
                          </svg>
                        </div>
                        <span className="absolute -top-4 -left-5 px-3.5 py-1 p1 font-bold text-red-100 transform bgColor rounded-full">
                          6
                        </span>
                      </span>
                      <p className="w-40 pt-2 p2 text-gray-500">
                        {t("sell_a_car_page.how_work_section.p4")}
                      </p>
                    </div>
                  </div>
                </div>
                <PageComponents.LeftArrowSvg />
                <div className="lg:w-1/3 lg:flex lg:flex-col lg:items-center">
                  <div className="lg:flex-grow">
                    <div className="text-center">
                      <span className="relative inline-block">
                        <div className="bg-[var(--light-color)] rounded-lg p-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-10 h-auto"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
                            />
                          </svg>
                        </div>
                        <span className="absolute -top-4 -left-5 px-3.5 py-1 p1 font-bold text-red-100 transform bgColor rounded-full">
                          5
                        </span>
                      </span>
                      <p className="w-40 pt-2 p2 text-gray-500">
                        {t("sell_a_car_page.how_work_section.p5")}
                      </p>
                    </div>
                  </div>
                </div>
                <PageComponents.LeftArrowSvg />
                <div className="lg:w-1/3 lg:flex lg:flex-col lg:items-center">
                  <div className="lg:flex-grow">
                    <div className="text-center">
                      <span className="relative inline-block">
                        <div className="bg-[var(--light-color)] rounded-lg p-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-10 h-auto"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                            />
                          </svg>
                        </div>
                        <span className="absolute -top-4 -left-5 px-3.5 py-1 p1 font-bold text-red-100 transform bgColor rounded-full">
                          4
                        </span>
                      </span>
                      <p className="w-40 pt-2 p2 text-gray-500">
                        {t("sell_a_car_page.how_work_section.p6")}
                      </p>
                    </div>
                  </div>
                </div>
                <PageComponents.LeftArrowSvg />
              </div>
              <div className="lg:flex lg:flex-row lg:space-x-4 items-center justify-center pt-4">
                <div className="lg:w-1/3 lg:flex lg:flex-col lg:items-center">
                  <div className="lg:flex-grow">
                    <div className="text-center">
                      <span className="relative inline-block">
                        <div className="bg-[var(--light-color)] rounded-lg p-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-10 h-auto"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                            />
                          </svg>
                        </div>
                        <span className="absolute -top-4 -left-5 px-3.5 py-1 p1 font-bold text-red-100 transform bgColor rounded-full">
                          7
                        </span>
                      </span>
                      <p className="w-40 pt-2 p2 text-gray-500">
                        {t("sell_a_car_page.how_work_section.p7")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* flow diagram ending here */}
            <Box className="w-full pt-4 flex justify-center">
              <ThemeProvider theme={themes.ButtonTheme_2}>
                <Button
                  onClick={() =>
                    isUserLogedIn
                      ? navigate("/sell-car/submit")
                      : document.getElementById("signin")?.click()
                  }
                  variant="contained"
                  sx={{
                    paddingTop: "0.75rem",
                    paddingBottom: "0.75rem",
                    minWidth: "320px",
                  }}
                >
                  {t("sell_a_car_page.how_work_section.get_started_butn_text")}
                </Button>
              </ThemeProvider>
            </Box>
            <p className="pt-4 items-center text-center">
              <Link
                className=" textColor cursor-pointer p1 font-medium"
                to="/about"
                state={{ div_id: "how-works" }}
              >
                {t("sell_a_car_page.how_work_section.learn_more_link")}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
