import { memo, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";

type CarImagesSwiperProps = {
  data: any;
  sliderIndex?: number | null;
  setSliderIndex?: any;
};

const CarImagesSwiper = memo(
  ({ data, sliderIndex, setSliderIndex }: CarImagesSwiperProps) => {
    const { t } = useTranslation();

    return (
      <>
        <Swiper
          spaceBetween={0}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          className="car-show-case-swiper"
          navigation={true}
          modules={[Navigation]}
          initialSlide={sliderIndex ? sliderIndex : 0}
        >
          {data && data?.length > 0 ? (
            data?.map((img: any, index: number) => (
              <SwiperSlide className="swiperWfull" key={index}>
                <img
                  src={process.env.REACT_APP_HOST_API_KEY + "/" + img}
                  className="w-full h-auto max-h-[80vh] max-w-screen object-contain drop-shadow-sm shadow-sm rounded mx-auto"
                  loading="lazy"
                  key={index}
                />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide className="swiperWfull" key={data?.length}>
              <p className="info_message">
                {t("auction_details_page.car_show_case.no_photo_message")}
              </p>
            </SwiperSlide>
          )}
        </Swiper>
      </>
    );
  }
);

export default CarImagesSwiper;
