import LanguageContextProvider, { LanguageContext } from "./language.context";
import ThemeContextProvider, { ThemeContext } from "./theme.context";

const Context = {
  LanguageContext,
  LanguageContextProvider,
  ThemeContextProvider,
  ThemeContext,
};

export default Context;
