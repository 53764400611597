import { useState, useEffect, memo, useContext } from "react";
import { Button, ThemeProvider } from "@mui/material";
import { Modal, Sheet, Input, ModalClose } from "@mui/joy";
import Utils from "../../utils";
import Services from "../../services";
import Swal from "sweetalert2";
import { PreLoaderComponent2, WarningAlert } from "../../components";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../context/theme.context";
import { AppImages } from "../../assets";

type bidType = {
  listing_id: number;
  price: number;
  card_id?: string;
};

interface bidProps {
  showBid: boolean;
  setShowBid: any;
  setShowCheckoutModal: any;
  auction_info: any;
  getComments: any;
  getListOfAuction: any;
  selectedCard: string;
  hideAgreementModal: any;
}

const CreateBid = memo(
  ({
    showBid,
    setShowBid,
    setShowCheckoutModal,
    auction_info,
    getComments,
    getListOfAuction,
    selectedCard,
    hideAgreementModal,
  }: bidProps) => {
    const { t } = useTranslation();
    const themes = Utils.Themes();
    const [price, setPrice] = useState<number>(0);
    const [highestBid, setHighestBid] = useState<number>(0);
    const [errors, setErrors] = useState<any>({
      biddingPrice: "",
    });
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
      auction_info?.current_bid?.price
        ? setHighestBid(auction_info?.current_bid?.price + 250)
        : setHighestBid(2500);
    }, [auction_info]);

    const handleError = (price: number) => {
      if (price !== 0) {
        setErrors((old: any) => {
          return {
            ...old,
            biddingPrice:
              price < highestBid
                ? t("create_bid.error_text_1") + " " + highestBid
                : price > 30000000
                  ? t("create_bid.error_text_2")
                  : "",
          };
        });
      }
    };

    const handleBid = (e: any) => {
      e.preventDefault();
      const data: bidType = {
        listing_id: auction_info.id,
        price: price,
        card_id: selectedCard,
      };
      setLoading(true);
      setShowBid(false);
      setShowCheckoutModal(false);
      Services.Bid.CreateBid(data)
        .then((res: any) => {
          setLoading(false);
          Swal.fire(
            {
              icon: res.status ? "success" : "error",
              title: res.message,
            }
            // t(
            //   res.status
            //     ? "create_bid.success_title.error_title"
            //     : "create_bid.sweat_alert.error_title"
            // ),
            // res.message
          );
          hideAgreementModal && hideAgreementModal();
          if (res.status) {
            getListOfAuction(auction_info?.id, false, true);
            getComments(auction_info?.id);
            const biddingSection = document.getElementById("place-bid-info");
            biddingSection?.scrollIntoView({
              behavior: "smooth", // "auto" or "smooth"
              block: "center", // "start", "center", "end", or "nearest"
              inline: "nearest",
            });
          }
        })
        .catch((error: any) => {
          setLoading(false);
          error?.response?.data?.message &&
            Swal.fire({
              icon: "error",
              title: error?.response?.data?.message,
            });
          // Swal.fire(
          //   t("create_bid.sweat_alert.error_title"),
          //   error?.response?.data?.message
          // );
          hideAgreementModal && hideAgreementModal();
        });
    };

    return (
      <>
        {loading && <PreLoaderComponent2 />}
        <div className="">
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={showBid}
            hideBackdrop={true}
            onClose={() => {
              // close bid modal
              setShowBid(false);
              // close credit card modal
              setShowCheckoutModal(false);
            }}
            sx={themes.JoyModalStyle_2}
          >
            <Sheet
              className="w-11/12 sm:8/12 md:w-6/12 lg:w-5/12 xl:w-4/12 overflow-y-auto overflow-x-hidden"
              sx={themes.JoyModalSheetStyle_2}
            >
              <ModalClose
                sx={themes.JoyModalCloseStyle_2}
              />
              <div className="flex flex-col justify-center items-center w-100 gap-2 pb-3">
                <img
                  src={AppImages.LogoImage}
                  className="h-8 xs-h-12 sm:h-10 2xl:h-12 xs-h-6"
                  alt="AutoBids Logo"
                />
                <h2 className="head font-bold text-gray-900">
                  {t("create_bid.title")}
                </h2>
              </div>
              <form className="py-3 p1" onSubmit={(e: any) => handleBid(e)}>
                <div className="flex flex-col sm:flex-row gap-y-1 sm:gap-y-0 gap-x-3 ">
                  <div className="InputFieldSetting">
                    <Input
                      placeholder="Bidding Price"
                      type="number"
                      variant="outlined"
                      required={true}
                      style={{ width: "100%" }}
                      id="biddingPrice"
                      onChange={(e: any) => {
                        e.target.value < 30000000 && setPrice(e.target.value);
                      }}
                      onBlur={(e: any) => handleError(e.target.value)}
                      sx={
                        errors.biddingPrice
                          ? themes.JoyInputError_2
                          : themes.JoyGrayShadeInputStyle2_2
                      }
                    />
                  </div>
                  {window.matchMedia("(max-width: 640px)").matches &&
                    (errors.biddingPrice ? (
                      <WarningAlert message={errors.biddingPrice} />
                    ) : (
                      <p className="opacity-40 py-0 dark:opacity-100 darK:text-white">
                        {t("create_bid.min_bid_label")}{" "}
                        {highestBid ? highestBid : 2000}
                        {" CHF"}
                      </p>
                    ))}
                  <ThemeProvider theme={themes.ButtonTheme_2}>
                    <Button variant="contained" type="submit">
                      {t("create_bid.submit_butn_text")}
                    </Button>
                  </ThemeProvider>
                </div>
                {window.matchMedia("(min-width: 640px)").matches &&
                  (errors?.biddingPrice ? (
                    <WarningAlert message={errors?.biddingPrice} />
                  ) : (
                    <p className="opacity-40 py-0 dark:text-white  dark:opacity-100">
                      {t("create_bid.min_bid_label")} {highestBid}
                      {" CHF"}
                    </p>
                  ))}
              </form>
            </Sheet>
          </Modal>
        </div>
      </>
    );
  }
);

export default CreateBid;
