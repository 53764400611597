import axios from "axios";

const token = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : "";

export default axios.create({
  // baseURL: process.env.REACT_APP_HOST_API_KEY,
  baseURL:
    process.env.REACT_APP_ENV_MODE === "dev"
      ? process.env.REACT_APP_HOST_API_KEY
      : "/api/public/",
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
