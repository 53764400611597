import { useContext } from "react";
import { ThemeContext } from "../context/theme.context";
import Utils from "../utils";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { IconButton, ThemeProvider, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ToggleThemeComponentPops {
  applyStyle2?: boolean;
}

const ToggleThemeComponent = ({ applyStyle2 }: ToggleThemeComponentPops) => {
  const { theme, setTheme, isDark } = useContext(ThemeContext);
  const { t } = useTranslation();
  const themes = Utils.Themes();

  // Toggle between light and dark mode
  const toggleTheme = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };

  return (
    <div>
      <Tooltip title={isDark ? t("mo0ovncr") : t("so0qenqd")}>
        <ThemeProvider theme={themes.GrayShadeButtonTheme}>
          <IconButton
            onClick={() => toggleTheme()}
            type="button"
            id="dark-light-toggler"
          >
            {isDark ? (
              <LightModeIcon fontSize="small" className="w-[18px] h-auto" />
            ) : (
              <DarkModeIcon fontSize="small" className="w-[18px] h-auto" />
            )}
          </IconButton>
        </ThemeProvider>
      </Tooltip>
    </div>
  );
};

export default ToggleThemeComponent;
