import { useEffect, useState, lazy, Suspense } from "react";
import { Pagination, Grid } from "@mui/material";
import PageComponents from "../components";
import Services from "../../services";
import { AuctionNavComponent, PreLoaderComponent2 } from "../../components";
import { useTranslation } from "react-i18next";
import Utils from "../../utils";

const AuctionCardComponent = lazy(
  () => import("../components/AuctionCard.component")
);

const SearchCardComponent = lazy(
  () => import("../components/SearchCard.component")
);

function WatchList() {
  const { t } = useTranslation();
  const themes = Utils.Themes();
  const isLogedIn = Services.Auth.IsUserLogedIn();
  const [loading, setLoading] = useState<boolean>(false);
  const [auctions, setAuctions] = useState<any[]>([]);
  const [results, setResults] = useState<any[]>([]);
  const [savedSearches, setSavedSearches] = useState<any[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [resultSort, setResultSort] = useState<string>("");
  const [resultOffset, setResultOffset] = useState<number>(0);
  const [resultLimit, setResultLimit] = useState<number>(10);
  const [resultPage, setResultPage] = useState<number>(1);
  const [resultPages, setResultPages] = useState<number>(1);
  const [auctionSort, setAuctionSort] = useState<string>("");
  const [auctionOffset, setAuctionOffset] = useState<number>(0);
  const [auctionLimit, setAuctionLimit] = useState<number>(10);
  const [auctionPage, setAuctionPage] = useState<number>(1);
  const [auctionPages, setAuctionPages] = useState<number>(1);

  const getWatchLists = (
    fetch: string,
    offset: number,
    limit: number,
    sort: string
  ) => {
    if (isLogedIn) {
      setLoading(true);
      Services.WatchList.GetWatchList(fetch, offset, limit, sort)
        .then((res) => {
          setLoading(false);
          if (res.status) {
            if (fetch == "results") {
              setResults(res.data);
              setResultPages(Math.ceil(res.total / resultLimit));
            } else {
              setAuctions(res.data);
              setAuctionPages(Math.ceil(res.total / auctionLimit));
            }
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getWatchLists("results", resultOffset, resultLimit, resultSort);
  }, [resultSort, resultPage, resultLimit, resultOffset]);

  useEffect(() => {
    getWatchLists("auctions", auctionOffset, auctionLimit, auctionSort);
  }, [auctionSort, auctionPage, auctionOffset, auctionLimit]);

  const getSearch = () => {
    setLoading(true);
    Services.Search.GetSearch()
      .then((res) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSearch();
  }, []);

  const getSavedSearch = () => {
    setLoading(true);
    Services.Search.GetSaveSearchdata(offset, limit)
      .then((res) => {
        setLoading(false);
        if (res.status) {
          setSavedSearches(res.data);
          setTotal(Math.ceil(res.total / limit));
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const deleteSearch = (searchKey: string) => {
    const data = {
      search_text: searchKey,
    };
    setLoading(true);
    Services.Search.DeleteSearch(data)
      .then((res) => {
        setLoading(false);
        if (res.status) {
          getSavedSearch();
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    isLogedIn && getSavedSearch();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="containerSpacing py-16 ">
      <div className="flex flex-row w-full py-0 md:py-3 lg:py-5 px-2 lg:px-0 flex-wrap items-center justify-between">
        <PageComponents.MetaComponent title={t("watch_list_page.title")} />
        {loading && <PreLoaderComponent2 />}
        <div className="flex flex-col w-full">
          <h2 className="lg:flex head font-bold mb-6 md:mb-10 lg:mb-12 ">
            {t("watch_list_page.i0oq7ky7r")}
          </h2>
          <div className="flex justify-between flex-wrap flex-row items-center w-full pb-6">
            <h3 className="p00 font-bold pr-4 ">
              {t("watch_list_page.kndmnwuv6")}
            </h3>
            <AuctionNavComponent
              setSort={setAuctionSort}
              sort={auctionSort}
              listingsType={"auctions"}
              hideFilter={true}
            />
          </div>
          {auctions?.length === 0 && (
            <p className="leading-relaxed pb-2 text-lg font-normal text-gray-500 info_message">
              {t("watch_list_page.iu8456mty")}{" "}
            </p>
          )}
          {
            <Suspense fallback={<PageComponents.CardSkeletonElements />}>
              <>
                <Grid container spacing={3}>
                  {" "}
                  {auctions?.length > 0 &&
                    auctions?.map((auction, index) => (
                      <Grid
                        item
                        className="px-3-"
                        lg={4}
                        md={6}
                        xs={12}
                        key={index}
                      >
                        <AuctionCardComponent
                          item={auction}
                          getListings={() =>
                            getWatchLists(
                              "auctions",
                              auctionOffset,
                              auctionLimit,
                              auctionSort
                            )
                          }
                          showDetailsInProp={false}
                          isWatchListPage={true}
                        />
                      </Grid>
                    ))}
                </Grid>
                {auctions?.length > 0 && auctionPages > 1 && (
                  <div className="flex justify-center items-center pt-8">
                    <Pagination
                      count={auctionPages}
                      page={auctionPage}
                      onChange={(e: any, pageNum) => {
                        setAuctionPage(pageNum);
                        setAuctionOffset((pageNum - 1) * auctionLimit);
                      }}
                      shape="rounded"
                      sx={themes.MuiPagenationStyle_2}
                    />
                  </div>
                )}
              </>
            </Suspense>
          }
          <hr className="h-[1px] mt-14 bg-black-200 border dark:bg-gray-700 hrDarkMode"></hr>
          <h3 className="lg:flex p00 py-12 font-bold">
            {t("watch_list_page.vsmjfjvik")}
          </h3>
          {savedSearches?.length === 0 && (
            <p className="leading-relaxed pb-2 p1 font-normal text-gray-500">
              {t("watch_list_page.9s7tpfz94")}
            </p>
          )}
          <Suspense fallback={<PageComponents.CardSkeletonElements />}>
            <>
              <Grid container>
                {savedSearches &&
                  Object.keys(savedSearches)?.length > 0 &&
                  Object.keys(savedSearches)?.map((search_text: any, index) => (
                    <Grid
                      item
                      className="px-2 py-2"
                      lg={4}
                      md={6}
                      xs={12}
                      key={index}
                    >
                      {savedSearches[search_text] &&
                        Object.keys(savedSearches[search_text])?.length > 0 && (
                          <SearchCardComponent
                            item={savedSearches[search_text]}
                            getSavedSearch={getSavedSearch}
                            searchedText={search_text}
                            deleteSearch={deleteSearch}
                            savedSearches={savedSearches}
                            index={index}
                          />
                        )}
                    </Grid>
                  ))}
              </Grid>
              {Object.keys(savedSearches)?.length > 0 && total > 1 && (
                <div className="flex justify-center items-center pt-4">
                  <Pagination
                    count={total}
                    page={page}
                    onChange={(e: any, pageNum) => {
                      setPage(pageNum);
                      setOffset((pageNum - 1) * limit);
                    }}
                    shape="rounded"
                    sx={themes.MuiPagenationStyle_2}
                  />
                </div>
              )}
            </>
          </Suspense>
          <>
            <hr className="h-[1px] mt-14 bg-black-200 border dark:bg-gray-700 hrDarkMode"></hr>
            <div className="flex justify-between flex-wrap flex-row items-center w-full pb-6 mt-10">
              <h3 className="p00 font-bold pr-4 ">
                {t("watch_list_page.x6vfbtpzr")}
              </h3>
              <AuctionNavComponent
                setSort={setResultSort}
                sort={resultSort}
                listingsType={"results"}
                hideFilter={true}
              />
            </div>
            <Suspense fallback={<PageComponents.CardSkeletonElements />}>
              <>
                <Grid container>
                  {results?.length > 0 &&
                    results?.map((result: any, index: number) => (
                      <Grid
                        item
                        className="px-2 py-2"
                        lg={4}
                        md={6}
                        xs={12}
                        key={index}
                      >
                        <AuctionCardComponent
                          item={result}
                          getListings={() =>
                            getWatchLists(
                              "results",
                              resultOffset,
                              resultLimit,
                              resultSort
                            )
                          }
                          showDetailsInProp={false}
                          isWatchListPage={true}
                        />
                      </Grid>
                    ))}
                </Grid>
                {results?.length > 0 && resultPages > 1 && (
                  <div className="flex justify-center items-center pt-8">
                    <Pagination
                      count={resultPages}
                      page={resultPage}
                      onChange={(e: any, pageNum) => {
                        setResultPage(pageNum);
                        setResultOffset((pageNum - 1) * resultLimit);
                      }}
                      shape="rounded"
                      sx={themes.MuiPagenationStyle_2}
                    />
                  </div>
                )}
                {results?.length === 0 && (
                  <p className="leading-relaxed pb-2 text-lg font-normal text-gray-500 info_message">
                    {t("watch_list_page.iu8305mty")}{" "}
                  </p>
                )}
              </>
            </Suspense>
          </>
        </div>
      </div>
    </div>
  );
}

export default WatchList;
