import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { Images } from "../assets";
import { useTranslation } from "react-i18next";
import { useScreen } from "../hooks";
import {
  FacebookRounded,
  X,
  LinkedIn,
  Instagram,
  Pinterest,
} from "@mui/icons-material";

function FooterComponent() {
  const { t } = useTranslation();

  const SIconStyle = {
    fontSize: "1rem",
  };

  return (
    <footer className="bg-[--light] dark:bg-[--dark-light] md:pt-16 pt-12 pb-12 dark:border-t-2 dark:border-[--gray] shadow">
      <div className="mainContainer">
        {/* Grid Container for Footer Items */}
        <div className="flex justify-between items-start flex-wrap lg:flex-nowrap containerSpacing">
          {/* About Auto Bids */}
          <div className="footer-item about">
            <div className="child-item">
              <div>
                <Link
                  to="/"
                  className="flex items-center"
                  onClick={() => window?.scrollTo(0, 0)}
                  state={{ refresh: true }}
                >
                  <img
                    src={Images.FullYellowLogo}
                    className="w-full h-full max-w-[300px]"
                    alt="AutoBids Logo"
                  />
                </Link>
              </div>
              <p className="text-black dark:text-[--light] w-full p1 py-4 sm:py-6 lg:py-7 max-w-[320px]">
                {t("footer.description")}
              </p>
            </div>
          </div>

          {/* How Works Menus */}
          <div className="footer-item links work-menus">
            <div className="child-item">
              <h3 className="footer-head">{t("footer.about_menus.title")}</h3>
              <ul className="footer-list">
                <li>
                  <Link
                    to="/about"
                    state={{ div_id: "#buyingacar" }}
                    className="footer-list-item"
                  >
                    {t("footer.about_menus.1")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    state={{ div_id: "#sellingacar" }}
                    className="footer-list-item"
                  >
                    {t("footer.about_menus.2")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    state={{ div_id: "#finalizingthesale" }}
                    className="footer-list-item"
                  >
                    {t("footer.about_menus.3")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    state={{ div_id: "#faqs" }}
                    className="footer-list-item"
                  >
                    {t("footer.about_menus.4")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Sellers Menus */}
          <div className="footer-item links seller-menus">
            <div className="child-item">
              <h3 className="footer-head">{t("footer.seller_menus.title")}</h3>
              <ul className="footer-list">
                <li>
                  <Link to="/sell-car" className="footer-list-item">
                    {t("footer.seller_menus.1")}
                  </Link>
                </li>
                <li>
                  <Link
                    className="footer-list-item"
                    to="/sell-car/submit"
                    target="_blank"
                  >
                    {t("footer.seller_menus.2")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Helpful Links Menus */}
          <div className="footer-item links helpfulLinks">
            <div className="child-item">
              <h3 className="footer-head">{t("footer.support_menus.title")}</h3>
              <ul className="footer-list">
                <li>
                  <Link to="/support" className="footer-list-item">
                    {t("footer.support_menus.1")}
                  </Link>
                </li>
                <li>
                  <Link to="/shipping" className="footer-list-item">
                    {t("footer.support_menus.2")}
                  </Link>
                </li>
                <li>
                  <Link to="/inspection" className="footer-list-item">
                    {t("footer.support_menus.3")}
                  </Link>
                </li>
                <li>
                  <Link to="/careers" className="footer-list-item">
                    {t("footer.support_menus.4")}
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          {/* Social Menus */}
          <div className="footer-item social-menus">
            <div className="child-item">
              <div className="social-icon">
                <a href="#" target="_self">
                  <FacebookRounded sx={SIconStyle} />
                </a>
                <a href="#" target="_self">
                  <X sx={SIconStyle} />
                </a>
                <a href="#" target="_self">
                  <LinkedIn sx={SIconStyle} />
                </a>
                <a href="#" target="_self">
                  <Instagram sx={SIconStyle} />
                </a>
                <a href="#" target="_self">
                  <Pinterest sx={SIconStyle} />
                </a>
              </div>
              <div className="w-full tracking-wide font-medium capitalize sm:text-right pt-4">
                <span className="p1 text-black">
                  © Copyright 2025 Auto-Bids.ch
                </span>
                <div className="text-black p1">
                  <a
                    href="/term-of-use"
                    className="hover:underline pr-1"
                    target="_blank"
                  >
                    {t("footer.use_terms")}
                  </a>
                  <a
                    href="/privacy-policy"
                    className="hover:underline"
                    target="_blank"
                  >
                    {t("footer.policy")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterComponent;
