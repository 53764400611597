import { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ThemeProvider,
  Button,
  Breadcrumbs,
  Box,
  IconButton,
  Popover,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonGroup,
  Pagination,
} from "@mui/material";
import Utils from "../../utils";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Services from "../../services";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { PreLoaderComponent } from "../../components";
import PageComponents from "../components";
import { Popover as HeadlessUiPopover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { isAdmin_and_loged_in } from "../../utils/checkUserType.utils";

const Listing = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const isLogedIn = isAdmin_and_loged_in();
  const themes = Utils.Themes();
  const [anchorEl, setAnchorEl] = useState(null);
  const [offset, setOffset] = useState<number>(0);
  const [limit] = useState<number>(5);
  const [sort, setSort] = useState<string>("");
  const [loadingdata, setLoadingdata] = useState<boolean>(true);
  const [lists, setLists] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<number>(0);
  const [applicationStatus, setApplicationStatus] = useState<string>("");
  const [detailsStatus, setDetailsStatus] = useState<string>("");
  const [scheduleStatus, setScheduleStatus] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [search_text, setSearchText] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function handleClick(event: any) {
    event.preventDefault();
  }

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // useEffect(() => {
  //   document.addEventListener("click", (e: any) => {
  //     let clickonActionButton = false;
  //     rows?.map((row, index) => {
  //       const actionButton = document.getElementById(`actionMenu-${index}`);
  //       if (e.target.contains(actionButton)) {
  //         clickonActionButton = true;
  //       }
  //     });
  //   });
  // }, []);

  const handleLisitng = (e?: any) => {
    e?.preventDefault();
    setLists([]);
    setLoadingdata(true);
    const scheduleMeetingStatus = scheduleStatus ? scheduleStatus : null;
    Services.Listing.GetAllListings(
      offset,
      limit,
      sort,
      search_text?.toLocaleLowerCase(),
      applicationStatus,
      detailsStatus,
      scheduleMeetingStatus
    )
      .then((res) => {
        setLoadingdata(false);
        if (res.status) {
          res.data && setLists(res.data);
          res.total && setTotal(Math.ceil(res.total / limit));
        }
      })
      .catch((error) => {
        setLoadingdata(false);
      });
  };

  useEffect(() => {
    isLogedIn && handleLisitng();
  }, [
    isLogedIn,
    offset,
    limit,
    sort,
    applicationStatus,
    detailsStatus,
    scheduleStatus,
    search_text,
  ]);

  const resetFilter = () => {
    setSort("");
    setApplicationStatus("");
    setDetailsStatus("");
    setScheduleStatus(false);
  };

  const resetPagenation = () => {
    setOffset(0);
    setTotal(0);
    setPage(1);
  };

  const handleSearch = (e?: any) => {
    e?.preventDefault();
    resetPagenation();
    resetFilter();
    setSearchText(search);
  };

  return (
    <>
      <PageComponents.MetaComponent title={t("listing_dashboard_page.title")} />
      <div className="w-full">
        <div className="">
          {/* list items */}
          <div className="flex flex-row flex-wrap justify-between items-center gap-2 py-6">
            <div className="flex flex-col justify-center items-start gap-2">
              <h2 className="breadCrumbsMianTitle">
                {t("listing_dashboard_page.wx3ag6ds0")}
              </h2>
              <div role="presentation" onClick={handleClick}>
                <ThemeProvider theme={themes.MuiBreadCrumbsTheme}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/dashboard" className="hover:underline">
                      {t("listing_dashboard_page.trafkfgls")}
                    </Link>
                    <Link to={location.pathname} className="hover:underline">
                      {t("listing_dashboard_page.wx3ag6ds0")}
                    </Link>
                  </Breadcrumbs>
                </ThemeProvider>
              </div>
            </div>
            <div className="grow" />
            <div className="m-auto">
              <HeadlessUiPopover className="relative">
                {({ open }) => (
                  <>
                    <HeadlessUiPopover.Button
                      className={`${open ? "text-black" : "text-black/90"}
               headlessUIButton`}
                    >
                      <svg
                        className="h-5 w-5"
                        aria-hidden="true"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.25 2A2.25 2.25 0 002 4.25v2.5A2.25 2.25 0 004.25 9h2.5A2.25 2.25 0 009 6.75v-2.5A2.25 2.25 0 006.75 2h-2.5zm0 9A2.25 2.25 0 002 13.25v2.5A2.25 2.25 0 004.25 18h2.5A2.25 2.25 0 009 15.75v-2.5A2.25 2.25 0 006.75 11h-2.5zm9-9A2.25 2.25 0 0011 4.25v2.5A2.25 2.25 0 0013.25 9h2.5A2.25 2.25 0 0018 6.75v-2.5A2.25 2.25 0 0015.75 2h-2.5zm0 9A2.25 2.25 0 0011 13.25v2.5A2.25 2.25 0 0013.25 18h2.5A2.25 2.25 0 0018 15.75v-2.5A2.25 2.25 0 0015.75 11h-2.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </HeadlessUiPopover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <HeadlessUiPopover.Panel className="headlessUiPopoverPanel min-w-[300px] sm:min-w-[400px] px-4">
                        <div className="headlessUiPopoverPanelDiv">
                          <div className="px-6 py-6">
                            <h3 className="filterSortMainTitle">
                              {t("listing_dashboard_page.50kl4fsr2")}
                            </h3>
                            <div className="headlessUiPopSortLabel">
                              <span className="headlessUiPopoverTitle2">
                                {t("listing_dashboard_page.rnsilgkhl")}
                              </span>
                              <ThemeProvider
                                theme={
                                  sort === "reserve"
                                    ? themes.ButtonTheme_2
                                    : themes.AppleButtonTheme_2
                                }
                              >
                                <Button
                                  variant="text"
                                  onClick={() =>
                                    setSort(sort === "reserve" ? "" : "reserve")
                                  }
                                  size="small"
                                >
                                  {t("listing_dashboard_page.86ioa1v3d")}
                                </Button>
                              </ThemeProvider>
                              <ThemeProvider
                                theme={
                                  sort === "no_reserve"
                                    ? themes.ButtonTheme_2
                                    : themes.AppleButtonTheme_2
                                }
                              >
                                <Button
                                  variant="text"
                                  onClick={() =>
                                    setSort(
                                      sort === "no_reserve" ? "" : "no_reserve"
                                    )
                                  }
                                  size="small"
                                >
                                  {t("listing_dashboard_page.4u8kntao7")}
                                </Button>
                              </ThemeProvider>
                            </div>
                            <div className="headlessUiPopSortLabel">
                              <span className="headlessUiPopoverTitle2">
                                {t("listing_dashboard_page.ww9intkan")}
                              </span>
                              <ThemeProvider
                                theme={
                                  applicationStatus === "pending"
                                    ? themes.ButtonTheme_2
                                    : themes.AppleButtonTheme_2
                                }
                              >
                                <Button
                                  variant="text"
                                  onClick={() =>
                                    setApplicationStatus(
                                      applicationStatus === "pending"
                                        ? ""
                                        : "pending"
                                    )
                                  }
                                  size="small"
                                >
                                  {t("listing_dashboard_page.3ftnrg6dr")}
                                </Button>
                              </ThemeProvider>
                              <ThemeProvider
                                theme={
                                  applicationStatus === "rejected"
                                    ? themes.ButtonTheme_2
                                    : themes.AppleButtonTheme_2
                                }
                              >
                                <Button
                                  variant="text"
                                  onClick={() =>
                                    setApplicationStatus(
                                      applicationStatus === "rejected"
                                        ? ""
                                        : "rejected"
                                    )
                                  }
                                  size="small"
                                >
                                  {t("listing_dashboard_page.qm161juyx")}
                                </Button>
                              </ThemeProvider>
                              <ThemeProvider
                                theme={
                                  applicationStatus === "approved"
                                    ? themes.ButtonTheme_2
                                    : themes.AppleButtonTheme_2
                                }
                              >
                                <Button
                                  variant="text"
                                  onClick={() =>
                                    setApplicationStatus(
                                      applicationStatus === "approved"
                                        ? ""
                                        : "approved"
                                    )
                                  }
                                  size="small"
                                >
                                  {t("listing_dashboard_page.rspwutonn")}
                                </Button>
                              </ThemeProvider>
                            </div>
                            <div className="headlessUiPopSortLabel">
                              <span className="headlessUiPopoverTitle2">
                                {t("listing_dashboard_page.7g0o8acn5")}
                              </span>
                              <ThemeProvider
                                theme={
                                  detailsStatus === "pending"
                                    ? themes.ButtonTheme_2
                                    : themes.AppleButtonTheme_2
                                }
                              >
                                <Button
                                  variant="text"
                                  onClick={() =>
                                    setDetailsStatus(
                                      detailsStatus === "pending"
                                        ? ""
                                        : "pending"
                                    )
                                  }
                                  size="small"
                                >
                                  {t("listing_dashboard_page.gffeestns")}
                                </Button>
                              </ThemeProvider>
                              <ThemeProvider
                                theme={
                                  detailsStatus === "rejected"
                                    ? themes.ButtonTheme_2
                                    : themes.AppleButtonTheme_2
                                }
                              >
                                <Button
                                  variant="text"
                                  onClick={() =>
                                    setDetailsStatus(
                                      detailsStatus === "rejected"
                                        ? ""
                                        : "rejected"
                                    )
                                  }
                                  size="small"
                                >
                                  {t("listing_dashboard_page.vsdr3opqo")}
                                </Button>
                              </ThemeProvider>
                              <ThemeProvider
                                theme={
                                  detailsStatus === "approved"
                                    ? themes.ButtonTheme_2
                                    : themes.AppleButtonTheme_2
                                }
                              >
                                <Button
                                  variant="text"
                                  onClick={() =>
                                    setDetailsStatus(
                                      detailsStatus === "approved"
                                        ? ""
                                        : "approved"
                                    )
                                  }
                                  size="small"
                                >
                                  {t("listing_dashboard_page.hj90weogv")}
                                </Button>
                              </ThemeProvider>
                            </div>
                            <div className="headlessUiPopSortLabel">
                              <span className="headlessUiPopoverTitle2">
                                {t("listing_dashboard_page.1zxcne5ww")}
                              </span>
                              <ThemeProvider
                                theme={
                                  scheduleStatus
                                    ? themes.ButtonTheme_2
                                    : themes.AppleButtonTheme_2
                                }
                              >
                                <Button
                                  variant="text"
                                  onClick={() =>
                                    setScheduleStatus(!scheduleStatus)
                                  }
                                  size="small"
                                >
                                  {t("listing_dashboard_page.jxgx58u0q")}
                                </Button>
                              </ThemeProvider>
                            </div>
                            <ThemeProvider theme={themes.ButtonTheme_2}>
                              <Button
                                onClick={() => resetFilter()}
                                fullWidth
                                size="small"
                                variant="contained"
                              >
                                {t("listing_dashboard_page.59iy5zpng")}
                              </Button>
                            </ThemeProvider>
                          </div>
                        </div>
                      </HeadlessUiPopover.Panel>
                    </Transition>
                  </>
                )}
              </HeadlessUiPopover>
            </div>
            <form onSubmit={(e) => handleSearch(e)} className="w-full sm:w-fit">
              <PageComponents.SearchComponent
                search={search}
                setSearch={setSearch}
                classes="w-full sm:w-fit"
              />
            </form>
          </div>
          {/* loading data */}
          {loadingdata && <PreLoaderComponent />}
          {/* desktop responsive table */}
          <div className="overflow-x-hidden ">
            {!loadingdata && (
              <div className="hidden md:block overflow-hidden">
                <Box
                  component={Paper}
                  sx={themes.MuiTableBox}
                  className="overflow-x-auto "
                >
                  <ThemeProvider theme={themes.TableTheme_2}>
                    <TableContainer className="muiTableContainer">
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              {t("listing_dashboard_page.riensgads")}
                            </TableCell>
                            <TableCell>
                              {t("listing_dashboard_page.3goonldjd")}
                            </TableCell>
                            <TableCell align="center">
                              {t("listing_dashboard_page.tdtm9bgvg")}
                            </TableCell>
                            <TableCell>
                              {t("listing_dashboard_page.8rw0xqkot")}
                            </TableCell>
                            <TableCell>
                              {t("listing_dashboard_page.z3y8kkevx")}
                            </TableCell>
                            <TableCell>
                              {t("listing_dashboard_page.ascsz9fwg")}
                            </TableCell>
                            <TableCell align="center">
                              {t("listing_dashboard_page.cjh8q2hcg")}
                            </TableCell>
                            <TableCell>
                              {t("listing_dashboard_page.95tt6o3ya")}
                            </TableCell>
                            <TableCell align="center">
                              {t("listing_dashboard_page.0ostoa3cw")}
                            </TableCell>
                            <TableCell>
                              {t("listing_dashboard_page.c1cruycd3")}
                            </TableCell>
                            <TableCell align="center">
                              {t("listing_dashboard_page.g8zqbsfzy")}
                            </TableCell>
                            <TableCell align="center">
                              {t("listing_dashboard_page.8ye79u6z8")}
                            </TableCell>
                            <TableCell align="center">
                              {t("listing_dashboard_page.qo3olid0z")}
                            </TableCell>
                            <TableCell>
                              {t("listing_dashboard_page.agnbih9zp")}
                            </TableCell>
                            <TableCell>
                              {t("listing_dashboard_page.xw4w41vmf")}
                            </TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {lists?.length > 0 &&
                            lists?.map((list, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {list?.title ? list?.title : ""}
                                </TableCell>
                                <TableCell>
                                  {list.owner_is_dealer
                                    ? t("listing_dashboard_page.5abqr4n7j")
                                    : t("listing_dashboard_page.gn709w67w")}
                                </TableCell>
                                <TableCell align="center">
                                  {list.year}
                                </TableCell>
                                <TableCell>{list.make}</TableCell>
                                <TableCell>{list.model}</TableCell>
                                <TableCell>{list.transmission}</TableCell>
                                <TableCell align="center">
                                  {list?.mileage + " KM"}
                                </TableCell>
                                <TableCell>{list.vehicle_stock}</TableCell>
                                <TableCell align="center">
                                  {list.has_flaws
                                    ? t("listing_dashboard_page.95fqeazaj")
                                    : t("listing_dashboard_page.u9prflgwh")}
                                </TableCell>
                                <TableCell align="center">
                                  {list?.country || ""}
                                </TableCell>
                                <TableCell align="center">
                                  {list.listed_elsewhere
                                    ? t("listing_dashboard_page.95fqeazaj")
                                    : t("listing_dashboard_page.u9prflgwh")}
                                </TableCell>
                                <TableCell align="center">
                                  {list?.title_country || ""}
                                </TableCell>
                                <TableCell align="center">
                                  {list.reserve_price ? list.reserve_price : 0}
                                </TableCell>
                                <TableCell align="center">
                                  <ThemeProvider theme={themes.ButtonTheme_2}>
                                    <Button
                                      size="small"
                                      variant="text"
                                      fullWidth
                                      color={
                                        list.application_status === "approved"
                                          ? "primary"
                                          : list.application_status ===
                                              "rejected"
                                            ? "error"
                                            : "inherit"
                                      }
                                    >
                                      {list.application_status}
                                    </Button>
                                  </ThemeProvider>
                                </TableCell>
                                <TableCell align="center">
                                  <ThemeProvider theme={themes.ButtonTheme_2}>
                                    <Button
                                      size="small"
                                      variant="text"
                                      fullWidth
                                      color={
                                        list.details_submission_status ===
                                        "approved"
                                          ? "primary"
                                          : list.details_submission_status ===
                                              "rejected"
                                            ? "error"
                                            : "inherit"
                                      }
                                    >
                                      {list.details_submission_status}
                                    </Button>
                                  </ThemeProvider>
                                </TableCell>
                                <TableCell align="right" className="relative">
                                  <ThemeProvider
                                    theme={themes.AppleButtonTheme_2}
                                  >
                                    <IconButton
                                      onClick={(e) => {
                                        handleOpen(e);
                                        setSelectedId(list.id);
                                      }}
                                      aria-describedby={`actionMenu-${index}`}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 128 512"
                                        className="popover"
                                      >
                                        <path d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z" />
                                      </svg>
                                    </IconButton>
                                  </ThemeProvider>
                                  <Popover
                                    id={`actionMenu-${index}`}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                  >
                                    <div
                                      className={`MuiPopoverMenu`}
                                      id={`actionMenu-${index}`}
                                    >
                                      <ThemeProvider
                                        theme={themes.AppleButtonTheme_2}
                                      >
                                        <Button
                                          fullWidth
                                          onClick={() =>
                                            navigate("/list-detail", {
                                              state: { listing_id: selectedId },
                                            })
                                          }
                                        >
                                          <div className="flex justify-start items-center mr-auto pl-1">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="w-3 h-3 "
                                              fill="currentColor"
                                              viewBox="0 0 576 512"
                                            >
                                              <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                            </svg>
                                            <span className="pl-4 capitalize ">
                                              {t(
                                                "listing_dashboard_page.coxggs14b"
                                              )}
                                            </span>
                                          </div>
                                        </Button>
                                      </ThemeProvider>
                                    </div>
                                  </Popover>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </ThemeProvider>
                </Box>
              </div>
            )}
          </div>
          {/* mobile responsive data table */}
          <div className="py-3">
            <div className="flex flex-wrap h-full md:hidden">
              {!loadingdata &&
                lists?.length > 0 &&
                lists?.map((list, index) => (
                  <div className="p-1 sm:p-2 w-full " key={index} id={list.id}>
                    <ThemeProvider theme={themes.MuiAccordionTheme}>
                      <Accordion className="accordionShadow">
                        <AccordionSummary
                          expandIcon={<ExpandMoreRoundedIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography
                            sx={{
                              width: "100%",
                              flexShrink: 0,
                            }}
                          >
                            <p className="p0 font-medium capitalize">
                              {list.year + "-" + list.make + " " + list.model}
                            </p>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            <Table>
                              <TableRow>
                                <TableHead>
                                  <TableCell>
                                    {t("listing_dashboard_page.wx3ag6ds1")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>
                                  {list?.title ? list.title : ""}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableHead>
                                  <TableCell>
                                    {t("listing_dashboard_page.zf6r9oelv")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>
                                  {list.owner_is_dealer
                                    ? t("listing_dashboard_page.5abqr4n7j")
                                    : t("listing_dashboard_page.gn709w67w")}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableHead>
                                  <TableCell>
                                    {t("listing_dashboard_page.95fqeazaj")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>{list.year}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableHead>
                                  <TableCell>
                                    {t("listing_dashboard_page.qgp8qdc7g")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>{list.make}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableHead>
                                  <TableCell>
                                    {t("listing_dashboard_page.jujhm8tu5")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>{list.model}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableHead>
                                  <TableCell>
                                    {t("listing_dashboard_page.gcorr84su")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>{list.transmission}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableHead>
                                  <TableCell>
                                    {t("listing_dashboard_page.0st9bavrp")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>{list?.mileage + " KM"}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableHead>
                                  <TableCell>
                                    {t("listing_dashboard_page.skex0ayib")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>{list.vehicle_stock}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableHead>
                                  <TableCell>
                                    {t("listing_dashboard_page.xg7qv2k0r")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>
                                  {list.has_flaws
                                    ? t("listing_dashboard_page.95fqeazaj")
                                    : t("listing_dashboard_page.u9prflgwh")}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableHead>
                                  <TableCell>
                                    {t("listing_dashboard_page.6tru2kara")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>{list?.country || ""}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableHead>
                                  <TableCell>
                                    {t("listing_dashboard_page.ev3ahzjvr")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>
                                  {list.listed_elsewhere
                                    ? t("listing_dashboard_page.95fqeazaj")
                                    : t("listing_dashboard_page.u9prflgwh")}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableHead>
                                  <TableCell>
                                    {t("listing_dashboard_page.hqtq0q0u9")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>
                                  {list?.title_country || ""}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableHead>
                                  <TableCell>
                                    {t("listing_dashboard_page.gqjy1nbnx")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>
                                  {list.reserve_price ? list.reserve_price : 0}
                                </TableCell>
                              </TableRow>
                              {/* application status might be pending, approved, declined, auction_started or auction_ended */}
                              <TableRow>
                                <TableHead>
                                  <TableCell>
                                    {t("listing_dashboard_page.me9ro0sab")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>
                                  {" "}
                                  <ThemeProvider theme={themes.ButtonTheme_2}>
                                    <Button
                                      size="small"
                                      variant="text"
                                      color={
                                        list.application_status === "approved"
                                          ? "primary"
                                          : list.application_status ===
                                              "rejected"
                                            ? "error"
                                            : "inherit"
                                      }
                                    >
                                      {list.application_status}
                                    </Button>
                                  </ThemeProvider>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableHead>
                                  <TableCell>
                                    {t("listing_dashboard_page.j50hmjc9g")}
                                  </TableCell>
                                </TableHead>
                                <TableCell>
                                  <ThemeProvider theme={themes.ButtonTheme_2}>
                                    <Button
                                      size="small"
                                      variant="text"
                                      color={
                                        list.details_submission_status ===
                                        "approved"
                                          ? "primary"
                                          : list.details_submission_status ===
                                              "rejected"
                                            ? "error"
                                            : "inherit"
                                      }
                                    >
                                      {list.details_submission_status}
                                    </Button>
                                  </ThemeProvider>
                                </TableCell>
                              </TableRow>
                            </Table>
                            <div className="py-4 w-full flex">
                              <ThemeProvider
                                theme={themes.MenuActiveButtonTheme_2}
                              >
                                <ButtonGroup
                                  variant="contained"
                                  aria-label="action button"
                                  sx={{ margin: "auto" }}
                                >
                                  <Button
                                    fullWidth
                                    onClick={() =>
                                      navigate("/list-detail", {
                                        state: { listing_id: list.id },
                                      })
                                    }
                                  >
                                    <div className="flex justify-start items-center mr-auto px-6 ">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-3 h-3 "
                                        fill="currentColor"
                                        viewBox="0 0 576 512"
                                      >
                                        <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z" />
                                      </svg>
                                      <span className="pl-2 capitalize p1">
                                        {t("listing_dashboard_page.t3hq4as51")}
                                      </span>
                                    </div>
                                  </Button>
                                </ButtonGroup>
                              </ThemeProvider>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </ThemeProvider>
                  </div>
                ))}
            </div>
          </div>
          {/* pagenation */}
          <div className="flex justify-end items-center">
            {!loadingdata && total > 1 && (
              <Pagination
                count={total}
                hidePrevButton
                hideNextButton
                page={page}
                onChange={(e: any, pageNum) => {
                  setPage(pageNum);
                  setOffset((pageNum - 1) * limit);
                }}
                shape="rounded"
                sx={themes.MuiPagenationStyle_2}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Listing;
