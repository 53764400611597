import { Suspense, useEffect, useState } from "react";
import PageComponents from "../components";
import { AuctionNavComponent } from "../../components";
import { useTranslation } from "react-i18next";
import { Grid, Pagination } from "@mui/material";
import Services from "../../services";
import _ from "lodash";
import Utils from "../../utils";

type auctionType = any[];

const Featured = () => {
  const { t } = useTranslation();
  const themes = Utils.Themes();
  const [sort, setSort] = useState<string | null>(null); // "ending_soon"
  const [startYear, setStartYear] = useState<number | null>(null);
  const [endYear, setEndYear] = useState<number | null>(null);
  const [transmission, setTransmission] = useState<string | null>("");
  const [bodyStyle, setBodyStyle] = useState<string | null>("");
  const [search, setSearch] = useState<string | null>("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [auctions, setAuctions] = useState<auctionType>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [make, setMake] = useState<string | null>("");
  const [model, setModel] = useState<string | null>("");

  const addToWatchList = (id: string | number) => {
    // add to watch list
    setLoading(true);
    const auction_data = {
      listing_id: id,
    };
    Services.WatchList.AddToWatchList(auction_data)
      .then((res) => {
        setLoading(false);
        res.status &&
          auctions?.length > 0 &&
          setAuctions(
            auctions?.map((item: any) => {
              item.id == id && (item.watching = true);
              return item;
            })
          );
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const removeFromWatchList = (id: string | number) => {
    // remove from watch
    setLoading(true);
    Services.WatchList.DeleteFromWatchList(id)
      .then((res) => {
        setLoading(false);
        res.status &&
          auctions?.length > 0 &&
          setAuctions(
            auctions?.map((item: any) => {
              item.id == id && (item.watching = false);
              return item;
            })
          );
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getAuctions = () => {
    setLoading(true);
    Services.Listing.GetListings(
      offset,
      limit,
      "auctions",
      sort,
      startYear,
      endYear,
      transmission,
      search,
      bodyStyle,
      null,
      true,
      make,
      model
    )
      .then((res: any) => {
        setLoading(false);
        if (res.status) {
          setTotal(Math.ceil(res.total / limit));
          setAuctions(res.data);
        }
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAuctions();
  }, [
    currentPage,
    sort,
    startYear,
    endYear,
    transmission,
    bodyStyle,
    search,
    make,
    model,
  ]);

  return (
    <div>
      <PageComponents.MetaComponent
        title={"Auto Bids - " + t("featured_title")}
        canonicalUrl={window.location.host}
      />

      {/* featured content */}
      <section className="text-gray-600 body-font containerSpacing mt-8">
        <AuctionNavComponent
          setSort={setSort}
          sort={sort}
          setStartYear={setStartYear}
          setEndYear={setEndYear}
          setTransmission={setTransmission}
          setBodyStyle={setBodyStyle}
          startYear={startYear}
          endYear={endYear}
          listingsType={"auctions"}
          setMake={setMake}
          setModel={setModel}
        />
        <div className="w-full ">
          <div className="h-full w-full">
            <div className="w-full lg:col-span-3 col-span-full">
              <Suspense fallback={<PageComponents.CardSkeletonElements />}>
                <div className=" text-gray-600 body-font">
                  <div className="flex flex-col py-8 lg:py-12 mx-auto-">
                   
                    <div className="flex flex-wrap w-full">
                      <Grid container spacing={3}>
                        {loading ? (
                          _.times(3, (index) => (
                            <Grid item className="px-3-" xs={12} key={index}>
                              <PageComponents.FeaturedSkeletonComponent />
                            </Grid>
                          ))
                        ) : auctions?.length > 0 ? (
                          auctions?.map((item, index) => (
                            <Grid item className="px-3-" xs={12} key={index}>
                              <PageComponents.FeaturedCardComponent
                                data={item}
                                addToWatchList={addToWatchList}
                                removeFromWatchList={removeFromWatchList}
                              />
                            </Grid>
                          ))
                        ) : (
                          <p className="info_message">
                            {t("user_application_page.zudr5t9da")}
                          </p>
                        )}
                      </Grid>
                    </div>
                  </div>
                  {!loading && (
                    <div className="flex justify-center items-center py-4">
                      {!loading && auctions?.length > 0 && total > 1 && (
                        <Pagination
                          count={total}
                          hidePrevButton
                          hideNextButton
                          page={currentPage}
                          onChange={(e: any, pageNum) => {
                            setCurrentPage(pageNum);
                            setOffset((pageNum - 1) * limit);
                          }}
                          shape="rounded"
                          sx={themes.MuiPagenationStyle_2}
                        />
                      )}
                    </div>
                  )}
                </div>
              </Suspense>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Featured;
