import { Suspense, lazy, useContext, useEffect, useState } from "react";
import Utils from "../../utils";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import PageComponents from "../components";
import Services from "../../services";
import { useTranslation } from "react-i18next";
import { Box, Button, ThemeProvider, Grid } from "@mui/material";
import { ThemeContext } from "../../context/theme.context";
import { AuctionNavComponent } from "../../components";

const AuctionCardComponent = lazy(
  () => import("../components/AuctionCard.component")
);

function Search() {
  let { slug } = useParams();
  let navigate = useNavigate();
  const params = useParams();
  const themes = Utils.Themes();
  const { isDark } = useContext(ThemeContext);
  const isUserLogedIn = Services.Auth.IsUserLogedIn();
  const { t } = useTranslation();
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(10);
  const [auctionSort, setAuctionSort] = useState<string | null>("ending_soon");
  const [resultSort, setResultSort] = useState<string | null>("recently_ended");
  const [startYear, setStartYear] = useState<number | null>(null);
  const [endYear, setEndYear] = useState<number | null>(null);
  const [transmission, setTransmission] = useState<string | null>("");
  const [search, setSearch] = useState<string | null>("");
  const [bodyStyle, setBodyStyle] = useState<string | null>("");
  const [make, setMake] = useState<string | null>("");
  const [model, setModel] = useState<string | null>("");
  const [listings, setListings] = useState<any[]>([]);
  const [auctions, setAuctions] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [savedSearch, setSavedSearch] = useState<boolean>(false);
  const [resultStartYear, setResultStartYear] = useState<number | null>(null);
  const [resultEndYear, setResultEndYear] = useState<number | null>(null);
  const [resultTransmission, setResultTransmission] = useState<string | null>(
    ""
  );
  const [resultBodyStyle, setResultBodyStyle] = useState<string | null>("");
  const [resultMake, setResultMake] = useState<string | null>("");
  const [resultModel, setResultModel] = useState<string | null>("");
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [loadAuctions, setLoadAuctions] = useState<boolean>(false);
  const [loadResults, setLoadResults] = useState<boolean>(false);
  // search dialogue open
  const [openSearchDialogue, setOpenSearchDialogue] = useState<boolean>(false);
  const [saveSearchInfo, setSaveSearchInfo] = useState<boolean>(false);
  const [auctionNotifyMe, setAuctionNotifyMe] = useState<boolean>(true);
  // pre loader
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    navigate(`/search/${search}`);
    getSearchResults(true);
    return getSearchResults(false);
  };

  const saveSearchAndNotifyMe = (e?: any) => {
    setOpenSearchDialogue(false);
    setIsLoading(true);
    e?.preventDefault();
    const data = {
      search_text: params?.slug?.trim().toLowerCase(),
      setting: {
        name: "when_new_auctions_go_live",
        value: auctionNotifyMe,
      },
    };
    Services.Search.SaveSearch(data)
      .then((res: any) => {
        setSavedSearch(true);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsLoading(false);
      });
  };

  const saveSearch = () => {
    setOpenSearchDialogue(true);
    setSaveSearchInfo(true);
  };

  const getSavedSearches = () => {
    Services.Search.GetSearch().then((res) => {
      let isSearchSave = false;
      res?.status &&
        res?.data?.length > 0 &&
        res?.data?.map((item: string) => {
          item === params?.slug?.trim().toLowerCase() && (isSearchSave = true);
        });
      setSavedSearch(isSearchSave);
    });
  };

  const DeleteSavedSearch = () => {
    const data = {
      search_text: params?.slug?.trim(),
    };
    Services.Search.DeleteSearch(data).then((res) => {
      res.status && setSavedSearch(false);
    });
  };

  useEffect(() => {
    isUserLogedIn && getSavedSearches();
  }, [params.slug]);

  const getSearchResults = (currentAuction: boolean) => {
    currentAuction ? setLoadAuctions(true) : setLoadResults(true);
    Services.Listing.GetListings(
      offset,
      limit,
      currentAuction ? "auctions" : "results",
      currentAuction ? auctionSort : resultSort,
      currentAuction ? startYear : resultStartYear,
      currentAuction ? endYear : resultEndYear,
      currentAuction ? transmission : resultTransmission,
      slug,
      currentAuction ? bodyStyle : resultBodyStyle,
      null,
      null,
      currentAuction ? make : resultMake,
      currentAuction ? model : resultModel
    )
      .then((res) => {
        currentAuction ? setLoadAuctions(false) : setLoadResults(false);
        if (res.status) {
          currentAuction ? setAuctions(res.data) : setListings(res.data);
        }
      })
      .catch((error) => {
        currentAuction ? setLoadAuctions(false) : setLoadResults(false);
      });
  };

  useEffect(() => {
    getSearchResults(true);
  }, [
    params,
    auctionSort,
    startYear,
    endYear,
    transmission,
    bodyStyle,
    make,
    model,
  ]);

  useEffect(() => {
    getSearchResults(false);
  }, [
    params,
    resultSort,
    resultStartYear,
    resultEndYear,
    resultTransmission,
    resultBodyStyle,
    resultMake,
    resultModel,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageComponents.MetaComponent title={t("search_page.title")} />
      <div className="text-gray-600 body-font containerSpacing py-16">
        <div className="">
          <div className="flex flex-col">
            <div className="flex flex-wrap flex-row w-full lg:items-center gap-4 lg:mr-auto  justify-self-center sm:justify-between lg:mt-5 pb-8">
              <h2 className="flex head font-bold break-words text-black dark:text-white">
                {t("search_page.search_label")}: {slug}
              </h2>
              <div className=" items-start">
                <Box className=" w-full items-center text-base ">
                  <ThemeProvider
                    theme={
                      savedSearch
                        ? isDark
                          ? themes.GoogleButtonTheme_2
                          : themes.AppleButtonTheme_2
                        : themes.ButtonTheme_2
                    }
                  >
                    <Button
                      variant={savedSearch ? "contained" : "contained"}
                      onClick={() =>
                        isUserLogedIn
                          ? savedSearch
                            ? DeleteSavedSearch()
                            : saveSearch()
                          : document.getElementById("signin")?.click()
                      }
                    >
                      {savedSearch
                        ? t("search_page.saved_search")
                        : t("search_page.notify_save_search")}
                    </Button>
                  </ThemeProvider>
                </Box>
              </div>
            </div>
            {/* auctions */}
            <h3 className="lg:flex p00 font-bold py-3 text-black dark:text-white">
              {t("search_page.auctions_heading")} ({auctions?.length})
            </h3>
            {/* auction sort */}
            <div className=" pb-6 w-full">
              <AuctionNavComponent
                setSort={setAuctionSort}
                sort={auctionSort}
                setStartYear={setStartYear}
                setEndYear={setEndYear}
                setTransmission={setTransmission}
                setBodyStyle={setBodyStyle}
                startYear={startYear}
                endYear={endYear}
                listingsType={"auctions"}
                setMake={setMake}
                setModel={setModel}
              />
            </div>
            {/* auction content */}
            <Suspense fallback={<PageComponents.CardSkeletonElements />}>
              <Grid container spacing={1}>
                {loadAuctions ? (
                  <PageComponents.CardSkeletonElements />
                ) : auctions?.length > 0 ? (
                  auctions?.map((auction, index) => (
                    <Grid
                      item
                      className="px-2-"
                      lg={4}
                      md={6}
                      xs={12}
                      key={index}
                    >
                      <AuctionCardComponent
                        item={auction}
                        showDetailsInProp={false}
                      />
                    </Grid>
                  ))
                ) : (
                  <div className="info_message">
                    {t("search_page.no_auction_found")}
                  </div>
                )}
              </Grid>
            </Suspense>
          </div>
          {/* past listings */}
          <h3 className="lg:flex p00 font-bold py-2 pt-12 text-black dark:text-white">
            {t("search_page.result_heading")} ({listings?.length})
          </h3>
          {/* past listing sort */}
          <div className=" pb-6 w-full">
            <AuctionNavComponent
              setSort={setResultSort}
              sort={resultSort}
              setStartYear={setResultStartYear}
              setEndYear={setResultEndYear}
              setTransmission={setResultTransmission}
              setBodyStyle={setResultBodyStyle}
              startYear={resultStartYear}
              endYear={resultEndYear}
              listingsType={"results"}
              setMake={setResultMake}
              setModel={setResultModel}
            />
          </div>
          {/* past auction content */}
          <Suspense fallback={<PageComponents.CardSkeletonElements />}>
            <Grid container spacing={1}>
              {loadResults ? (
                <PageComponents.CardSkeletonElements />
              ) : listings?.length > 0 ? (
                listings?.map((lsit, index) => (
                  <Grid item className="px-2" lg={4} md={6} xs={12} key={index}>
                    <AuctionCardComponent
                      item={lsit}
                      showDetailsInProp={false}
                    />
                  </Grid>
                ))
              ) : (
                <div className="info_message">
                  {t("search_page.no_list_text")}
                </div>
              )}
            </Grid>
          </Suspense>
          <div className="flex justify-center mx-auto mt-10">
            {!loadResults && total > 1 && (
              <Pagination
                count={total}
                hidePrevButton
                hideNextButton
                page={page}
                onChange={(e: any, pageNum) => {
                  setPage(pageNum);
                  setOffset((pageNum - 1) * limit);
                }}
                shape="rounded"
                sx={themes.MuiPagenationStyle_2}
              />
            )}
          </div>
        </div>
      </div>
      {openSearchDialogue && (
        <PageComponents.SavedSearch
          open={openSearchDialogue}
          auctionNotifyMe={auctionNotifyMe}
          setAuctionNotifyMe={setAuctionNotifyMe}
          handleSubmit={saveSearchAndNotifyMe}
          isLoading={isLoading}
        />
      )}
    </>
  );
}

export default Search;
