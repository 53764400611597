import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  NavComponent,
  FooterComponent,
  ProfileSidebar,
  FloatingScroller,
  HeaderComponent,
} from "../components";
import CheckUserType from "../utils/checkUserType.utils";
import { useTranslation } from "react-i18next";

const User = () => {
  const navigate = useNavigate();
  const isUserAndLogedIn = CheckUserType.isUser_and_loged_in();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    !isUserAndLogedIn && navigate(-1);
  }, [isUserAndLogedIn]);

  return (
    <>
      <div className="sticky top-0 z-50">
        <NavComponent />
      </div>
      <HeaderComponent />
      <div className="bg-white dark:bg-[--dark]">
        <div className="mainContainer min-h-screen">
          {/* <NavComponent /> */}
          <div className="my-8 md:my-12 lg:my-16 containerSpacing">
            <div className="my-12 lg:my-16 xl:my-20 flex justify-start items-start gap-0">
              <ProfileSidebar />
              <Outlet />
            </div>
          </div>
          {/* <FooterComponent /> */}
          <FloatingScroller />
        </div>
      </div>
      <div className="bg-[--dark]">
        <FooterComponent />
      </div>
    </>
  );
};

export default User;
