import { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Box, Breadcrumbs, ThemeProvider, Button } from "@mui/material";
import { Input } from "@mui/joy";
import Utils from "../../utils";
import { WarningAlert } from "../../components";
import Services from "../../services";
import Swal from "sweetalert2";
import { Images } from "../../assets";
import { useFormik } from "formik";
import * as Yup from "yup";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";
import { isAdmin_and_loged_in } from "../../utils/checkUserType.utils";

type formType = {
  avatar?: any;
  name: string;
};

const ReviewerForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const themes = Utils.Themes();
  const isLogedIn = isAdmin_and_loged_in();
  const forms: string[] = ["Create", "Update"];
  const [formType, setFormType] = useState<string>(forms[0]);
  const [errors, setErrors] = useState<any>({
    profilePic: "",
    name: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [profilePic, setProfilePic] = useState<any>({});
  const [profilePreview, setProfilePreview] = useState<any>("");
  const [name, setName] = useState<string>("");
  const [profileImageName, setProfileImageName] = useState<string>("");

  useEffect(() => {
    if (location.pathname === "/edit-reviewer") {
      if (
        location.state &&
        location.state.reviewer &&
        location.state.formType
      ) {
        if (location.state) {
          setFormType(location.state.formType);
          location.state?.reviewer?.avatar &&
            setProfilePreview(
              process.env.REACT_APP_HOST_API_KEY +
                location.state?.reviewer?.avatar
            );
          setName(location.state?.reviewer?.name);
          formik.setFieldValue("name", location.state?.reviewer?.name);
          location.state?.reviewer?.avatar?.split("/")[3] &&
            setProfileImageName(
              location.state?.reviewer?.avatar?.split("/")[3]
            );
        }
      } else {
        navigate(-1);
      }
    }
  }, [location]);

  const formik = useFormik({
    initialValues: {
      name: "",
      profilePic: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required().min(3).max(30),
      profilePic: Yup.string(),
    }),
    onSubmit: async (values) => {
      await new Promise((resolve) => setTimeout(resolve, 500));
      handleSubmit();
    },
  });

  function handleClick(event: any) {}

  const handleForm = () => {
    const data: formType = {
      name: name,
    };
    if (profilePic) {
      data.avatar = profilePic;
    }
    let urlService: any =
      formType === forms[0]
        ? Services.Reviewer.CreateReviewer(data)
        : Services.Reviewer.UpdateReviewer(location.state.reviewer.id, data);
    setLoading(true);
    urlService
      .then((res: any) => {
        setLoading(false);
        if (res.status) {
          navigate(-1);
        }
      })
      .catch((error: any) => {
        setLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          Swal.fire({
            icon: "error",
            title: error.response.data.message.split(".")[0],
          });
        }
      });
  };

  const handleSubmit = (e?: any) => {
    e?.preventDefault();
    let noOfErrors = 0;
    let firstKey = "";
    Object.values(errors)?.map((objValue) => {
      if (objValue !== "" && objValue !== null) {
        ++noOfErrors;
      }
    });
    for (let i = 0; i <= Object.keys(errors).length - 1; i++) {
      if (
        Object.values(errors)[i] !== "" &&
        Object.values(errors)[i] !== null
      ) {
        firstKey = Object.keys(errors)[i];
        break;
      }
    }
    if (noOfErrors > 0) {
      let getFirstErrorElement: HTMLElement | null =
        document.getElementById(firstKey);
      if (getFirstErrorElement) {
        return getFirstErrorElement.focus();
      }
    } else {
      handleForm();
    }
  };

  const handleImageChange = (e: any) => {
    formik.setFieldTouched("profilePic", true, false);
    let file = e.target.files[0];
    if (file) {
      if (
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg"
      ) {
        // get file size in MBs
        const fileSize = file.size / (1024 * 1024);
        if (fileSize <= 2) {
          // profile image must be less than 2MB
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            let fileInfo = {
              name: file.name,
              type: file.type,
              size: Math.round(file.size / 1000) + " kB",
              base64: reader.result,
              file: file,
            };
            setProfileImageName(fileInfo.name);
            setProfilePic(file);
            setProfilePreview(fileInfo.base64);
            formik.setFieldError("profilePic", "");
            formik.setFieldValue("profilePic", fileInfo.base64);
          };
        } else {
          formik.setFieldError(
            "profilePic",
            t("create_reviewer_page.epqiv3voa")
          );
          setProfilePic({});
          setProfilePreview("");
        }
      } else {
        formik.setFieldError("profilePic", t("create_reviewer_page.2e6yeq9to"));
        setProfilePic({});
        setProfilePreview("");
      }
    } else {
      // give error
      formik.setFieldError("profilePic", t("create_reviewer_page.4bcxknbae"));
      setProfilePic({});
      setProfilePreview("");
    }
    e.target.files = null;
  };

  return (
    <>
      <PageComponents.MetaComponent
        title={t("create_reviewer_page.hi9p89egu")}
      />
      <div className=" ">
        <div className="flex flex-wrap justify-between items-center py-6">
          <div className="flex flex-col justify-center items-start gap-2">
            <h2 className="breadCrumbsMianTitle">
              {(formType === forms[0]
                ? t("create_reviewer_page.9vycc957n")
                : t("create_reviewer_page.39dhektgh")) + " "}
              {t("create_reviewer_page.3l1g2t540")}
            </h2>
            <div role="presentation" onClick={handleClick}>
              <ThemeProvider theme={themes.MuiBreadCrumbsTheme}>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link to="/dashboard" className="hover:underline">
                    {t("create_reviewer_page.th775smaq")}
                  </Link>
                  <Link to={"/reviewers"} className="hover:underline">
                    {t("reviewers_page.pdcpqvyv7")}
                  </Link>
                  <Link
                    to={location.pathname}
                    className="hover:underline"
                    state={location.state}
                  >
                    {(formType === forms[0]
                      ? t("create_reviewer_page.9vycc957n")
                      : t("create_reviewer_page.39dhektgh")) + " "}
                    {t("create_reviewer_page.3l1g2t540")}
                  </Link>
                </Breadcrumbs>
              </ThemeProvider>
            </div>
          </div>
          <ThemeProvider theme={themes.ButtonTheme_2}>
            <Button variant="contained" onClick={() => navigate(-1)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-4 h-4 mr-2"
                viewBox="0 0 448 512"
                fill="CurrentColor"
              >
                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
              </svg>
              {t("create_reviewer_page.hpfwiajvb")}
            </Button>
          </ThemeProvider>
        </div>
        <div className="m-auto w-full sm:w-4/5 md:w-2/3">
          <div className="clayEffect">
            <Box
              noValidate
              autoComplete=""
              component="form"
              onSubmit={formik.handleSubmit}
            >
              <div className="flex flex-col  gap-4 px-6 py-8 ">
                <div className="flex-col w-full lg:w-full space-y-1">
                  <img
                    src={profilePreview ? profilePreview : Images.ProfileIcon2}
                    alt={name}
                    loading="lazy"
                    className="mx-auto w-28 sm:w-36 lg:w-42 xl:w-48 h-28 sm:h-36 lg:h-42 xl:h-48 rounded-full border shadow2 hover:shadow-xl darkShadow dark:border-[--gray]"
                  />
                </div>
                <div className="flex-col w-full lg:w-full space-y-1">
                  <div
                    className="flex items-center justify-center w-full shadow2"
                    id="profilePic"
                  >
                    <label
                      htmlFor="dropzone-file"
                      className="dropzonFileUploader"
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6 text-center px-1">
                        <svg
                          className="dropzonFileUploaderIcon"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 16"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                          />
                        </svg>
                        {profileImageName ? (
                          <p className="mb-2 p1 text-gray-500 dark:text-[--light]">
                            <span
                              className="font-semibold text-wrap"
                              style={{ overflowWrap: "anywhere" }}
                            >
                              {profileImageName}
                            </span>
                          </p>
                        ) : (
                          <>
                            <p className="mb-2 p1 text-gray-500 dark:text-[--light]">
                              <span className="font-semibold">
                                {t("create_reviewer_page.mfqixkh4o")}
                              </span>{" "}
                              {t("create_reviewer_page.hndwvy22j")}
                            </p>
                            <p className="p2 text-gray-500 dark:text-[--light]">
                              PNG, JPG ({t("create_reviewer_page.mekyi2lv1")}{" "}
                              2MB)
                            </p>
                          </>
                        )}
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        onChange={(e: any) => handleImageChange(e)}
                        accept="image/png, image/jpg, image/jpeg"
                      />
                    </label>
                  </div>
                  <WarningAlert message={formik.errors.profilePic} />
                </div>
                <div className="flex-col w-full lg:w-full space-y-1">
                  <label className="p1 px-1" htmlFor="name">
                    {t("create_reviewer_page.is659b62b")}{" "}
                    <sup className="requireField">*</sup>
                  </label>
                  <Input
                    name="name"
                    variant="outlined"
                    color={errors?.name ? "danger" : "neutral"}
                    required={true}
                    value={name}
                    id="name"
                    onChange={(e: any) => {
                      setName(e.target.value);
                      formik.handleChange(e);
                    }}
                    slotProps={{
                      input: {
                        maxLength: 150,
                      },
                    }}
                    onBlur={formik.handleBlur}
                    sx={
                      formik?.errors?.name
                        ? themes.JoyInputError_2
                        : themes.JoyGrayShadeInputStyle2_2
                    }
                    className="2xl:py-2"
                  />
                  <WarningAlert message={formik?.errors?.name} />
                </div>
                <div className="flex justify-end sm:justify-end items-center">
                  <ThemeProvider theme={themes.ButtonTheme_2}>
                    <Button
                      variant="contained"
                      type="submit"
                      fullWidth
                      disabled={formik?.isSubmitting || loading}
                    >
                      {t("create_reviewer_page.rakg0lsvp")}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="CurrentColor"
                        viewBox="0 0 448 512"
                        className="w-4 h-4 ml-2"
                      >
                        <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                      </svg>
                    </Button>
                  </ThemeProvider>
                </div>
              </div>
            </Box>
          </div>
          {/* </Box> */}
        </div>
      </div>
    </>
  );
};

export default ReviewerForm;
