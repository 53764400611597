import { ApiConfig } from "../config";
import Storage from "./Storage.service";

const SignUp = (data: any) => {
  return ApiConfig.post("/api/auth/register", data).then((res: any) => {
    return res?.data;
  });
};

const SignIn = (data: any) => {
  return ApiConfig.post("/api/auth/login", data).then((res: any) => {
    return res?.data;
  });
};

const AdminSignIn = (data: any) => {
  return ApiConfig.post("/api/auth/admin/login", data).then((res: any) => {
    return res?.data;
  });
};

const VerifyEmail = (email: string, accessToken: string) => {
  return ApiConfig.get(`/api/auth/verify-email/${email}/${accessToken}`).then(
    (res: any) => {
      return res?.data;
    }
  );
};

const SubscribeToDailyEmail = (data: any) => {
  return ApiConfig.post("/api/auth/daily-email", data).then((res: any) => {
    return res?.data;
  });
};

const UnSubscribeToDailyEmail = (data: any) => {
  return ApiConfig.post("/api/auth/daily-email/unsubscribe", data).then((res: any) => {
    return res?.data;
  });
};

const IsUserLogedIn = () => {
  const token = Storage.getToken();
  if (token) {
    return true;
  }
  return false;
};

const ResetPasswordLink = (data: any) => {
  return ApiConfig.post(`/api/auth/reset/password/link`, data).then(
    (res: any) => {
      return res?.data;
    }
  );
};

const VerifyPasswordToken = (data: any) => {
  return ApiConfig.post(`/api/auth/reset/verify/token`, data).then(
    (res: any) => {
      return res?.data;
    }
  );
};

const ResetPassword = (data: any) => {
  return ApiConfig.post(`/api/auth/reset/password`, data).then((res: any) => {
    return res?.data;
  });
};

const Logout = () => {
  const device_token = Storage.getDeviceToken();
  const data:any = {
    device_token: device_token,
  };
  return ApiConfig.get("/api/auth/logout", data)
    .then((res: any) => {
      Storage.clearSession();
      return res?.data;
    })
    .catch((error) => {
      Storage.clearSession();
      return error;
    });
};

const GoogleSignIn = () => {
  return ApiConfig.get("/api/auth").then((res: any) => {
    return res?.data;
  });
};

const GoogleAuthCallBack = (token: any) => {
  return ApiConfig.get(`/api/auth/callback${token}`).then((res: any) => {
    return res?.data;
  });
};

const FacebookSignIn = (data: any) => {
  return ApiConfig.post("/api/auth/social-login/facebook", data).then((res: any) => {
    return res?.data;
  });
};

const Auth = {
  FacebookSignIn,
  GoogleAuthCallBack,
  GoogleSignIn,
  AdminSignIn,
  ResetPassword,
  ResetPasswordLink,
  VerifyPasswordToken,
  IsUserLogedIn,
  SignUp,
  SignIn,
  VerifyEmail,
  SubscribeToDailyEmail,
  Logout,
  UnSubscribeToDailyEmail
};

export default Auth;
