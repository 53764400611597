import {
  createContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

interface translatedObject {
  latest?: any;
}

interface LanguageObject {
  name: string;
  nativeName?: string;
  isReferenceLanguage?: boolean;
  region?: string;
  translated?: translatedObject;
  abbrevation?: string;
}

interface Languages {
  [key: string]: LanguageObject;
}

interface LanguageContext {
  lngs?: Languages;
  lng?: string;
  setLng?: Dispatch<SetStateAction<string>>;
  i18n?: any;
}

export const LanguageContext = createContext<LanguageContext>({});

const LanguageContextProvider = ({ children }: any) => {
  const { i18n, t } = useTranslation();
  const [lngs, setLngs] = useState<Languages>({
    en: {
      name: "English",
      nativeName: "English",
      isReferenceLanguage: true,
      translated: {
        latest: 1,
      },
      abbrevation: "en",
    },
    "it-CH": {
      name: "Italian",
      nativeName: "Italiano",
      isReferenceLanguage: false,
      region: "CH",
      translated: {
        latest: 0,
      },
      abbrevation: "it",
    },
    "de-CH": {
      name: "German",
      nativeName: "Deutsch",
      isReferenceLanguage: false,
      region: "CH",
      translated: {
        latest: 0,
      },
      abbrevation: "de",
    },
    "fr-CH": {
      name: "French",
      nativeName: "Français, langue française",
      isReferenceLanguage: false,
      region: "CH",
      translated: {
        latest: 0,
      },
      abbrevation: "fr",
    },
  });
  const [lng, setLng] = useState<string>("en");

  useEffect(() => {
    i18n.services.backendConnector.backend.getLanguages(
      (err: any, res: any) => {
        if (err) return; // TODO: handle err...
        res && setLngs(res);
        i18n?.language && setLng(i18n?.language);
      }
    );
  }, [i18n]);

  return (
    <LanguageContext.Provider value={{ lngs, lng, setLng, i18n }}>
      {children}
    </LanguageContext.Provider>
  );
};

LanguageContextProvider.propTypes = {
  children: PropTypes.any,
};

export default LanguageContextProvider;
