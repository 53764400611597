import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import {
  NavComponent,
  FooterComponent,
  CookieBannerComponent,
  FloatingScroller,
  HeaderComponent,
} from "../components";

let mainFontFamily = ["Signika Negative", "sans-serif"];

function Main() {

  return (
    <>
      <div className="sticky top-0 z-50">
        <NavComponent />
      </div>
      <HeaderComponent />
      <div className="bg-white dark:bg-[--dark]">
        <div className="mainContainer min-h-screen ">
          <div className="my-6">
            <Outlet />
          </div>
          <CookieBannerComponent />
          <FloatingScroller />
        </div>
      </div>
      <div className="bg-[--dark]">
        <FooterComponent />
      </div>
    </>
  );
}

export default Main;

export { mainFontFamily };
