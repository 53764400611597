import { useState, useEffect } from "react";
import Services from "../../services";
import { PreLoaderComponent } from "../../components";
import PageComponents from "../components";
import { Images } from "../../assets";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isUser_loged_in } from "../../utils/checkUserType.utils";

const WhatIs = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const isLogedIn = isUser_loged_in();
  const navigate = useNavigate();
  const [faqs, setFaqs] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const data = {
    reviewer: {
      avatar: Images.DougTakeProfile,
      name: t("about_page.right_side.heading5"),
    },
    review: t("about_page.right_side.p6"),
  };

  interface Question {
    id: number;
    type: string;
    question: string;
    answer: string;
  }

  useEffect(() => {
    getFAQs();
    const navbarHeight = document.getElementById("navbar")?.offsetHeight;
    document.documentElement.style.setProperty(
      "--scroll-padding",
      navbarHeight! + 15 + "px"
    );
  }, []);

  const getFAQs = () => {
    setIsLoading(true);
    Services.Faqs.getFaqs()
      .then((res) => {
        setIsLoading(false);
        if (res.status) {
          setFaqs({
            "Buyer Questions": res.data["Buyer Questions"],
            "Seller Questions": res.data["Seller Questions"],
            "Shipping Questions": res.data["Shipping Questions"],
            "Sign in Questions": res.data["Sign in Questions"],
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (location?.state?.div_id) {
      window.location.hash = location?.state?.div_id;
    }
  }, [location.state]);

  useEffect(() => {
    if (!location?.state) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className="containerSpacing py-16">
      <PageComponents.MetaComponent title={t("about_page.title")} />
      <div className="flex flex-wrap mx-[2px] lg:mx-0 flex-row w-full">
        {/* sidebar content */}
        <div className="hidden lg:block w-48 left-0 top-0 py-4 mt-16 z-10">
          {/* sidebar */}
          <div className="flex flex-col gap-y-4 w-48  sticky top-10">
            <a
              href={"#aboutus"}
              target="_self"
              className="flex title-font font-medium items-center md:mb-0 "
            >
              <span className="p1 pb-6 clicableLink">
                {t("about_page.left_side.menu1")}
              </span>
            </a>
            <a
              href={"#how-works"}
              target="_self"
              className="flex title-font font-medium items-center md:mb-0 "
            >
              <span className="p1 clicableLink">
                {t("about_page.left_side.menu2")}
              </span>
            </a>
            <a href={"#buyingacar"} target="_self" className="  ">
              <span className="p1 clicableLink">
                {t("about_page.left_side.menu3")}
              </span>
            </a>
            <a href={"#sellingacar"} target="_self" className="mr-5  ">
              <span className="p1 clicableLink">
                {t("about_page.left_side.menu4")}
              </span>
            </a>
            <a href={"#finalizingthesale"} target="_self" className="mr-5 ">
              <span className="p1 clicableLink">
                {t("about_page.left_side.menu5")}
              </span>
            </a>
            <a href={"#faqs"} target="_self" className="mr-5 font-medium pt-6">
              <span className="p1 clicableLink ">
                {t("about_page.left_side.menu6")}
              </span>
            </a>
            <a href={"#buyer questions"} target="_self" className="mr-5  ">
              <span className="p1 clicableLink">
                {t("about_page.left_side.menu7")}
              </span>
            </a>
            <a href={"#seller questions"} target="_self" className="mr-5 ">
              <span className="p1 clicableLink">
                {t("about_page.left_side.menu8")}
              </span>
            </a>
            <a href={"#shipping questions"} target="_self" className="mr-5 ">
              <span className="p1 clicableLink">
                {t("about_page.left_side.menu9")}
              </span>
            </a>
            <a href={"#sign in questions"} target="_self" className="mr-5 ">
              <p className="p1 clicableLink">
                {t("about_page.left_side.menu10")}
              </p>
            </a>
          </div>
        </div>
        {/* main content */}
        <div className="flex flex-col pt-16 w-full lg:w-2/3 mx-auto-">
          <h2 className="flex head lg:text-4xl mb-16  font-bold hover:text-gray-700 dark:hover:text-[--light] overflow-hidden">
            {t("about_page.right_side.heading")}
          </h2>

          {/* whats auto bids? */}
          <div className="whatsCardBidItem text-justify-break-words">
            <div className="item">
              <div className="itemContent">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="whatsCarBidIconStyle"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5"
                  />
                </svg>
                <div className="content">
                  <h3 className="heading ">
                    {t("about_page.right_side.heading1")}
                  </h3>
                  <p className="description">{t("about_page.right_side.p1")}</p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="itemContent">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="whatsCarBidIconStyle"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3"
                  />
                </svg>
                <div className="content">
                  <h3 className="heading">
                    {t("about_page.right_side.heading2")}
                  </h3>
                  <p className="description">
                    {t("about_page.right_side.p2")}
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="itemContent">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="whatsCarBidIconStyle"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                  />
                </svg>
                <div className="content">
                  <h3 className="heading">
                    {t("about_page.right_side.heading3")}
                  </h3>
                  <p className="description">
                    {t("about_page.right_side.p3")}
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="itemContent">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="whatsCarBidIconStyle"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
                <div className="content">
                  <h3 className="heading">
                    {t("about_page.right_side.heading4")}
                  </h3>
                  <p className="description">
                    {t("about_page.right_side.p4")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* reviewers */}
          <div className="mt-14">
            <PageComponents.AuthorQuote data={data} />
          </div>
          <hr className="h-1px mt-14 bg-black-200 border dark:bg-gray-700 hrDarkMode"></hr>
          {/* about content */}
          <div
            id="aboutus"
            className=" py-16 space-y-8  text-justify-break-words"
          >
            <h3 className="head font-bold">
              {t("about_page.right_side.heading6")}
            </h3>
            <p className="p1">{t("about_page.right_side.p7")}</p>
            <p className="p1">{t("about_page.right_side.p8")}</p>
            <p className="p1">
              {t("about_page.right_side.p9")}{" "}
              <span className="font-bold">
                {t("about_page.right_side.p10")}
              </span>
            </p>
            <ul className="list-disc pl-5 space-y-8">
              <li className="p1">
                <span className="font-bold">
                  {t("about_page.right_side.list.heading1")}
                </span>{" "}
                {t("about_page.right_side.list.p1")}
              </li>
              <li className="p1">
                <span className="font-bold">
                  {t("about_page.right_side.list.heading2")}
                </span>{" "}
                {t("about_page.right_side.list.p2")}
              </li>
              <li className="p1">
                <span className="font-bold">
                  {t("about_page.right_side.list.heading3")}
                </span>{" "}
                {t("about_page.right_side.list.p3")}
              </li>
              <li className="p1">
                <span className="font-bold">
                  {t("about_page.right_side.list.heading4")}
                </span>{" "}
                {t("about_page.right_side.list.p4")}
              </li>
              <li className="p1">
                {t("about_page.right_side.list.p5")}{" "}
                <span className="font-bold">
                  {" "}
                  {t("about_page.right_side.list.p6")}
                </span>{" "}
                {t("about_page.right_side.list.p7")}
              </li>
              <li className="p1">
                <span className="font-bold">
                  {t("about_page.right_side.list.heading8")}
                </span>{" "}
                {t("about_page.right_side.list.p8")}
              </li>
              <li className="p1">
                <span className="font-bold">
                  {t("about_page.right_side.list.p9")}
                </span>{" "}
                {t("about_page.right_side.list.p10")}
              </li>
              <li className="p1">
                {t("about_page.right_side.list.p11")}{" "}
                <span className=" text-lg font-bold">
                  {" "}
                  {t("about_page.right_side.list.p12")}{" "}
                </span>{" "}
                {t("about_page.right_side.list.p13")}
              </li>
            </ul>
            <hr className="h-1px  bg-black-200 border dark:bg-gray-700 hrDarkMode"></hr>
          </div>
          {/* how works and faqs */}
          <div className=" space-y-8  text-justify-break-words" id="how-works">
            <h3 className="head font-bold">
              {t("about_page.right_side.heading7")}
            </h3>
            <h4 id="buyingacar" className="p00 font-bold">
              {t("about_page.right_side.p11")}
            </h4>
            <p className="p1">{t("about_page.right_side.p12")}</p>
            <ol className="list-decimal ml-5 space-y-8">
              <li className="p1 space-y-5">
                <span className=" font-bold ">
                  {t("about_page.right_side.list1.p1")}
                </span>{" "}
                <p className="p1 -m--5">
                  {t("about_page.right_side.list1.p2")}{" "}
                  <span className="hover:underline font-bold  cursor-pointer">
                    {t("about_page.right_side.list1.p1")}
                  </span>{" "}
                  {t("about_page.right_side.list1.p3")}
                </p>
              </li>
              <li className="p1 space-y-5 ">
                <span className="font-bold ">
                  {t("about_page.right_side.list1.p4")}
                </span>
                <p className="p1 -m--5">
                  {t("about_page.right_side.list1.p5")}
                  <p className="hover:underline font-bold">
                    {" "}
                    {t("about_page.right_side.list1.p6")}
                  </p>{" "}
                  .
                </p>
                <ul className="list-disc space-y-8">
                  <li className="p1">{t("about_page.right_side.list2.p1")}</li>
                  <li className="p1">{t("about_page.right_side.list2.p2")}</li>
                  <li className="p1">{t("about_page.right_side.list2.p3")}</li>
                </ul>
              </li>
              <li className="p1 space-y-5">
                <span className="font-bold">
                  {t("about_page.right_side.list2.p4")}
                </span>{" "}
                <p className="p1 -m--5">
                  {t("about_page.right_side.list2.p5")}
                </p>
                <ul className="list-disc space-y-8">
                  <li className="p1">{t("about_page.right_side.list3.p1")}</li>
                  <li className="p1">{t("about_page.right_side.list3.p2")}</li>
                  <li className="p1">{t("about_page.right_side.list3.p3")}</li>
                </ul>
              </li>
              <li className="p1 space-y-5">
                <span className="font-bold">
                  {t("about_page.right_side.list4.span1")}
                </span>{" "}
                <p className="-m--5">{t("about_page.right_side.list4.p4")}</p>
                <video
                  className="w-full -m--5 items-center"
                  width="fullwidth"
                  height="340"
                  controls
                >
                  <source
                    src="http://techslides.com/demos/sample-videos/small.ogv"
                    type="video/ogg"
                  />
                </video>
                <ul className="list-disc space-y-8">
                  <li className="p1">{t("about_page.right_side.list4.p1")}</li>
                  <li className="p1">{t("about_page.right_side.list4.p2")}</li>
                  <li className="p1">{t("about_page.right_side.list4.p4")}</li>
                </ul>
              </li>
              <li className="p1 space-y-5">
                <span className="font-bold">
                  {t("about_page.right_side.list5.span1")}
                </span>{" "}
                <p className="-m--5">{t("about_page.right_side.list5.p1")}</p>
                <p className="-m--5">
                  {t("about_page.right_side.list5.p2")}{" "}
                  <span className="font-bold">
                    {t("about_page.right_side.list5.span2")}
                  </span>{" "}
                  <span className="hover:underline text-[var(--main-color)] font-bold">
                    {t("about_page.right_side.list5.span3")}
                  </span>
                  .
                </p>
              </li>
            </ol>
            <h4 id="sellingacar" className="p00 font-bold">
              {t("about_page.right_side.p13")}
            </h4>
            <p className="p1">{t("about_page.right_side.p14")}</p>
            <ol className="list-decimal ml-5 space-y-8">
              <li className="p1 space-y-5">
                <span
                  className="hover:underline font-bold cursor-pointer"
                  onClick={(e) => {
                    isLogedIn
                      ? navigate("/sell-car/submit")
                      : document?.getElementById("signin")?.click();
                  }}
                >
                  {t("about_page.right_side.list6.a1")}
                </span>{" "}
                <p className="-m--5">
                  {t("about_page.right_side.list6.p1")}{" "}
                  <span
                    className="hover:underline font-bold text-[var(--main-color)] cursor-pointer"
                    onClick={(e) => {
                      isLogedIn
                        ? navigate("/sell-car/submit")
                        : document?.getElementById("signin")?.click();
                    }}
                  >
                    {" "}
                    {t("about_page.right_side.list6.a2")}
                  </span>
                  . {t("about_page.right_side.list6.p2")}
                </p>
                <p className="-m--5">{t("about_page.right_side.list6.p3")}</p>
                <p className="-m--5">{t("about_page.right_side.list6.p4")}</p>
              </li>
              <li className="p1 space-y-5 ">
                <span className="font-bold ">
                  {t("about_page.right_side.list6.span1")}
                </span>
                <p className="-m--5">{t("about_page.right_side.list6.p5")}</p>
                <ul className="list-disc space-y-8">
                  <li className="p1">{t("about_page.right_side.list7.p1")}</li>
                  <li className="p1">
                    {t("about_page.right_side.list7.p2")}{" "}
                    <p className="hover:underline font-bold">
                      {" "}
                      {t("about_page.right_side.list7.a1")}{" "}
                    </p>{" "}
                    {t("about_page.right_side.list7.p3")}{" "}
                    <p className="hover:underline font-bold">
                      {t("about_page.right_side.list7.a2")}{" "}
                    </p>{" "}
                    {t("about_page.right_side.list7.p4")}
                  </li>
                  <li className="p1">
                    {t("about_page.right_side.list7.p5")}{" "}
                    <p className="hover:underline font-bold">
                      {" "}
                      {t("about_page.right_side.list7.a3")}{" "}
                    </p>{" "}
                    {t("about_page.right_side.list7.p6")}
                  </li>
                  <li className="p1">{t("about_page.right_side.list7.p7")}</li>
                  <li className="p1">{t("about_page.right_side.list7.p8")}</li>
                </ul>
              </li>
              <li className="p1 space-y-5">
                <span className="font-bold">
                  {t("about_page.right_side.list7.span1")}
                </span>{" "}
                <p className="-m--5">{t("about_page.right_side.list7.p9")}</p>
              </li>
              <li className="p1 space-y-5">
                <span className="font-bold">
                  {t("about_page.right_side.list7.span2")}
                </span>{" "}
                <p className="-m--5">{t("about_page.right_side.list7.p10")}</p>
                <ul className="list-disc space-y-8">
                  <li className="p1">{t("about_page.right_side.list8.p1")}</li>
                  <li className="p1">{t("about_page.right_side.list8.p2")}</li>
                  <li className="p1">{t("about_page.right_side.list8.p3")}</li>
                </ul>
              </li>
              <li className="p1 space-y-5">
                <span className="font-bold">
                  {t("about_page.right_side.list8.span1")}
                </span>{" "}
                <p className="-m--5">
                  {t("about_page.right_side.list8.p4")}{" "}
                  <a
                    href="#finalizingthesale"
                    target="_self"
                    className="font-bold text-[var(--main-color)] cursor-pointer"
                  >
                    {t("about_page.right_side.list8.p5")}
                  </a>
                  .
                </p>
                <p className="-m--5">{t("about_page.right_side.list8.p6")}</p>
              </li>
            </ol>
            <h3 id="finalizingthesale" className="p00 font-bold">
              {t("about_page.right_side.p15")}
            </h3>
            <p className="p1">{t("about_page.right_side.p16")}</p>
            <ol className="list-decimal pl-5 space-y-8">
              <li className="p1 space-y-5">
                <span className="p1 font-bold ">
                  {t("about_page.right_side.list9.span1")}
                </span>{" "}
                <ul className="list-disc space-y-8">
                  <li className="p1">{t("about_page.right_side.list9.p1")}</li>
                  <li className="p1">{t("about_page.right_side.list9.p2")}</li>
                </ul>
              </li>
              <li className="p1 space-y-5 ">
                <span className="font-bold ">
                  {t("about_page.right_side.list9.span2")}
                </span>
                <ul className="list-disc space-y-8">
                  <div className="flex items-center ">
                    <div className="w-2 h-2 rounded-full mr-4 items-center border-[1px] border-black"></div>
                    {t("about_page.right_side.list9.p3")}
                  </div>
                  <div className="flex items-center ">
                    <div className="w-3 h-2 rounded-full mr-4 border-[1px] border-black"></div>
                    {t("about_page.right_side.list9.p4")}
                  </div>
                  <li className="p1">{t("about_page.right_side.list9.p5")}</li>
                  <li className="p1">{t("about_page.right_side.list9.p6")}</li>
                  <li className="p1">
                    {t("about_page.right_side.list9.p7")}{" "}
                    <a
                      href="mailto:shipping@auto-bids.ch"
                      target="_self"
                      className="hover:underline font-bold text-[var(--main-color)]"
                    >
                      {" "}
                      shipping@auto-bids.ch
                    </a>{" "}
                    {t("about_page.right_side.list9.p8")}{" "}
                    <a
                      href="tel:(978) 955-2355"
                      target="_self"
                      className="hover:underline font-bold text-[var(--main-color)]"
                    >
                      {" "}
                      (978) 955-2355
                    </a>
                    . {t("about_page.right_side.list9.p9")}
                  </li>
                </ul>
              </li>
              <li className="p1 space-y-5">
                <span className="font-bold ">
                  {t("about_page.right_side.list9.span3")}
                </span>{" "}
                <p className="-m--5">
                  <a
                    href="mailto:shipping@auto-bids.ch"
                    target="_self"
                    className="hover:underline font-bold text-[var(--main-color)]"
                  >
                    {t("about_page.right_side.list9.a1")}
                  </a>{" "}
                  {t("about_page.right_side.list9.p10")}
                </p>
              </li>
            </ol>
          </div>
          <hr className="h-1px bg-black-200 border dark:bg-gray-700 hrDarkMode"></hr>
          {/* FAQ Section */}
          <div className="bg-light-blue w-full" id="faqs">
            <div className="flex justify-center items-start my-2">
              <div className="w-full my-1">
                <h3 className="head font-semibold text-vnet-blue mb-8 mt-4">
                  {t("about_page.right_side.faqs_section.heading")}
                </h3>
                {isLoading ? (
                  <PreLoaderComponent />
                ) : (
                  <>
                    <div>
                      {Object.keys(faqs)?.length > 0 &&
                        Object.keys(faqs)?.map(
                          (questionType: string, i: number) => {
                            return (
                              <div key={i}>
                                <h4
                                  id={questionType.toLowerCase()}
                                  className="p00 font-semibold text-vnet-blue mb-2 mt-6"
                                >
                                  {questionType}
                                </h4>
                                {faqs[questionType]?.length > 0 &&
                                  faqs[questionType]?.map(
                                    (question: Question) => (
                                      <PageComponents.FAQ question={question} />
                                    )
                                  )}
                              </div>
                            );
                          }
                        )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* last paragraph */}
          <p className="p2 text-gray-400 pt-8">
            {t("about_page.right_side.faqs_section.p1")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhatIs;
