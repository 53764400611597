import { useState, memo } from "react";
import { Button, Typography, ThemeProvider } from "@mui/material";
import Utils from "../../utils";
import { PasswordInputField, WarningAlert } from "../../components";
import Services from "../../services";
import { Input } from "@mui/joy";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";
import { Images } from "../../assets";

const Signin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const themes = Utils.Themes();
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const Copyright = memo(function Copyright(props: any) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        <span className="p2 text-wrap">
          {/* {t("admin_sign_in_page.vtxe09c26")}
          {new Date().getFullYear()}{" "} */}
          © Copyright 2025{" "}
          <Link color="inherit" to="/" target="_blank">
            Auto-Bids.ch
          </Link>
        </span>
      </Typography>
    );
  });

  const handleSubmit = async () => {
    const data = {
      email: email.trim(),
      password: password.trim(),
    };
    setIsLoading(true);
    await Services.Auth.AdminSignIn(data)
      .then(async (res: any) => {
        if (res.status) {
          await Services.Profile.GetProfileUsingAT(res.data.access_token)
            .then(async (response) => {
              setIsLoading(false);
              if (response.status) {
                await Services.Storage.setSession(
                  res.data.access_token,
                  response.data
                );
                await Services.Storage.setUserRole(res.data.role);
                navigate("/dashboard", { replace: true });
                return window.location.reload();
              }
            })
            .catch((error) => {
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
          Swal.fire(
            t("user_application_page.error"),
            t("user_application_page.unauthorized")
          );
          return navigate("/", { replace: true });
        }
      })
      .catch((error: any) => {
        setIsLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          Swal.fire({
            icon: "error",
            title: error.response.data.message.split(".")[0],
          });
        }
      });
  };

  return (
    <>
      <PageComponents.MetaComponent title={t("admin_sign_in_page.title")} />
      <main className="px-4 mx-auto">
        <div className="pt-4">
          <div>
            <Link to="/" className={`title-font font-medium py-6`}>
              <img
                src={Images.FullYellowLogo}
                className="w-full max-w-48 mx-auto"
                alt="AutoBids"
              />
            </Link>
          </div>
          <div className="">
            <Formik
              initialValues={{ email: "", password: "" }}
              onSubmit={async (values) => {
                await new Promise((resolve) => setTimeout(resolve, 500));
                handleSubmit();
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email()
                  .trim()
                  .required(t("admin_sign_in_page.7iojxy7b4")),
                password: Yup.string()
                  .trim()
                  .required(t("admin_sign_in_page.pz8p9k5sd")),
              })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                } = props;
                return (
                  <form
                    onSubmit={handleSubmit}
                    noValidate
                    autoComplete="w-full border-2 border-red-900"
                  >
                    <div className="py-4 w-full">
                      <label className="p1 px-1" htmlFor="title">
                        {t("admin_sign_in_page.2bkioglxj")}
                        <sup className="requireField">*</sup>
                      </label>
                      <Input
                        id="email"
                        name="email"
                        variant="outlined"
                        required={true}
                        fullWidth
                        autoComplete="off"
                        autoFocus
                        value={values.email.trim()}
                        onChange={(e) => {
                          handleChange(e);
                          setEmail(e.target.value.trim());
                        }}
                        sx={
                          errors?.email && touched?.email
                            ? themes.JoyInputError_2
                            : themes.JoyGrayShadeInputStyle2_2
                        }
                        onBlur={handleBlur}
                        slotProps={{
                          input: {
                            maxLength: 255,
                          },
                        }}
                      />
                      <WarningAlert
                        message={
                          touched.email && errors.email || ""
                        }
                      />
                    </div>
                    <div className="pb-4 w-full">
                      <PasswordInputField
                        // fieldName is label of the field here
                        fieldName={t("admin_sign_in_page.xbsemat62")}
                        setPassword={setPassword}
                        password={password}
                        required={true}
                        name="password"
                        id="password"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        formikProps={props}
                        maxLength={12}
                      />
                      <WarningAlert
                        message={touched?.password && errors?.password || ""}
                      />
                    </div>
                    <div className="pb-4 w-full">
                      <ThemeProvider theme={themes.ButtonTheme_2}>
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          onClick={(e: any) => handleSubmit(e)}
                          disabled={isSubmitting || isLoading ? true : false}
                        >
                          {t("admin_sign_in_page.sign_in")}
                        </Button>
                      </ThemeProvider>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
        <Copyright />
      </main>
    </>
  );
};

export default Signin;
