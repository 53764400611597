import React from "react";
import { Avatar } from "@mui/material";
import ProfileBidHistoryCard from "../components/ProfileBidHistoryCard.component";
import PageComponents from "../components";

const PublicProfileView = () => {
  return (
    <>
      <div className="py-20 px-2">
        <div className="container mx-auto px-5 py-3 sm:px-1 ">
          <div className="flex justify-center items-center gap-2 sm:gap-4">
            <div className="w-16 sm:w-24 md:w-28 lg:w-36 h-16 sm:h-24 md:h-28 lg:h-36">
              <Avatar sx={{ width: "100%", height: "100%" }} />
            </div>
            <div>
              <h3 className="font-bold text-lg sm:text-xl lg:text-2xl uppercase">
                UserName
              </h3>
              <div className="flex justify-start items-center font-semobold">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="h-3 sm:h-4"
                >
                  <path d="M214.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 109.3V480c0 17.7 14.3 32 32 32s32-14.3 32-32V109.3l73.4 73.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-128-128z" />
                </svg>
                <span className="text-base sm:text-lg ">2</span>
                <p className="textGray ps-2 sm:ps-4 text-base capitalize ">
                  Reputation Growth
                </p>
              </div>
              <p className="textGray text-base capitalize">Joined June 2020</p>
            </div>
          </div>
          <div className="flex justify-center items-center py-2">
            <p className="text-justify sm:text-center s-full sm:w-4/5 lg:w-3/5 text-sm sm:text-base">
              I'm located in Parker, CO. I enjoy all types of vehicles but
              mostly german muscle! I currently own a 2011 BMW M3 Competition
              but have owned several other BMWs and Mercedes.
            </p>
          </div>
          <div className="py-4">
            <hr />
          </div>
          <div className="flex justify-start items-end gap-2 sm:gap-4">
            <h3 className="font-bold sm-text-lg text-xl sm:text-2xl lg:text-4xl">Bid History</h3>
            <p className="sm-text-small text-base sm:text-lg">(Bid on 4 cars, 0 wins)</p>
          </div>
          <div className="flex justify-start items-center flex-wrap py-4">
            <ProfileBidHistoryCard />
            <ProfileBidHistoryCard />
            <ProfileBidHistoryCard />
            <ProfileBidHistoryCard />
            <ProfileBidHistoryCard />
            <ProfileBidHistoryCard />
          </div>
          <div className="flex justify-start items-end gap-2 sm:gap-4">
            <h3 className="font-bold sm-text-lg text-xl sm:text-2xl lg:text-4xl">Comments</h3>
            <p className="sm-text-small text-base sm:text-lg">(1 Comment)</p>
          </div>
          <div className="flex flex-col justify-center items-start py-4">
            <PageComponents.ProfileCommentHistory />
            <PageComponents.ProfileCommentHistory />
            <PageComponents.ProfileCommentHistory />
            <PageComponents.ProfileCommentHistory />
            <PageComponents.ProfileCommentHistory />
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicProfileView;
