import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";

const ProfileSidebar = () => {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <>
      <div className="hidden lg:flex w-2/12">
        <ul className=" flex flex-col  justify-content-center text-gray-700 dark:text-white leading-10 py-4 no-underline ">
          <li
            className={`${
              location.pathname === "/profile" ? "font-semibold" : ""
            }  hover:font-semibold list-none`}
          >
            <NavLink to="/profile">{t('profile_sidebar_component.menus.1')}</NavLink>
          </li>
          <li
            className={`${
              location.pathname === "/listings" ? "font-semibold" : ""
            }   hover:font-semibold list-none`}
          >
            <NavLink to="/listings">{t('profile_sidebar_component.menus.2')}</NavLink>
          </li>
          <li
            className={`${
              location.pathname === "/settings" ? "font-semibold" : ""
            } hover:font-semibold list-none`}
          >
            <NavLink to="/settings">{t('profile_sidebar_component.menus.3')}</NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ProfileSidebar;
