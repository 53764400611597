import React, { memo } from "react";
import { useTranslation } from "react-i18next";

type propsType = {
  videoUrls?: string[];
};

const CarVideos = memo(({ videoUrls }: propsType) => {
  const { t } = useTranslation();

  return (
    <div className="py-6 px-4">
      <h3 className="font-bold head tracking-wide pb-3">
        {t("auction_details_page.video_section.title")}
      </h3>
      <div className="w-full h-full flex flex-wrap justify-start md:justify-start items-center gap-2 lg:gap-0">
        {videoUrls &&
          videoUrls?.length > 0 &&
          videoUrls?.map((item: any, index: number) => (
            <div
              className={`w-full ${
                videoUrls.length == 1 ? "lg:w-2/3" : "lg:w-1/2"
              }  lg:p-2`}
              key={index}
            >
              <iframe
                src={item?.url ? item?.url : item}
                className={`w-full h-full  ${
                  videoUrls.length == 1 ? "sm:h-64 md:h-80" : "sm:h-48 md:h-64"
                }`}
              />
            </div>
          ))}
      </div>
    </div>
  );
});

export default CarVideos;
