import { useTranslation } from "react-i18next";

const Careers = () => {
  const {t} = useTranslation();

  return (
    <>
      <div className="py-3 px-2">
        <section className="containerSpacing">
          <div className="py-12 min-w-screen min-h-[40vh] flex justify-center items-center">
            <h2 className="head leading-6 md:leading-7 lg:leading-9 text-[var(--main-color)] drop-shadow-md text-center">
              {t("comming_soon_title")}
            </h2>
          </div>
        </section>
      </div>
    </>
  );
};

export default Careers;
