import { useState, useEffect } from "react";
import {
  Table,
  TableCell,
  TableRow,
  ThemeProvider,
  Button,
  Link as MuiLink,
  Breadcrumbs,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ButtonGroup,
  Pagination,
  TableHead,
} from "@mui/material";
import Utils from "../../utils";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Services from "../../services";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { PreLoaderComponent } from "../../components";
import Swal from "sweetalert2";
import moment from "moment";
import PageComponents from "../components";
import { useTranslation } from "react-i18next";
import { isAdmin_and_loged_in } from "../../utils/checkUserType.utils";

const TimeSchedule = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const isLogedIn = isAdmin_and_loged_in();
  const themes = Utils.Themes();
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(6);
  const [loadingdata, setLoadingdata] = useState<boolean>(false);
  const [rows, setRows] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [schedules, setSchedules] = useState<any[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.addEventListener("click", (e: any) => {
      let clickonActionButton = false;
      rows?.map((row, index) => {
        const actionButton = document.getElementById(`actionMenu-${index}`);
        if (e.target.contains(actionButton)) {
          clickonActionButton = true;
        }
      });
    });
  }, []);

  const deleteSlot = (item: any) => {
    let countReserved = 0;
    if (item?.time_slots?.length > 0) {
      item["time_slots"]?.map((slot: any) => {
        if (slot["status"] === "reserved") {
          ++countReserved;
        }
      });
    }
    if (countReserved === 0) {
      Swal.fire({
        title: t("admins_page.db8l5i6xo"),
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: t("admins_page.tgiikqtzt"),
        denyButtonText: t("admins_page.j0icdgpbo"),
        icon: "warning",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoadingdata(true);
          Services.Schedule.DeleteSchedule(item.id)
            .then((res) => {
              setLoadingdata(false);
              if (res.status) {
                Swal.fire(
                  t("time_schedule_dashboard_page.k8exmvnkh"),
                  t("time_schedule_dashboard_page.pa556b3wa")
                );
                getTimeSchedule();
              }
            })
            .catch((error) => {
              setLoadingdata(false);
            });
        }
      });
    } else {
      Swal.fire(
        t("time_schedule_dashboard_page.7osaldyo4"),
        t("time_schedule_dashboard_page.ulzl2w4wz")
      );
    }
  };

  const getTimeSchedule = () => {
    setLoadingdata(true);
    Services.Schedule.GetSchedules(offset, limit)
      .then((res) => {
        setLoadingdata(false);
        if (res.status) {
          setSchedules(res.data);
          setTotal(Math.ceil(res.total / limit));
        }
      })
      .catch((error) => {
        setLoadingdata(false);
      });
  };

  useEffect(() => {
    isLogedIn && getTimeSchedule();
  }, [offset, limit, page]);

  const handleTime = (time: string) => {
    let timeArray = time.split(":");
    let format = "";
    timeArray.pop();
    if (time > "12:00:00") {
      let tweleveHourFormat = Number(timeArray[0]) - 12;
      let hours = "00";
      tweleveHourFormat > 10
        ? (hours = "0" + tweleveHourFormat.toString())
        : (hours = tweleveHourFormat.toString());
      timeArray[0] = hours;
      format = "PM";
    } else {
      format = "AM";
    }

    return timeArray.join(":") + " " + format;
  };

  const isScheduleEditable = (item: any) => {
    const date = new Date();
    if (
      moment(item?.date).year() === date.getFullYear() &&
      moment(item?.date).month() === date.getMonth() &&
      moment(item?.date).date() === date.getDate()
    ) {
      return true;
    }
    return Utils.CalculateDays(item?.date) > 0;
  };

  return (
    <>
      <PageComponents.MetaComponent
        title={t("time_schedule_dashboard_page.oqoveczgc")}
      />
      <div className=" ">
        <div className="  verflow-x-auto">
          {/* list items */}
          <div className="flex flex-wrap justify-between items-center py-6">
            <div className="flex flex-col justify-center items-start gap-2">
              <h2 className="breadCrumbsMianTitle">
                {t("time_schedule_dashboard_page.l8wt9oeh1")}
              </h2>
              <div role="presentation">
                <ThemeProvider theme={themes.MuiBreadCrumbsTheme}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/dashboard" className="hover:underline">
                      {t("time_schedule_dashboard_page.tggf2xc9f")}
                    </Link>
                    <Link to={location.pathname} className="hover:underline">
                      {t("time_schedule_dashboard_page.47tas5py8")}
                    </Link>
                  </Breadcrumbs>
                </ThemeProvider>
              </div>
            </div>
            <ThemeProvider theme={themes.ButtonTheme_2}>
              <Button
                variant="contained"
                onClick={() =>
                  navigate("/schedule/add", {
                    state: { formType: "Create" },
                  })
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="CurrentColor"
                  viewBox="0 0 448 512"
                  className="w-4 h-4 mr-2"
                >
                  <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                </svg>
                {t("time_schedule_dashboard_page.bl5joud68")}
              </Button>
            </ThemeProvider>
          </div>
          {/* loading data */}
          {loadingdata && <PreLoaderComponent />}
          {/* schedules details */}
          <div className="py-3">
            <div className="flex flex-wrap h-full ">
              {!loadingdata &&
                schedules?.length > 0 &&
                schedules?.map((item, index) => (
                  <div
                    className=" p-1 sm:p-2 w-full md:w-1/2"
                    key={index}
                    id={item.id}
                  >
                    <ThemeProvider theme={themes.MuiAccordionTheme}>
                      <Accordion className="accordionShadow">
                        <AccordionSummary
                          expandIcon={<ExpandMoreRoundedIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography
                            sx={{ width: "100%", flexShrink: 0 }}
                            className="uppercase"
                          >
                            <h3 className="p0 font-medium capitalize">
                              {" "}
                              {moment(item.date).format("MMMM DD YYYY")}
                            </h3>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            <Table>
                              <TableHead>
                                <TableRow
                                  sx={{
                                    borderBottom: "1px solid #e5e7eb",
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      border: "none",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {t(
                                      "time_schedule_dashboard_page.dycqjfg20"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      border: "none",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {t(
                                      "time_schedule_dashboard_page.7xnorf0hi"
                                    )}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      border: "none",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {t(
                                      "time_schedule_dashboard_page.kberhc79l"
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              {item?.time_slots?.length > 0 &&
                                item?.time_slots?.map(
                                  (item: any, index: number) => (
                                    <TableRow
                                      sx={{
                                        borderBottom: "1px solid #e5e7eb",
                                      }}
                                      key={index}
                                    >
                                      <TableCell
                                        sx={{
                                          border: "none",
                                        }}
                                      >
                                        {handleTime(item.start_time)}
                                      </TableCell>
                                      <TableCell>
                                        {handleTime(item.end_time)}
                                      </TableCell>
                                      <TableCell>{item.status}</TableCell>
                                    </TableRow>
                                  )
                                )}
                            </Table>
                            <div className="py-4 w-full flex">
                              <ThemeProvider
                                theme={themes.MenuActiveButtonTheme_2}
                              >
                                <ButtonGroup
                                  variant="contained"
                                  aria-label="action button"
                                  sx={{ margin: "auto" }}
                                >
                                  <Button
                                    fullWidth
                                    onClick={() => deleteSlot(item)}
                                  >
                                    <div className="flex justify-start items-center mr-auto px-6 ">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 448 512"
                                        className="w-3 h-3 "
                                        fill="currentColor"
                                      >
                                        <path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
                                      </svg>
                                      <p className="pl-2 capitalize p1">
                                        {t(
                                          "time_schedule_dashboard_page.36orzpqer"
                                        )}
                                      </p>
                                    </div>
                                  </Button>
                                  {isScheduleEditable(item) && (
                                    <Button
                                      fullWidth
                                      onClick={() =>
                                        navigate("/schedule/update", {
                                          state: { schedule: item },
                                        })
                                      }
                                    >
                                      <div className="flex justify-start items-center mr-auto px-6 ">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="w-3 h-3 "
                                          viewBox="0 0 512 512"
                                          fill="currentColor"
                                        >
                                          <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
                                        </svg>
                                        <p className="pl-2 capitalize p1">
                                          {t(
                                            "time_schedule_dashboard_page.kye4xktkp"
                                          )}
                                        </p>
                                      </div>
                                    </Button>
                                  )}
                                </ButtonGroup>
                              </ThemeProvider>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </ThemeProvider>
                  </div>
                ))}
            </div>
          </div>
          {/* pagenation */}
          <div className="flex justify-end items-center">
            {!loadingdata && total > 1 && (
              <Pagination
                count={total}
                hidePrevButton
                hideNextButton
                page={page}
                onChange={(e: any, pageNum) => {
                  setPage(pageNum);
                  setOffset((pageNum - 1) * limit);
                }}
                shape="rounded"
                sx={themes.MuiPagenationStyle_2}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeSchedule;
