import PageComponents from "../components";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageComponents.MetaComponent
        title={t("privacy_policy_page.title")}
        canonicalUrl={window.location.host}
      />
      <section className="containerSpacing py-16">
        <div className=" body-font text-gray-600 ">
          <h2 className="head pb-6 font-extrabold">Privacy Policy</h2>
          <p className="p1">{t("privacy_policy_page.41tt37hyl")}</p>
          <p className="p1">{t("privacy_policy_page.z47ycq4h3")}</p>
          <p className="p1">
            {t("privacy_policy_page.rb391i1xq")}{" "}
            <a
              href="https://www.termsfeed.com/privacy-policy-generator/"
              target="_blank"
              rel="noreferrer"
            >
              {t("privacy_policy_page.wsjytc2w1")}
            </a>
            .
          </p>

          <h2 className="p000 font-semibold py-4">
            {t("privacy_policy_page.v0efqun5n")}
          </h2>
          <h3 className="p0 font-medium py-2">
            {t("privacy_policy_page.motcrz5pl")}
          </h3>
          <p className="p1">{t("privacy_policy_page.c5wg4kgjx")}</p>
          <h3 className="p0 font-medium py-2">
            {t("privacy_policy_page.e1pk4kedq")}
          </h3>
          <p className="p1">{t("privacy_policy_page.xcwcr957m")}</p>

          <ul className="p1">
            <li>
              <p>{t("privacy_policy_page.ty5ytug7b")}</p>
            </li>
            <li>
              <p>
                {t("privacy_policy_page.sn3yzc41i")}{" "}
                {t("privacy_policy_page.iszgaeg3g")}
              </p>
            </li>
            <li>
              <p>{t("privacy_policy_page.sz0twzt4b")}</p>
            </li>
            <li>
              <p>{t("privacy_policy_page.pxj45qe81")}</p>
            </li>
            <li>
              <p>{t("privacy_policy_page.xijtd8uzu")}</p>
            </li>
            <li>
              <p>{t("privacy_policy_page.xpjelxt9c")}</p>
            </li>
            <li>
              <p>{t("privacy_policy_page.ohfrcypze")}</p>
            </li>
            <li>
              <p>{t("privacy_policy_page.mgfhh8kao")}</p>
            </li>
            <li>
              <p>
                {t("privacy_policy_page.yfrrrh4pk")}{" "}
                {t("privacy_policy_page.slfwaekxj")}
              </p>
            </li>
            <li>
              <p>{t("privacy_policy_page.xn3k9dtc9")}</p>
            </li>
            <li>
              <p>
                {t("privacy_policy_page.mscun6r3m")}{" "}
                <a
                  href="https://auto-bids.ch/"
                  rel="external nofollow noopener"
                  target="_blank"
                >
                  {t("privacy_policy_page.z9mhsgdik")}
                </a>
              </p>
            </li>
            <li>
              <p>{t("privacy_policy_page.l22kuslhy")}</p>
            </li>
          </ul>

          <h2 className="p000 font-semibold py-4">
            {t("privacy_policy_page.ij14eiq9f")}
          </h2>
          <h3 className="p00 font-medium py-2">
            {t("privacy_policy_page.a1neapasu")}
          </h3>
          <h4 className="p0 font-medium py-2">
            {t("privacy_policy_page.h2vibuavp")}
          </h4>
          <p className="p1">{t("privacy_policy_page.z13ycsrwk")}</p>

          <ul className="p1">
            <li>
              <p>{t("privacy_policy_page.5n6mqc60y")}</p>
            </li>
            <li>
              <p>{t("privacy_policy_page.2smdu0sml")}</p>
            </li>
            <li>
              <p>{t("privacy_policy_page.88uybv1kv")}</p>
            </li>
            <li>
              <p>{t("privacy_policy_page.ib3lrc2c9")}</p>
            </li>
            <li>
              <p>{t("privacy_policy_page.s63hf73n9")}</p>
            </li>
          </ul>

          <h4 className="p00 font-medium py-2">
            {t("privacy_policy_page.ly0pk82gl")}
          </h4>
          <p className="p1">{t("privacy_policy_page.7q2u5i1lj")}</p>
          <p className="p1">
            {t("privacy_policy_page.nbnarl6jn")}{" "}
            {t("privacy_policy_page.91uixn8he")}
          </p>
          <p className="p1">
            {t("privacy_policy_page.8dmdgn0ja")}{" "}
            {t("privacy_policy_page.q5fuvr5ge")}
          </p>
          <p className="p1">{t("privacy_policy_page.8pwx063in")}</p>
          <h4 className="p0 font-medium py-2">
            {t("privacy_policy_page.6i9xzb33z")}
          </h4>
          <p className="p1">
            {t("privacy_policy_page.tnm2b8jsd")}{" "}
            {t("privacy_policy_page.9re5l479s")}
          </p>

          <ul className="p1">
            <li>
              <strong> {t("privacy_policy_page.yegebiy291")}</strong>{" "}
              {t("privacy_policy_page.yegebiy29")}{" "}
              {t("privacy_policy_page.yuju1759p")}
            </li>
            <li>
              <strong> {t("privacy_policy_page.wf83queqj1")}</strong>{" "}
              {t("privacy_policy_page.wf83queqj")}{" "}
              {t("privacy_policy_page.o58qqqyhy")}
            </li>
          </ul>

          <p className="p1">
            {t("privacy_policy_page.ecx4fhyx6")}{" "}
            {t("privacy_policy_page.v8qhvggnv")}{" "}
            <a
              href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies"
              target="_blank"
              rel="noreferrer"
            >
              {t("privacy_policy_page.512seoobc")}{" "}
            </a>
            {t("privacy_policy_page.ztla18pfy")}
          </p>
          <p className="p1">{t("privacy_policy_page.sltyyaoqf")}</p>

          <ul className="p1">
            <li>
              <p>
                <strong>{t("privacy_policy_page.58yfdn5p8")}</strong>
              </p>
              <p>{t("privacy_policy_page.ay3wz03bd")}</p>
              <p>{t("privacy_policy_page.mc4q0hyhs")}</p>
              <p>
                {t("privacy_policy_page.kpq7av4b8")}{" "}
                {t("privacy_policy_page.feo18y00v")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("privacy_policy_page.hdokok4ep")}</strong>
              </p>
              <p>{t("privacy_policy_page.fbwrn02by")}</p>
              <p>{t("privacy_policy_page.mc4q0hyhs")}</p>
              <p>{t("privacy_policy_page.bjtecw8k0")}</p>
            </li>
            <li>
              <p>
                <strong>{t("privacy_policy_page.o8ishkdxy")}</strong>
              </p>
              <p>{t("privacy_policy_page.fbwrn02by")}</p>
              <p>{t("privacy_policy_page.mc4q0hyhs")}</p>
              <p>
                {t("privacy_policy_page.mjf5n21n6")}{" "}
                {t("privacy_policy_page.cwyhlch7h")}
              </p>
            </li>
          </ul>

          <p className="p1">{t("privacy_policy_page.dr8j7z52v")}</p>
          <h3 className="p00 font-semibold py-2">
            {t("privacy_policy_page.vu5wlnuwe")}
          </h3>
          <p>{t("privacy_policy_page.aplvm6apf")}</p>
          <ul>
            <li>
              <p>
                <strong>{t("privacy_policy_page.5bw5szevg1")}</strong>,{" "}
                {t("privacy_policy_page.5bw5szevg")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("privacy_policy_page.m529a3eo41")}</strong>{" "}
                {t("privacy_policy_page.m529a3eo4")}{" "}
                {t("privacy_policy_page.4kquqdijd")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("privacy_policy_page.l568nuz171")}</strong>{" "}
                {t("privacy_policy_page.l568nuz17")}{" "}
                {t("privacy_policy_page.2c9fhxl8h")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("privacy_policy_page.3hoq8runc1")}</strong>{" "}
                {t("privacy_policy_page.3hoq8runc")}{" "}
                {t("privacy_policy_page.0maddzlv8")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("privacy_policy_page.r2ar4rslk1")}</strong>{" "}
                {t("privacy_policy_page.r2ar4rslk")}{" "}
                {t("privacy_policy_page.ljnp9vyls")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("privacy_policy_page.9slg7zmwk1")}</strong>{" "}
                {t("privacy_policy_page.9slg7zmwk")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("privacy_policy_page.6sbtq62tc1")}</strong>{" "}
                {t("privacy_policy_page.6sbtq62tc")}{" "}
                {t("privacy_policy_page.nd57w2noq")}
              </p>
            </li>
            <li>
              <p>
                <strong>{t("privacy_policy_page.ohijdnpbv1")}</strong>{" "}
                {t("privacy_policy_page.ohijdnpbv")}{" "}
                {t("privacy_policy_page.44muohr7q")}
              </p>
            </li>
          </ul>
          <p className="p1">{t("privacy_policy_page.6mmdx7kbi")}</p>

          <ul className="p1">
            <li>
              <strong>{t("privacy_policy_page.yltitxez91")}</strong>{" "}
              {t("privacy_policy_page.yltitxez9")}{" "}
            </li>
            <li>
              <strong>{t("privacy_policy_page.2cqy8hdt81")}</strong>{" "}
              {t("privacy_policy_page.2cqy8hdt8")}{" "}
              {t("privacy_policy_page.r9xwd23i1")}
            </li>
            <li>
              <strong>{t("privacy_policy_page.mysn1ffep1")}</strong>{" "}
              {t("privacy_policy_page.mysn1ffep")}
            </li>
            <li>
              <strong>{t("privacy_policy_page.owfrimhxx1")}</strong>{" "}
              {t("privacy_policy_page.owfrimhxx")}
            </li>
            <li>
              <strong>{t("privacy_policy_page.a7j22p9qq1")}</strong>{" "}
              {t("privacy_policy_page.a7j22p9qq")}
            </li>
            <li>
              <strong>{t("privacy_policy_page.n1x1zhcwl1")}</strong>{" "}
              {t("privacy_policy_page.n1x1zhcwl")}
            </li>
          </ul>

          <h3 className="py-4 p00 font-medium">
            {t("privacy_policy_page.lod789ndh")}
          </h3>
          <p className="p1">
            {t("privacy_policy_page.fyuann2z5")}{" "}
            {t("privacy_policy_page.khk3lsjhd")}
          </p>
          <p className="p1">
            {t("privacy_policy_page.h40c5p9ia")}{" "}
            {t("privacy_policy_page.siveu3e3u")}
          </p>
          <h3 className="py-4 p00 font-medium">
            {t("privacy_policy_page.nsztiimxz")}
          </h3>
          <p className="p1">
            {t("privacy_policy_page.lm8h5wy3q")}{" "}
            {t("privacy_policy_page.ridg8wlff")}
          </p>
          <p className="p1">{t("privacy_policy_page.u9u75pv72")}</p>
          <p className="p1">
            {t("privacy_policy_page.zb64hqwdu")}{" "}
            {t("privacy_policy_page.1afpb11fi")}
          </p>
          <h3 className="py-4 font-medium p00">
            {t("privacy_policy_page.0oxj1aafk")}
          </h3>
          <p className="p1">{t("privacy_policy_page.1ms2bov1o")}</p>
          <p className="p1">{t("privacy_policy_page.in4kiiwle")}</p>
          <p className="p1">
            {t("privacy_policy_page.ztewgmj53")}{" "}
            {t("privacy_policy_page.jd1fo8iwf")}
          </p>
          <p className="p1">{t("privacy_policy_page.zfud1mko0")}</p>
          <h3 className="font-medium py-4 p00">
            {t("privacy_policy_page.ugwmppwji")}
          </h3>
          <h4 className="font-medium py-2 p00">
            {t("privacy_policy_page.l2bhsmuwj")}
          </h4>
          <p className="p1">{t("privacy_policy_page.2jn3moaqk")}</p>

          <h4 className="font-medium py-2 p00">
            {t("privacy_policy_page.4uun43v9a")}
          </h4>
          <p className="p1">{t("privacy_policy_page.a7kqe1cp0")}</p>
          <h4 className="font-medium py-2 p00">
            {t("privacy_policy_page.qj9ux2i4t")}
          </h4>
          <p className="p1">{t("privacy_policy_page.o0i4qrulg")}</p>

          <ul className="p1">
            <li>{t("privacy_policy_page.g20arpcmr")}</li>
            <li>{t("privacy_policy_page.962hlaqef")}</li>
            <li>{t("privacy_policy_page.1uu052mnp")}</li>
            <li>{t("privacy_policy_page.yev8mrqeb")}</li>
            <li>{t("privacy_policy_page.bvwc7fbhb")}</li>
          </ul>

          <h3 className="py-4 p00 font-medium">
            {t("privacy_policy_page.sbbwov3kd")}
          </h3>
          <p className="p1">
            {t("privacy_policy_page.1dtnpawel")}{" "}
            {t("privacy_policy_page.hlrcbyttd")}
          </p>
          <h2 className="font-medium p000 py-4">
            {t("privacy_policy_page.mg0ovziea")}
          </h2>
          <p className="p1">
            {t("privacy_policy_page.4j4peklw3")}{" "}
            {t("privacy_policy_page.vr0m95pep")}
          </p>
          <p className="p1">{t("privacy_policy_page.zdf68pn80")}</p>
          <h2 className="font-medium p000 py-4">
            {t("privacy_policy_page.nojycb7oh")}
          </h2>
          <p className="p1">
            {t("privacy_policy_page.6ufmhwb4z")}{" "}
            {t("privacy_policy_page.6xhaszn89")}
          </p>
          <p className="p1">{t("privacy_policy_page.7aevez3yc")}</p>
          <h2 className="font-medium p000 py-4">
            {t("privacy_policy_page.9vn0p9oar")}
          </h2>
          <p className="p1">{t("privacy_policy_page.p6wllrzcr")}</p>
          <p className="p1">{t("privacy_policy_page.v4nk4gi95")}</p>
          <p className="p1">{t("privacy_policy_page.nai2f5xj4")}</p>
          <h2 className="font-medium p000 py-4">
            {t("privacy_policy_page.m2eldsibx")}
          </h2>
          <p className="p1">{t("privacy_policy_page.xrweo8hcr")}</p>

          <ul className="p1">
            <li>
              {t("privacy_policy_page.1qgvl5wcr")}{" "}
              <a
                href="https://auto-bids.ch/sell-car"
                rel="external nofollow noopener"
                target="_blank"
              >
                {t("privacy_policy_page.jw8i8bn8b")}
              </a>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
