import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Services from "../services";
import CheckUserType from "../utils/checkUserType.utils";

const DashboardAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    Services.Auth.IsUserLogedIn() &&
      navigate(
        CheckUserType.isAdmin_and_loged_in() ? "/dashboard" : "/profile"
      );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white dark:bg-[--dark] max-w-screen box-border min-h-screen flex justify-center items-center">
      <div className="py-8 ">
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardAuth;
